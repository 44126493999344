import { Fragment, ReactNode } from "react";
import { Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { DropdownProvider, useDropdownContext } from "context";

export interface DropdownProps {
  children: ReactNode;
  className?: string;
  customOpen?: boolean;
}

export const Dropdown = ({ children }: DropdownProps) => <DropdownProvider>{children}</DropdownProvider>;

Dropdown.Trigger = ({ children }: DropdownProps) => {
  const { open, setOpen } = useDropdownContext();
  return <div onClick={() => setOpen(!open)}>{children}</div>;
};

Dropdown.Items = ({ children, className, customOpen }: DropdownProps) => {
  const { open } = useDropdownContext();

  return (
    <Transition
      show={customOpen ? customOpen : open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        className={twMerge(
          "absolute right-0 z-10 mt-2 w-56 origin-top bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          className
        )}
      >
        {children}
      </div>
    </Transition>
  );
};
