import { UUID } from "helpers";
import {
  AdminTableDataUserTypeDelete,
  AdminTableDataUserTypeDeleteProps,
  AdminTableDataUserTypeEdit,
  AdminTableDataUserTypeEditProps,
} from "./components";
import { Deleting } from "pages/AdminManagement/components/AdminTable/components";

interface AdminTableDataUserTypeProps {
  quickEditing: UUID;
  deleting: Deleting;
  editProps: AdminTableDataUserTypeEditProps;
  deleteProps: AdminTableDataUserTypeDeleteProps;
}
export const AdminTableDataUserType = ({
  deleting,
  quickEditing,
  editProps,
  deleteProps,
}: AdminTableDataUserTypeProps) => {
  const { uuid, enabled } = editProps.user;

  const editingState = quickEditing === uuid;
  const deletingState = deleting.id === uuid && deleteProps.deleteStep !== "deleting";

  return (
    <>
      {editingState ? (
        <AdminTableDataUserTypeEdit {...editProps} />
      ) : deletingState ? (
        <AdminTableDataUserTypeDelete {...deleteProps} />
      ) : (
        <span className={enabled ? "text-success" : "text-error"}>{enabled ? "Active" : "Inactive"}</span>
      )}
    </>
  );
};
