import { CompanyForm, CompanyFormProps } from "components";
import { Modal, ModalProps } from "features/Modal";

interface CompanyModalProps
  extends Omit<ModalProps, "onChange">,
    Pick<CompanyFormProps, "onChange" | "onCancel" | "onNext" | "companyId"> {}

export const CompanyModal = ({ open, setOpen, onChange, companyId, onCancel, onNext }: CompanyModalProps) => {
  return (
    <Modal withActions={false} open={open} setOpen={setOpen}>
      <CompanyForm
        title={
          <>
            Choose a company where to <span className="font-bold">MANAGE ROLES</span>
          </>
        }
        onChange={onChange}
        companyId={companyId}
        onCancel={(value) => {
          setOpen(false);
          onCancel(value);
        }}
        onNext={() => {
          setOpen(false);
          onNext();
        }}
        next="Confirm"
        placeholder="All companies"
      />
    </Modal>
  );
};
