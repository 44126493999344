import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface TableOptionsProps {
  children: ReactNode;
  className?: string;
}

export const TableOptions = ({ children, className }: TableOptionsProps) => (
  <div className={twMerge("bg-primary/5 p-5", className)}>{children}</div>
);
