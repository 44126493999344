import { CompanyId, Order, RoleSortItem } from "helpers";
import { useUser } from "hooks";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { RolesWithDetailsResponse, useGetPermissionsByGroupQuery, useLazyGetRolesWithDetailsQuery } from "services";

interface Params {
  search?: string;
  sort?: RoleSortItem;
  order?: Order;
}

const initialParams: Params = {
  search: undefined,
  sort: undefined,
  order: undefined,
};

export interface RoleManagementContextValues {
  companyId: CompanyId;
  data: RolesWithDetailsResponse | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  params: Params;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onFetch: (preferCacheValue?: boolean) => void;
  onSort: (sort: RoleSortItem, order?: Order) => void;
  onSearch: (search: string) => void;
  onChangeCompany: (search: string) => void;
}

export const RoleManagementContext = createContext<RoleManagementContextValues | undefined>(undefined);

export const RoleManagementProvider = ({ children }: { children: ReactNode }) => {
  const { companyId, setCompanyId } = useUser();
  const [trigger, result] = useLazyGetRolesWithDetailsQuery();
  const [params, setParams] = useState<Params>(initialParams);
  const [page, setPage] = useState<number>(0);
  const pageSize = 4;

  // getting data earlier!
  const { data } = useGetPermissionsByGroupQuery({});

  useEffect(() => {
    !!companyId && onFetchHandler();
  }, [companyId, params, page]);

  const onSortHandler = (sort: RoleSortItem, order?: Order) => {
    let sortValue: RoleSortItem | undefined;
    let orderValue: Order | undefined;

    // in case we don't have separate sorts for them
    if (sort === "name-a-z") {
      sortValue = "role-name";
      orderValue = "ASC";
    }
    if (sort === "name-z-a") {
      sortValue = "role-name";
      orderValue = "DESC";
    }
    if (sort === "latest") {
      sortValue = "latest";
      orderValue = "DESC";
    }

    setPage(0);
    setParams((oldObject) => ({ ...oldObject, sort: sortValue || sort, order: orderValue || order }));
  };

  const onSearchHandler = (search: string) => {
    // This needs to be revisited later!!
    setPage(0);
    setParams({ search });
  };

  const onChangeCompanyHandler = (company_id: CompanyId) => {
    setPage(0);
    setCompanyId(!!company_id ? company_id : "0");
    setParams(initialParams);
  };

  const onFetchHandler = (preferCacheValue: boolean = false) =>
    trigger({ company_id: companyId, page, pageSize, ...params }, preferCacheValue);

  const values: RoleManagementContextValues = {
    companyId,
    data: result?.data,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    isSuccess: result.isSuccess,
    params,
    page,
    setPage,
    onFetch: onFetchHandler,
    onSort: onSortHandler,
    onSearch: onSearchHandler,
    onChangeCompany: onChangeCompanyHandler,
  };

  return <RoleManagementContext.Provider value={values}>{children}</RoleManagementContext.Provider>;
};

export const useRoleManagementContext = () => {
  const context = useContext(RoleManagementContext);

  if (!context) {
    throw new Error("useRoleManagementContext must be used within a RoleManagementProvider!");
  }
  return context;
};
