import { ChangeEvent, SelectHTMLAttributes, useEffect, useState } from "react";
import { ArrowDown } from "components";
import { twMerge } from "tailwind-merge";

export type OptionProps = {
  value: string;
  label?: string;
  disabled?: boolean;
};

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: OptionProps[];
}

export const Select = ({
  options,
  placeholder = "Choose an option",
  value,
  required = true,
  onChange = () => null,
  disabled = false,
  ...rest
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string | number | readonly string[] | undefined>(value);

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className={twMerge("relative flex w-full flex-row", !disabled && "cursor-pointer")}>
      <select
        className={twMerge(
          "w-full appearance-none border-primary py-1.5 pl-2 pr-10 text-center ring-primary invalid:text-primary/50 focus:border-primary focus:ring-0",
          !disabled && "cursor-pointer"
        )}
        value={selectedValue}
        onChange={handleOnChange}
        required={required}
        disabled={disabled}
        {...rest}
      >
        {!!placeholder && <option value="">{placeholder}</option>}
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label || value}
          </option>
        ))}
      </select>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex w-8 items-center justify-center bg-primary">
        <ArrowDown />
      </span>
    </div>
  );
};
