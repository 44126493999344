import { Button, ButtonProps, Input, InputProps } from "components";

export type InputStatus = "initial" | "success" | "error";

export interface InputWithButtonI {
  value: string;
  status: InputStatus;
  error: string;
}

interface InputWithButtonProps {
  inputProps: InputProps;
  buttonProps: ButtonProps;
}

export const InputWithButton = ({ inputProps, buttonProps }: InputWithButtonProps) => (
  <div className="flex w-full">
    <Input {...inputProps} />
    <Button {...buttonProps} />
  </div>
);
