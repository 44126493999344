import { useState } from "react";
import { UserCognito } from "helpers";
import { useAuthContext } from "context";
import { AuthLogin } from "./components/AuthLogin";
import { AuthChangePassword } from "./components/AuthChangePassword";
import { AuthVerifyPhoneNumber } from "./components/AuthVerifyPhoneNumber";
import { Auth2FA } from "./components/Auth2FA";
import { CardHeader } from "components";

export const Authentication = () => {
  const { auth } = useAuthContext();
  const [user, setUser] = useState<UserCognito | null>(null);

  return (
    <div className="text-center">
      {auth === "login" && <AuthLogin setUser={setUser} />}
      {auth === "change-password" && user !== null && <AuthChangePassword user={user} />}
      {auth === "verify-phone-number" && user !== null && <AuthVerifyPhoneNumber user={user} />}
      {auth === "2fa" && user !== null && <Auth2FA user={user} />}
      {auth === "success" && (
        <CardHeader withLanguage={false} title="Sign In Successful" paragraph="Welcome to PARKBER." />
      )}
    </div>
  );
};
