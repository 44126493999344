import { Loading, CardHeader } from "components";
import { Modal, ModalProps } from "features";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useResendTemporaryPasswordMutation } from "services";
import { CompanyId, UUID } from "helpers";
import { Message } from "features/Modal";

type ModalStep = "initial" | "success";

interface AdminPassReminderModalProps extends ModalProps {
  user: { id: UUID; company_id: CompanyId };
}

export const AdminPassReminderModal = ({ closeButton, onSave, open, setOpen, user }: AdminPassReminderModalProps) => {
  const [step, setStep] = useState<ModalStep>("initial");
  const [resendTemporaryPassword, resendTemporaryPasswordResult] = useResendTemporaryPasswordMutation();

  const customModalClasses = "flex min-h-[370px] p-0 text-center";

  useEffect(() => {
    if (resendTemporaryPasswordResult.isSuccess && !resendTemporaryPasswordResult.isLoading) {
      setStep("success");
    }
  }, [resendTemporaryPasswordResult]);

  useEffect(() => {
    if (step === "success") {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [step]);

  return (
    <Modal
      withActions={step === "initial"}
      open={open}
      setOpen={setOpen}
      closeButton={closeButton}
      saveButton="SEND REMINDER"
      onSave={() => resendTemporaryPassword({ uuid: user.id, company_id: user.company_id })}
      className={step === "success" ? twMerge(customModalClasses, "max-w-[900px]") : undefined}
    >
      {step === "initial" && (
        <>
          {resendTemporaryPasswordResult.isLoading && <Loading absolute />}
          <CardHeader
            withLanguage={false}
            title="remind user to change password"
            paragraph="This user is using a temporary password. For security reasons the user should change the password immediately."
            paragraphClassName="text-base font-light"
          />
        </>
      )}

      {step === "success" && <Message message="A reminder has been send" />}
    </Modal>
  );
};
