import { Table, Loading } from "components";
import { AdminTableHeadings } from "./components/AdminTableHeadings/AdminTableHeadings";
import { AdminTableData } from "./components/AdminTableData/AdminTableData";
import { useAdminManagementContext } from "pages/AdminManagement/utils/AdminManagementContext";
import { AdminNotesModal } from "pages/AdminManagement/components/AdminTable/components/AdminNotesModal";
import { AdminPassReminderModal } from "pages/AdminManagement/components/AdminTable/components/AdminPassReminderModal";
import { useEffect, useState } from "react";
import { CompanyId, UUID, User } from "helpers";
import { useAppDispatch } from "app/hooks";
import {
  initialUserData,
  setSelectedUser as setStoreSelectedUser,
  setUserData,
  setUserDataInfo,
} from "services/userSlice";
import { UserModal } from "pages/AdminManagement/components/UserModal";
import { transformError, useLazyGetLogsQuery, useLazyGetNotesQuery, useLazyGetUserQuery } from "services";

export const AdminTable = () => {
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();

  const { data, isSuccess, onPrefetchNext, page, setPage } = useAdminManagementContext();

  const [getNotes] = useLazyGetNotesQuery();
  const [getLogs] = useLazyGetLogsQuery();

  const [userId, setUserId] = useState<UUID>("");
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [openNotes, setOpenNotes] = useState<boolean>(false);
  const [openEditUser, setOpenEditUser] = useState<boolean>(false);
  const [openTempPassReminder, setOpenTempPassReminder] = useState<boolean>(false);
  const [resendUser, setResendUser] = useState<{ id: UUID; company_id: CompanyId }>({
    id: "",
    company_id: "",
  });

  useEffect(() => {
    if (!openEditUser) dispatch(setUserData(initialUserData));
  }, [openEditUser]);

  const onFlag = (user: User) => {
    const { uuid } = user;
    getNotes(uuid);
    getLogs(uuid);
    setUserId(uuid);
    setOpenNotes(true);
  };

  const onResendTempPass = (user: User) => {
    const { uuid: id, company_id } = user;

    setOpenTempPassReminder(true);
    setResendUser({ id, company_id });
  };

  const onEdit = async (user: User) => {
    const { company_id, uuid } = user;
    setUserId(uuid);
    setSelectedUser(user);
    dispatch(setUserDataInfo(user));
    setOpenEditUser(true);

    try {
      const userResult = await getUser({ company_id, uuid }, true).unwrap();
      setSelectedUser(userResult);
      dispatch(setUserDataInfo(userResult));
    } catch (error) {
      console.error(transformError(error).message);
    }
  };

  return (
    <>
      {isSuccess && !!data ? (
        <>
          <Table
            headings={<AdminTableHeadings />}
            data={<AdminTableData onFlag={onFlag} onResendTempPass={onResendTempPass} onEdit={onEdit} />}
            page={page}
            pageCount={data?.totalPages}
            onPageChange={({ selected }) => setPage(selected)}
            onMouseEnter={() => onPrefetchNext(page + 1)}
          />
          {openNotes && <AdminNotesModal open={openNotes} setOpen={setOpenNotes} userId={userId} />}
          {openEditUser && selectedUser && <UserModal open={openEditUser} setOpen={setOpenEditUser} variant="edit" />}

          {openTempPassReminder && (
            <AdminPassReminderModal
              open={openTempPassReminder}
              setOpen={(value) => {
                setOpenTempPassReminder(value);
                setResendUser({ id: "", company_id: "" });
              }}
              closeButton="Back"
              user={resendUser}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
