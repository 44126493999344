import { ChangeEvent } from "react";
import { Button, Checkbox, Toggle, WithActions } from "components";
import { Parking, Role } from "helpers";
import mockData from "__mocks__/mockData.json";

export interface UserSelectParkingProps {
  onBack: () => void;
  onNext: () => void;
  companyBasedRole: boolean;
  assignedParkings: Parking[];
  onRoleLevelChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onParkingChangeHandler: (event: ChangeEvent<HTMLInputElement>, parking: Parking) => void;
}

export const UserSelectParking = ({
  onBack,
  onNext,
  companyBasedRole,
  assignedParkings,
  onRoleLevelChange,
  onParkingChangeHandler,
}: UserSelectParkingProps) => (
  <div className="flex flex-col gap-4">
    <div>
      <h2 className="mb-3">Company level role</h2>
      <div className="mt-3 ">
        <Toggle
          off={{
            label: "Do not assign",
            id: "do-not-assign",
            value: "do-not-assign",
          }}
          on={{
            label: "Assign",
            id: "assign",
            value: "assign",
          }}
          defaultValue={companyBasedRole ? "on" : "off"}
          name="assign-company-roles"
          onChange={onRoleLevelChange}
        />
      </div>
    </div>
    <div>
      <h2 className="mb-3">Assign Parking(s)</h2>
      <div className="mt-3 grid grid-cols-4 justify-between gap-x-[30px] gap-y-4">
        {mockData.parkings.map(({ id, parkingLot }, index) => (
          <Checkbox
            variant="custom"
            label={parkingLot}
            id={String(id)}
            value={parkingLot}
            key={index}
            name="assignedParkings"
            checked={assignedParkings.some((assignedParking) => assignedParking.id === id)}
            onChange={(event) =>
              onParkingChangeHandler(event, {
                id,
                parkingLot,
                parkingRole: {} as Role,
                permissions: [],
              })
            }
            className="w-[125px] min-w-fit"
          />
        ))}
      </div>
    </div>
    <WithActions closeButton="Back" setOpen={onBack}>
      <Button onClick={onNext} disabled={!!!assignedParkings.length}>
        Next
      </Button>
    </WithActions>
  </div>
);
