import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface WithActionsProps {
  closeButton: string;
  setOpen: (state: boolean) => void;
  children?: ReactNode;
}

export const WithActions = ({ closeButton = "Close", setOpen, children }: WithActionsProps) => (
  <div className={twMerge("mt-5", children && "grid gap-3 sm:grid-flow-row-dense sm:grid-cols-2")}>
    <div className="flex items-center justify-center">
      <span className="cursor-pointer text-xs leading-6 text-secondary" onClick={() => setOpen(false)}>
        {closeButton}
      </span>
    </div>
    {children}
  </div>
);
