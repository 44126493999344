import { Dispatch, SetStateAction } from "react";
import { InputStyledDiv } from "components";
import { DeleteStep, Deleting } from "pages/AdminManagement/components/AdminTable/components";

export interface AdminTableDataUserTypeDeleteProps {
  deleteStep: DeleteStep;
  setDeleteStep: Dispatch<SetStateAction<DeleteStep>>;
  setDeleting: Dispatch<SetStateAction<Deleting>>;
}
export const AdminTableDataUserTypeDelete = ({
  deleteStep,
  setDeleteStep,
  setDeleting,
}: AdminTableDataUserTypeDeleteProps) => (
  <InputStyledDiv>
    <>
      <span className="text-error">{deleteStep === "initial" ? "Delete user ?" : "Are you sure ?"}</span>
      <div className="flex items-center justify-between">
        <span
          className="cursor-pointer font-bold hover:underline"
          onClick={() => {
            setDeleting({
              id: "",
              company_id: "",
            });
          }}
        >
          {deleteStep === "initial" ? "No" : "Cancel"}
        </span>
        <span
          className="cursor-pointer hover:underline"
          onClick={() => setDeleteStep((step) => (step === "initial" ? "confirmation" : "deleting"))}
        >
          Yes
        </span>
      </div>
    </>
  </InputStyledDiv>
);
