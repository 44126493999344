import { AdminHeader, AdminTable } from "./components";

export const AdminManagement = () => {
  return (
    <>
      <AdminHeader />
      <AdminTable />
    </>
  );
};
