import { Select, SelectProps, Button, WithActions } from "components";
import { ReactNode } from "react";
import { COMPANIES, transformCompanyToOptions } from "helpers";

export interface CompanyFormProps extends Pick<SelectProps, "onChange" | "placeholder"> {
  onCancel: (state: boolean) => void;
  onNext: () => void;
  next?: string;
  title: ReactNode | string;
  companyId: string | undefined;
}

export const CompanyForm = ({
  onChange,
  onCancel,
  onNext,
  companyId,
  title,
  placeholder,
  next = "Next",
}: CompanyFormProps) => (
  <div className="flex flex-col gap-6">
    <span>{title}</span>
    <Select
      options={transformCompanyToOptions(COMPANIES)}
      onChange={onChange}
      name="company_id"
      value={companyId}
      placeholder={placeholder}
    />
    <WithActions closeButton="Cancel" setOpen={onCancel}>
      <Button onClick={onNext} disabled={!!!companyId}>
        {next}
      </Button>
    </WithActions>
  </div>
);
