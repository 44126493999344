import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { InputStyledDiv } from "./InputStyledDiv";

export const OverviewItem = ({
  item,
  disabled = false,
  children,
}: {
  item: string;
  disabled?: boolean;
  children: ReactNode;
}) => (
  <div className={twMerge("mb-2 flex", disabled && "pointer-events-none opacity-50")} aria-disabled={disabled}>
    <div className="flex w-1/3 items-center justify-center border border-primary px-2 text-center">{item}</div>
    <InputStyledDiv className="flex items-center justify-center bg-opacity-20 py-1">{children}</InputStyledDiv>
  </div>
);
