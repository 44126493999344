import { Modal, ModalProps } from "features";
import { useEffect, useState } from "react";
import { CompanyForm } from "components";
import { useUser } from "hooks";
import { MessageModal } from "features/Modal";
import { RolePermissions } from "pages/RoleManagement/components/RolePermissions";
import { CompanyId, getUserType, isMasterAdmin } from "helpers";
import { NewRoleAssignPermissions } from "./components/NewRoleAssignPermissions";
import { useRoleManagementContext } from "context";
import { RoleOverview } from "pages/RoleManagement/components/RoleOverview";

type ModalStep = "choose-company" | "action" | "success" | "overview" | "permissions";

interface AddNewRoleModalProps extends ModalProps {}

export const AddNewRoleModal = ({ open, setOpen }: AddNewRoleModalProps) => {
  const { onFetch } = useRoleManagementContext();
  const [step, setStep] = useState<ModalStep>("choose-company");
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<CompanyId>("");
  const userType = getUserType();
  const { user } = useUser();

  useEffect(() => {
    if (userType && isMasterAdmin(userType.id)) {
      setStep("choose-company");
      setCompanyId("");
    } else {
      setStep("action");
      if (user) setCompanyId(user.company_id);
    }
  }, []);

  return (
    <>
      {openSuccess ? (
        <MessageModal
          open={openSuccess}
          setOpen={setOpenSuccess}
          message={"New role has been created"}
          onClose={() => setStep("overview")}
        />
      ) : (
        <Modal withActions={false} open={open} setOpen={setOpen}>
          {step === "choose-company" && (
            <CompanyForm
              title={
                <>
                  Choose a company where to <span className="font-bold">ADD A NEW ROLE</span>
                </>
              }
              onChange={({ target }) => setCompanyId(target.value)}
              companyId={companyId}
              onCancel={setOpen}
              onNext={() => setStep("action")}
            />
          )}
          {step === "action" && (
            <NewRoleAssignPermissions
              companyId={companyId}
              onBack={() => {
                if (userType && isMasterAdmin(userType.id)) {
                  setStep("choose-company");
                } else setOpen(false);
              }}
              back={userType && isMasterAdmin(userType.id) ? "Back" : "Cancel"}
              onSuccess={() => {
                onFetch();
                setOpenSuccess(true);
              }}
            />
          )}
          {step === "overview" && (
            <RoleOverview
              onClose={() => setOpen(false)}
              onEditPermissions={() => setStep("permissions")}
              disabledEditUsers={true}
            />
          )}
          {step === "permissions" && (
            <RolePermissions onBack={() => setStep("overview")} onSuccess={() => setStep("overview")} />
          )}
        </Modal>
      )}
    </>
  );
};
