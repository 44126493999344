import { ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";
import { Route } from "helpers";
import { Layout } from "./Layout";
import { useProfilePrefetch, useUserApiPrefetch } from "services";
import { useUser } from "hooks";

const routes: Route[] = [
  {
    path: "/profile",
    label: "General information",
  },
  {
    path: "/change-password",
    label: "Change password",
  },
  {
    path: "/2fa",
    label: "Two-factor authentication",
  },
];

export const ProfileLayout = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const prefetchUsers = useUserApiPrefetch("getUsers");
  const prefetchProfile = useProfilePrefetch("getProfile");

  const handlePrefetch = (path: string) => {
    if (user) {
      if (path === "/admin-management") {
        prefetchUsers({ company_id: user.company_id, page: 0, pageSize: 5 });
      }
      if (path === "/profile") {
        prefetchProfile(undefined, { force: true });
      }
    }
  };

  return (
    <Layout
      sidebar={
        <div className="flex h-full flex-col justify-between">
          <div className="space-y-4">
            <NavLink to="/admin-management" className="text-xl uppercase">
              <p className="text-xl">Home</p>
            </NavLink>
            <div className="space-y-3">
              {routes.map(({ path, label }, index) => (
                <NavLink
                  key={index}
                  to={path}
                  className="flex items-center space-x-4"
                  onMouseEnter={() => handlePrefetch(path)}
                >
                  <p className="text-xl">{label}</p>
                </NavLink>
              ))}
            </div>
          </div>
          <Link to="" className="text-xl">
            Looking for help?
          </Link>
        </div>
      }
    >
      {children}
    </Layout>
  );
};
