import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const ArrowDown = ({
  className = "text-white",
  viewBox = "0 0 12 12",
  width = 12,
  height = 12,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>ArrowDown</title>
    <path
      d="M11.3327 6.00033L10.3927 5.06033L6.66602 8.78032V0.666992H5.33268V8.78032L1.61268 5.05366L0.666016 6.00033L5.99935 11.3337L11.3327 6.00033Z"
      fill="currentColor"
    />
  </svg>
);
