import { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Modal, ModalProps, AssignPermissions } from "features";
import { MessageModal } from "features/Modal";
import { CompanyForm } from "components";
import { CompanyId, Parking, Role, UserRole, getUserType, isMasterAdmin, isSuperAdmin } from "helpers";
import { setAssignedParkings, setCompanyBasedRole, setRole } from "services/userSlice";
import {
  EditInfo,
  UserOverview,
  UserSelectParking,
  UserForm,
  UserTypeForm,
} from "pages/AdminManagement/components/UserModal/components";
import { useUserModal } from "pages/AdminManagement/components/UserModal/utils";
import {
  transformError,
  useLazyGetRoleQuery,
  useLazyGetRolesWithDetailsQuery,
  usePermissionApiPrefetch,
  useRoleApiPrefetch,
  useUpdateUserMutation,
} from "services";
import { twMerge } from "tailwind-merge";

type Variant = "add" | "edit";

type UserModalProps = {
  variant: Variant;
} & ModalProps;

export const UserModal = ({ variant, open, setOpen }: UserModalProps) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.userSlice);
  const { user, assignedParkings, role, companyBasedRole } = userData;
  const { type, company_id, uuid } = user;
  const [isLoading, setIsLoading] = useState(false);

  const [updateUser] = useUpdateUserMutation();

  const prefetchRole = useRoleApiPrefetch("getRole");
  const prefetchRolesWithDetails = useRoleApiPrefetch("getRolesWithDetails");
  const prefetchPermissions = usePermissionApiPrefetch("getPermissionsByGroup");

  const currentUserType = getUserType();

  const [getRole] = useLazyGetRoleQuery();
  const [getRolesWithDetails] = useLazyGetRolesWithDetailsQuery();
  const [isParkingBased, setIsParkingBased] = useState(false);

  const [parkingIndex, setParkingIndex] = useState<number | undefined>(0);

  const {
    editMode,
    step,
    setStep,
    openSuccessModal,
    setOpenSuccessModal,
    responseMessage,
    onChangeHandler,
    handleEditInfo,
    handleSave,
    isLoading: saveUserLoading,
  } = useUserModal(variant === "edit");

  const returnAssignedParkings = (): Parking[] => {
    if (user && user?.roles && user.roles.length) {
      const parkingRoles = user.roles.filter((role) => role.permission_level.id === 2);

      const parkings = parkingRoles
        .map((role) => {
          if (role.parking && role.parking.id) {
            return {
              id: role.parking.id,
              parkingLot: role.parking.name || role.parking.id,
              parkingRole: {} as Role,
              permissions: [],
            } as Parking;
          }
          return undefined;
        })
        .filter((parking): parking is Parking => parking !== undefined);

      return parkings;
    } else {
      return [];
    }
  };

  const goToPermissions = async ({
    id,
    company_id,
    isParkingBased,
    index,
  }: {
    id: number;
    company_id: CompanyId;
    isParkingBased: boolean;
    index?: number;
  }) => {
    try {
      setIsLoading(() => true);
      setIsParkingBased(() => isParkingBased);
      dispatch(setAssignedParkings(returnAssignedParkings()));
      setParkingIndex(() => index);
      const roleData = await getRole({ id, company_id }, true).unwrap();
      dispatch(
        setRole({
          role: roleData,
          parking_id: isParkingBased && user.roles ? user.roles.find((role) => role.id === id)?.parking?.id : undefined,
        })
      );
      setStep(() => "assign-permissions");
    } catch (error) {
      console.error(transformError(error).message);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handlePrefetch = (role: UserRole) => {
    const {
      id,
      company: { id: company_id },
      permission_level: { id: permission_level_id },
    } = role;
    prefetchRole({ id, company_id }, { ifOlderThan: 5 });
    prefetchRolesWithDetails(
      {
        company_id,
        permission_level_id: isSuperAdmin(type.id) ? 1 : 2,
        include_default_permissions: false,
      },
      { ifOlderThan: 5 }
    );
    prefetchPermissions({ permission_level_id }, { ifOlderThan: 5 });
  };

  const returnPermissionsVariant = () => {
    if (editMode) {
      return isParkingBased ? "parking" : "company";
    } else {
      return isSuperAdmin(type?.id) ? "company" : "parking";
    }
  };

  return (
    <div className="">
      <Modal
        withActions={false}
        open={open}
        setOpen={setOpen}
        className={twMerge("relative", step === "assign-permissions" && "pr-10")}
      >
        {step === "choose-company" && !editMode && (
          <CompanyForm
            title={
              <>
                Choose a company to <span className="font-bold">ADD A NEW USER</span>
              </>
            }
            companyId={user.company_id}
            onChange={({ target: { name, value } }) => onChangeHandler({ name, value })}
            onCancel={setOpen}
            onNext={() => setStep("user-form")}
          />
        )}
        {step === "user-form" && (
          <>
            {editMode ? (
              <EditInfo
                onClose={setOpen}
                onEdit={handleEditInfo}
                goToUserType={() => setStep("user-type")}
                goToParking={() => {
                  setIsParkingBased(true);
                  setStep("select-parking");
                }}
                goToPermissions={({ id, company_id, isParkingBased, index }) =>
                  goToPermissions({ id, company_id, isParkingBased, index })
                }
                onPrefetchRole={handlePrefetch}
                isLoading={isLoading}
              />
            ) : (
              <UserForm
                onChange={({ target: { name, value } }) => onChangeHandler({ name, value })}
                onBack={() => {
                  if (currentUserType && isMasterAdmin(currentUserType.id)) {
                    setStep("choose-company");
                  } else setOpen(false);
                }}
                back={currentUserType && isMasterAdmin(currentUserType.id) ? "Back" : "Close"}
                onSubmit={() => setStep("user-type")}
              />
            )}
          </>
        )}
        {step === "user-type" && (
          <UserTypeForm
            onChange={({ target: { name, value } }) => onChangeHandler({ name, value })}
            onBack={() => setStep("user-form")}
            onNext={() => setStep(isSuperAdmin(type.id) ? "overview" : "select-parking")}
            editMode={editMode}
          />
        )}
        {step === "select-parking" && (
          <UserSelectParking
            onBack={() => setStep(editMode ? "user-form" : "user-type")}
            onNext={() => setStep("overview")}
            companyBasedRole={companyBasedRole}
            assignedParkings={assignedParkings}
            onRoleLevelChange={({ target: { value } }) => dispatch(setCompanyBasedRole(value === "assign"))}
            onParkingChangeHandler={({ target: { checked } }, parking) =>
              dispatch(
                setAssignedParkings(
                  checked ? [...assignedParkings, parking] : assignedParkings.filter(({ id }) => id !== parking.id)
                )
              )
            }
          />
        )}
        {step === "assign-permissions" && (
          <AssignPermissions
            variant={returnPermissionsVariant()}
            parking={parkingIndex !== undefined ? assignedParkings[parkingIndex] : undefined}
            role={role}
            onBack={() => setStep(editMode ? "user-form" : "overview")}
            onGoToOverview={() => setStep(editMode ? "user-form" : "overview")}
            editMode={editMode}
          />
        )}
        {step === "overview" && (
          <UserOverview
            onCancel={(val) => (editMode ? setStep("user-form") : setOpen(val))}
            onAddNewParking={() => setStep("select-parking")}
            onEdit={(index) => {
              setParkingIndex(() => index);
              setIsParkingBased(() => index !== undefined);
              setStep("assign-permissions");
            }}
            onSave={handleSave}
            isLoading={saveUserLoading}
            editMode={editMode}
          />
        )}
        {step === "success" && openSuccessModal && (
          <MessageModal
            open={openSuccessModal}
            setOpen={setOpenSuccessModal}
            onClose={() => setOpen(false)}
            message={responseMessage?.message || ""}
            variant={responseMessage?.type}
          />
        )}
      </Modal>
    </div>
  );
};
