import { Bin, CheckLarge, Close, Flag, Pencil, ResendTemporaryPassword, Settings } from "components";
import { UUID, User, isMasterAdmin } from "helpers";
import { Deleting } from "../../AdminTableData";
import { Dispatch, SetStateAction } from "react";

interface AdminTableDataOptionsProps {
  user: User;
  quickEditing: UUID;
  deleting: Deleting;
  setDeleting: Dispatch<SetStateAction<Deleting>>;
  onResendTempPass: (user: User) => void;
  onEdit: (user: User) => void;
  onQuickEdit: (user: User) => void;
  onSaveQuickEdit: (user: User) => void;
  onDelete: (user: User) => void;
  onFlag: (user: User) => void;
}
export const AdminTableDataOptions = ({
  user,
  quickEditing,
  deleting,
  setDeleting,
  onResendTempPass,
  onEdit,
  onQuickEdit,
  onSaveQuickEdit,
  onDelete,
  onFlag,
}: AdminTableDataOptionsProps) => {
  const { uuid, type, confirmation_status, has_undone_note } = user;

  return (
    <div className="flex items-center justify-center space-x-3 bg-primary/5 py-5">
      {confirmation_status === "FORCE_CHANGE_PASSWORD" && (
        <ResendTemporaryPassword onClick={() => onResendTempPass(user)} />
      )}
      <Settings onClick={() => onEdit(user)} />
      {quickEditing === uuid ? (
        <CheckLarge onClick={() => onSaveQuickEdit(user)} viewBox="0 0 24 18" width={19} height={19} />
      ) : (
        <Pencil onClick={() => onQuickEdit(user)} />
      )}
      {!isMasterAdmin(type.id) && (
        <>
          {deleting.id === uuid ? (
            <Close
              onClick={() =>
                setDeleting({
                  id: "",
                  company_id: "",
                })
              }
            />
          ) : (
            <Bin onClick={() => onDelete(user)} />
          )}
        </>
      )}
      <div>
        <Flag className={has_undone_note ? "text-error" : "text-primary"} onClick={() => onFlag(user)} />
      </div>
    </div>
  );
};
