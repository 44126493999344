import { getInputClasses } from "helpers";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { InputVariant } from ".";

interface InputStyledDivProps {
  className?: string;
  children?: ReactNode;
  variant?: InputVariant;
}
export const InputStyledDiv = ({ className, children, variant }: InputStyledDivProps) => (
  <div className={`${twMerge(getInputClasses({ variant }), className)}`}>{children}</div>
);
