import { CheckLarge, Loading, Pencil, Input, InputStyledDiv } from "components";
import { useGetProfileQuery, useUpdateProfileMutation } from "services";
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { useClickOutside, useUser2FA } from "hooks";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";

type ProfileTable = {
  id: string;
  label: string;
  data: string;
  additional_info: string;
  options: ReactNode;
  locked?: boolean;
};

type EditingField = {
  name: "first_name" | "last_name" | "phone_number" | "address" | "";
  value: string;
};
const initialField: EditingField = {
  name: "",
  value: "",
};

export const ProfileTableData = () => {
  const { data, isLoading, refetch } = useGetProfileQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const cellClasses = "py-5 text-center text-sm w-full";
  const [editingField, setEditingField] = useState<EditingField>(initialField);
  const [loading, setLoading] = useState(false);

  const { has2FA } = useUser2FA();

  const [updateProfile] = useUpdateProfileMutation();
  const editElementRef = useRef(null);
  useClickOutside(editElementRef, () => setEditingField(initialField));

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setEditingField((prev) => ({ ...prev, value: value }));
  };

  const Options = ({ field, locked = false }: { field?: EditingField; locked?: boolean }) => {
    const onEdit = async () => {
      if (!!editingField.value && editingField.value !== field?.value && field?.name !== "phone_number") {
        setLoading(() => true);
        await updateProfile({
          [editingField.name]: editingField.value,
        });
        await refetch();
      }
      setLoading(() => false);
      setEditingField(initialField);
    };

    return (
      <>
        {locked ? (
          "locked"
        ) : (
          <div className="flex justify-center">
            {editingField.name === field!.name ? (
              <div className="flex cursor-pointer gap-2" onClick={onEdit}>
                <CheckLarge viewBox="0 0 24 18" width={19} height={19} />
                <span>{field?.name === "phone_number" ? "OK" : "Save"}</span>
              </div>
            ) : (
              <div
                className="flex cursor-pointer gap-2"
                onClick={() => setEditingField({ name: field?.name || "", value: field?.value || "" })}
              >
                <Pencil />
                <span>Edit</span>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const table_data: ProfileTable[] = [
    {
      id: "first_name",
      label: "Name",
      data: data?.first_name || "",
      additional_info: "",
      options: <Options field={{ name: "first_name", value: data?.first_name || "" }} />,
    },
    {
      id: "last_name",
      label: "Surname",
      data: data?.last_name || "",
      additional_info: "",
      options: <Options field={{ name: "last_name", value: data?.last_name || "" }} />,
    },
    {
      id: "phone_number",
      label: "Phone number",
      data: data?.phone_number || "",
      additional_info: has2FA ? "Connected to 2FA" : "Not connected to 2FA",
      options: <Options field={{ name: "phone_number", value: data?.phone_number || "" }} />,
    },
    {
      id: "address",
      label: "Address",
      data: data?.address || "",
      additional_info: data?.municipality || "",
      options: <Options field={{ name: "address", value: data?.address || "" }} />,
    },
    {
      id: "email",
      label: "Email",
      data: data?.email || "",
      additional_info: "",
      options: <Options locked />,
      locked: true,
    },
    {
      id: "company_id",
      label: "Company",
      data: data?.company_id || "",
      additional_info: "",
      options: <Options locked />,
      locked: true,
    },
    {
      id: "created_at",
      label: "Member since",
      data: data?.created_at || "",
      additional_info: "",
      options: <Options locked />,
      locked: true,
    },
    {
      id: "last_login",
      label: "Last log in",
      data: String(data?.last_login),
      additional_info: "",
      options: <Options locked />,
      locked: true,
    },
  ];

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {table_data.map(({ id, label, data, additional_info, options, locked }, index) => (
        <tr
          key={index}
          ref={id === editingField.name ? editElementRef : null}
          className={twMerge("h-[80px] max-h-[80px] break-words border-y text-center", locked && "opacity-50")}
        >
          <td className={twMerge(cellClasses)}>{label}</td>

          <td className={twMerge(cellClasses)}>
            {id === editingField.name ? (
              <>
                {id === "phone_number" ? (
                  <InputStyledDiv className="flex gap-1">
                    To change this please visit
                    <Link to="/2fa" className="text-sm">
                      2FA settings
                    </Link>
                  </InputStyledDiv>
                ) : (
                  <Input className="text-center text-sm" name={id} value={data || ""} onChange={onChangeHandler} />
                )}
              </>
            ) : (
              <>{data || ""}</>
            )}
          </td>
          <td className={twMerge(cellClasses)}>{additional_info}</td>
          <td className={twMerge(cellClasses)}>{options}</td>
        </tr>
      ))}
      {loading && <Loading absolute className="inset-0" />}
    </>
  );
};
