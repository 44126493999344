import { AssignPermissions, Modal, ModalProps } from "features";
import { ReactNode, useEffect, useState } from "react";
import { Button, InputStyledDiv, Loading, WithActions } from "components";
import { DeleteStep } from "hooks/useDeleteStep";
import { AssignedUser, Parking, UUID, User, getCompanyName } from "helpers";
import { twMerge } from "tailwind-merge";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { initialUserData, setAssignedParkings, setUserData, setUserDataInfo } from "services/userSlice";
import {
  setSelectedRole,
  transformError,
  useAttachRoleMutation,
  useDetachRoleMutation,
  useLazyGetRoleQuery,
  useLazyGetUserQuery,
} from "services";

interface RoleTableDetachUsersModalProps extends ModalProps {
  isLoading: boolean;
  deleteStep?: DeleteStep;
  successTitle?: string | ReactNode;
  selectedUsers: AssignedUser[];
}

export const RoleTableDetachUsersModal = ({
  open,
  setOpen,
  isLoading,
  title,
  onSave,
  deleteStep = "initial",
  successTitle = "",
  selectedUsers,
}: RoleTableDetachUsersModalProps) => {
  const dispatch = useAppDispatch();
  const [deleteRole, setDeleteRole] = useState(true);
  const [detachUsers] = useDetachRoleMutation();
  const [attach] = useAttachRoleMutation();
  const [getUser] = useLazyGetUserQuery();
  const [getRole] = useLazyGetRoleQuery();
  const {
    selectedRole: { id: role_id, permission_level, company },
    selectedUsers: storeSelectedUsers,
  } = useAppSelector((state) => state.roleSlice);

  const { user, assignedParkings } = useAppSelector((state) => state.userSlice.userData);

  const [step, setStep] = useState<DeleteStep | "assign-permissions">("initial");
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingRole, setIsLoadingRole] = useState(false);

  const atLeastOneWithoutRole = storeSelectedUsers.some((obj) => !obj.hasOwnProperty("role_id"));

  useEffect(() => {
    setStep(deleteStep);
  }, [deleteStep]);

  const allUsers = selectedUsers.flatMap((company) =>
    company.users.map((user) => ({ ...user, company: company.company }))
  );

  const onEdit = async (uuid: string, company_id: string) => {
    try {
      setIsLoadingUser(() => true);
      dispatch(setUserData(initialUserData));
      dispatch(
        setUserDataInfo({
          company_id,
          uuid,
        } as User)
      );

      const userResult = await getUser({ uuid, company_id }).unwrap();
      dispatch(setUserDataInfo(userResult));
      setStep("assign-permissions");
    } catch (error) {
    } finally {
      setIsLoadingUser(() => false);
    }
  };

  const handleDetachAttachRoles = async ({
    detachRoleId,
    parking_id,
  }: {
    detachRoleId: number;
    parking_id?: number;
  }) => {
    try {
      setIsLoadingRole(() => true);
      await detachUsers({
        users: storeSelectedUsers.map((user) => ({
          role_id: detachRoleId,
          username: user.uuid,
          parking_id,
        })),
      });

      storeSelectedUsers.forEach(async (user) => {
        if (user.role_id) {
          await attach({ roles: [{ role_id: user.role_id, parking_id: undefined }], username: user.uuid });
        }
      });
      const roleResult = await getRole({ company_id: company.id, id: role_id }).unwrap();
      dispatch(setSelectedRole(roleResult));
    } catch (error) {
      return console.error(transformError(error).message);
    } finally {
      setIsLoadingRole(() => false);
    }
  };

  const parking = user.roles?.find((role) => role.id === role_id)?.parking;

  useEffect(() => {
    if (parking)
      dispatch(setAssignedParkings([{ id: parking?.id, parkingLot: parking?.name || parking?.id } as Parking]));
  }, [parking]);

  return (
    <Modal
      withActions={false}
      open={open}
      setOpen={setOpen}
      className={step !== "assign-permissions" ? "max-w-[600px] py-20" : ""}
    >
      <div className="relative">
        {(isLoading || isLoadingUser || isLoadingRole) && <Loading absolute className="p-0" />}
        <div className="flex flex-col gap-y-6 text-center">
          {step === "initial" && (
            <>
              <h1 className="uppercase">{title}</h1>
              <span>
                This role will be removed for all the selected users. Please assign a new role for each to continue.
              </span>

              <div className="flex max-h-24 flex-col gap-3 overflow-y-auto" id="scroll">
                {allUsers.map(({ first_name, last_name, company, uuid }, index) => (
                  <div className={twMerge(allUsers.length > 2 && "mr-6")} key={index}>
                    <div className="flex w-full">
                      <InputStyledDiv variant="modal">
                        {`${first_name} ${last_name} | ${getCompanyName(company, 11)}`}
                      </InputStyledDiv>
                      <Button
                        variant={
                          !!storeSelectedUsers.find((user) => user.uuid === uuid)?.role_id ? "bg-success" : "bg-primary"
                        }
                        className="px-8"
                        onClick={() => onEdit(uuid, company.id)}
                      >
                        {!!storeSelectedUsers.find((user) => user.uuid === uuid)?.role_id
                          ? "Change role"
                          : "Assign new role"}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
              <WithActions closeButton="Back" setOpen={setOpen}>
                <Button
                  variant="bg-error"
                  disabled={atLeastOneWithoutRole}
                  onClick={async () => {
                    await handleDetachAttachRoles({
                      detachRoleId: role_id,
                      parking_id: parking?.id,
                    });
                    onSave && onSave();
                  }}
                >
                  Delete
                </Button>
              </WithActions>
            </>
          )}
        </div>
        {step === "assign-permissions" && (
          <AssignPermissions
            variant={permission_level.id === 2 ? "parking" : "company"}
            onBack={() => {
              setStep("initial");
            }}
            disabledRole={role_id}
            parking={permission_level.id === 2 ? assignedParkings[0] : undefined}
            onGoToOverview={() => {
              setStep("initial");
            }}
          />
        )}

        {step === "success" && <h1 className="text-center uppercase">{successTitle}</h1>}
      </div>
    </Modal>
  );
};
