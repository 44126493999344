import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmptyObject, Role, RoleUser, UUID } from "helpers";

type SelectedRole = Role | EmptyObject;

type SelectedUser = {
  role_id?: number;
} & RoleUser;

interface RoleState {
  selectedRole: SelectedRole;
  selectedUsers: SelectedUser[];
}

const initialState: RoleState = {
  selectedRole: {},
  selectedUsers: [],
};

export const roleSlice = createSlice({
  name: "roleSlice",
  initialState,
  reducers: {
    setSelectedRole: (state, action: PayloadAction<SelectedRole>) => {
      state.selectedRole = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<SelectedUser[]>) => {
      state.selectedUsers = action.payload;
    },
    setSelectedUsersRole: (state, action: PayloadAction<{ uuid: UUID; role_id: number }>) => {
      const userIndex = state.selectedUsers.findIndex((user) => user.uuid === action.payload.uuid);

      if (userIndex !== -1) {
        state.selectedUsers[userIndex] = {
          ...state.selectedUsers[userIndex],
          role_id: action.payload.role_id,
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedRole, setSelectedUsers, setSelectedUsersRole } = roleSlice.actions;

export default roleSlice.reducer;
