import { Auth } from "aws-amplify";

const awsExports = {
  Auth: {
    userPoolId: "eu-central-1_w7ldYwEHE",
    userPoolWebClientId: "5hu0mfdu4rd3q5h46desk5d6ug",
  },
};

Auth.configure(awsExports);

export { Auth as AWSAuth };
