import { useEffect, useState } from "react";
import { Modal, ModalProps } from "features/Modal";
import { Loading, Input } from "components";
import {
  transformError,
  useAttachRoleMutation,
  useCreateRoleMutation,
  useDetachRoleMutation,
  useLazyGetUserQuery,
} from "services";
import { twMerge } from "tailwind-merge";
import { GroupPermission, ParkingId, Role, UUID } from "helpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setRole, setUserDataInfo } from "services/userSlice";
import { isEqual } from "lodash";

type Step = "action" | "success" | "error";

interface UserAddRoleModalProps extends Pick<ModalProps, "open" | "setOpen"> {
  parking_id?: ParkingId;
  role: Role;
  selectedPermissions: GroupPermission[];
  editMode?: boolean;
  onBack: () => void;
  onRefetch: () => void;
  successMessage: (role: string) => string;
  errorMessage: (role: string) => string;
}
export const UserAddRoleModal = ({
  open,
  setOpen,
  parking_id,
  role,
  selectedPermissions,
  editMode = false,
  onBack,
  onRefetch,
  successMessage,
  errorMessage,
}: UserAddRoleModalProps) => {
  const dispatch = useAppDispatch();

  const { company_id, roles, uuid } = useAppSelector((state) => state.userSlice.userData.user);

  const companyBasedRole = roles?.find(({ permission_level }) => permission_level.id === 1);

  const [createRole, createRoleResult] = useCreateRoleMutation();
  const [attachRole] = useAttachRoleMutation();
  const [detachRole] = useDetachRoleMutation();
  const [roleName, setRoleName] = useState<string>("");
  const [step, setStep] = useState<Step>("action");
  const [isLoading, setIsLoading] = useState(false);
  const [getUser] = useLazyGetUserQuery(); // prefetch ?

  const createRoleRadio = role.id === 0 && role.name === "create-role";
  const changedPermissions = !isEqual(role.permissions, selectedPermissions);

  const handleDetachAttachRole = async ({
    deatchRoleId,
    attachRoleId,
    uuid,
  }: {
    deatchRoleId: number;
    attachRoleId: number;
    uuid: UUID;
  }) => {
    try {
      await detachRole({ users: [{ role_id: deatchRoleId, username: uuid }] });
      await attachRole({ roles: [{ role_id: attachRoleId }], username: uuid });
      const userResult = await getUser({ company_id, uuid }).unwrap();
      dispatch(setUserDataInfo(userResult));
    } catch (error) {
      setStep("error");
      return console.error(transformError(error).message);
    }
  };

  useEffect(() => {
    if (open) setStep(createRoleRadio || changedPermissions ? "action" : "success");
  }, []);

  useEffect(() => {
    if ((step === "success" || step === "error") && open) {
      setTimeout(() => {
        onBack();
        setOpen(false);
        setStep("action");
      }, 2000);
    }
  }, [step]);

  const handleCreateRole = async () => {
    setIsLoading(true);
    try {
      const result = await createRole({
        company_id,
        permissions: selectedPermissions.filter(({ is_default }) => !is_default),
        role: roleName,
      }).unwrap();
      dispatch(
        setRole({
          parking_id,
          role: result,
        })
      );
      if (editMode && companyBasedRole?.id) {
        await handleDetachAttachRole({ deatchRoleId: companyBasedRole.id, attachRoleId: result.id, uuid });
        setStep("success");
        onRefetch();
        setRoleName("");
      } else {
        setStep("success");
        onRefetch();
        setRoleName("");
      }
    } catch (error) {
      setStep("error");
      return console.error(transformError(error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      withActions={step === "action"}
      closeButton="Back"
      onSave={handleCreateRole}
      open={open}
      setOpen={setOpen}
      className={twMerge("max-w-[600px]", step !== "action" ? "flex" : "")}
    >
      <div className="flex flex-col items-center justify-center text-center">
        {step === "action" && (
          <>
            {isLoading && <Loading absolute />}
            <>
              <div className="mb-4 w-3/5">
                <h1>{createRoleRadio ? "You created a new role" : "You made changes to an existing role"}</h1>
                <span className="font-light">
                  {createRoleRadio ? "You must save the role" : "You must save the role as a new role"}
                </span>
              </div>
              <Input
                name="role"
                id="role"
                value={roleName}
                onChange={({ target: { value } }) => setRoleName(value)}
                placeholder="Give a unique name to the new role"
                autoComplete="off"
              />
            </>
          </>
        )}
        {step === "success" && (
          <div className="p-14">
            <h1 className="uppercase">{successMessage(roleName)}</h1>
          </div>
        )}
        {step === "error" && (
          <div>
            <h1 className="uppercase text-error underline"> An error has occurred</h1>
            <h1 className="uppercase">{errorMessage(roleName)}</h1>
          </div>
        )}
      </div>
    </Modal>
  );
};
