import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmptyObject, Parking, User, Permission, GroupPermission, ParkingId, Role } from "helpers";

interface UserData {
  user: User;
  companyBasedRole: boolean;
  assignedParkings: Parking[];
  role: Role;
}

export const initialUserData: UserData = {
  user: {} as User,
  companyBasedRole: false,
  assignedParkings: [],
  role: {} as Role,
};

export interface UserState {
  selectedUser: User | EmptyObject;
  userData: UserData;
}

const initialState: UserState = {
  selectedUser: {},
  userData: initialUserData,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<User | EmptyObject>) => {
      state.selectedUser = action.payload;
    },

    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },

    setUserDataInfo: (state, action: PayloadAction<User>) => {
      state.userData.user = action.payload;
    },

    setAssignedParkings: (state, action: PayloadAction<Parking[]>) => {
      state.userData.assignedParkings = action.payload;
    },

    setRole: (state, action: PayloadAction<{ parking_id?: ParkingId; role: Role }>) => {
      const {
        payload: { role, parking_id },
      } = action;
      if (parking_id) {
        const parkingIndex = state.userData.assignedParkings.findIndex(({ id }) => id === parking_id);
        state.userData.assignedParkings[parkingIndex].parkingRole = role;
      } else state.userData.role = role;
    },

    setPermissions: (
      state,
      action: PayloadAction<{
        parking_id: ParkingId;
        group: Omit<GroupPermission, "permissions">;
        permission: Permission;
        checked: boolean;
      }>
    ) => {
      const {
        payload: { checked, group, parking_id, permission },
      } = action;

      const parkingIndex = state.userData.assignedParkings.findIndex(({ id }) => id === parking_id);

      const parkingPermissions = state.userData.assignedParkings[parkingIndex].permissions;

      const groupIndex = parkingPermissions.findIndex(({ name }) => name === group.name);

      const selectedGroup = parkingPermissions[groupIndex];

      if (checked) {
        if (groupIndex === -1) {
          parkingPermissions.push({
            id: group.id,
            name: group.name,
            permissions: [permission],
            is_default: group.is_default,
          });

          state.userData.assignedParkings[parkingIndex].permissions = parkingPermissions;
        } else {
          selectedGroup.permissions.push(permission);
          state.userData.assignedParkings[parkingIndex].permissions[groupIndex].permissions = selectedGroup.permissions;
        }
      } else {
        const filteredPermissions = selectedGroup.permissions.filter(({ name }) => name !== permission.name);

        const filteredGroups = parkingPermissions.filter(({ name }) => name !== selectedGroup.name);

        if (filteredPermissions.length > 0) {
          state.userData.assignedParkings[parkingIndex].permissions[groupIndex].permissions = filteredPermissions;
        } else {
          state.userData.assignedParkings[parkingIndex].permissions = filteredGroups;
        }
      }
    },

    selectAllPermissions: (
      state,
      action: PayloadAction<{
        parking_id: ParkingId;
        group: Omit<GroupPermission, "permissions">;
        permissions: Permission[];
      }>
    ) => {
      const {
        payload: { group, parking_id, permissions },
      } = action;

      const parkingIndex = state.userData.assignedParkings.findIndex(({ id }) => id === parking_id);

      const parkingPermissions = state.userData.assignedParkings[parkingIndex].permissions;

      const groupIndex = parkingPermissions.findIndex(({ name }) => name === group.name);

      if (groupIndex === -1) {
        parkingPermissions.push({
          id: group.id,
          name: group.name,
          is_default: group.is_default,
          permissions: permissions,
        });

        state.userData.assignedParkings[parkingIndex].permissions = parkingPermissions;
      } else {
        state.userData.assignedParkings[parkingIndex].permissions[groupIndex].permissions = permissions;
      }
    },

    deselectAllPermissions: (
      state,
      action: PayloadAction<{
        parking_id: ParkingId;
        group: { name: string; is_default: boolean };
      }>
    ) => {
      const {
        payload: { group, parking_id },
      } = action;

      const parkingIndex = state.userData.assignedParkings.findIndex(({ id }) => id === parking_id);

      const parkingPermissions = state.userData.assignedParkings[parkingIndex].permissions;

      const filteredGroups = parkingPermissions.filter(({ name }) => name !== group.name);

      state.userData.assignedParkings[parkingIndex].permissions = filteredGroups;
    },

    resetPermissions: (state, action: PayloadAction<{ parking_id: ParkingId }>) => {
      const {
        payload: { parking_id },
      } = action;

      const parkingIndex = state.userData.assignedParkings.findIndex(({ id }) => id === parking_id);

      state.userData.assignedParkings[parkingIndex].permissions = [];
    },

    setCompanyBasedRole: (state, action: PayloadAction<boolean>) => {
      state.userData.companyBasedRole = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedUser,
  setUserData,
  setUserDataInfo,
  setAssignedParkings,
  setRole,
  setPermissions,
  selectAllPermissions,
  deselectAllPermissions,
  resetPermissions,
  setCompanyBasedRole,
} = userSlice.actions;

export default userSlice.reducer;
