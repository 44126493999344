import { convertDateFormat } from "helpers";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { twMerge } from "tailwind-merge";
import { OverviewItem, OverviewListItem, Pencil, WithActions } from "components";
import { useUserApiPrefetch } from "services";
import { useUser } from "hooks";

type RoleOverviewProps = {
  onClose: () => void;
  onEditUsers?: () => void;
  onEditPermissions?: () => void;
  disabledEditUsers?: boolean;
  disabledEditPermissions?: boolean;
};

export const RoleOverview = ({
  onClose,
  onEditUsers,
  onEditPermissions,
  disabledEditUsers = false,
  disabledEditPermissions = false,
}: RoleOverviewProps) => {
  const { user } = useUser();

  const { is_predefined, author, created_at, assigned_users, status, permissions } = useAppSelector(
    (state) => state.roleSlice.selectedRole
  );

  const prefetchUsers = useUserApiPrefetch("getUsers");

  if (user) {
    prefetchUsers({ company_id: user.company_id, page: 0, pageSize: 5 }, { ifOlderThan: 5 });
  }

  return (
    <div className="flex flex-col">
      <h2>Role overview</h2>
      <div className="space-y-4">
        <OverviewItem item="Role Type">{is_predefined ? "Pre-defined role" : "Admin generated role"}</OverviewItem>
        <OverviewItem item="Role added by">{`${author?.first_name} ${author?.last_name}`}</OverviewItem>
        <OverviewItem item="Date of adding">{convertDateFormat(created_at!) || "N/A"}</OverviewItem>
        <div className="space-y-2">
          <div className="flex justify-end">
            <Pencil disabled={disabledEditUsers || assigned_users.length === 0} onClick={onEditUsers} />
          </div>
          <OverviewItem item="Assigned Users">
            <div>
              {status ? (
                <>
                  {assigned_users.map((assigned_user, index) => (
                    <OverviewListItem
                      name={assigned_user.company.name || assigned_user.company.id || ""}
                      data={[{ name: assigned_user.users.length }]} // TODO: update this component to accept other data types
                      key={index}
                    />
                  ))}
                </>
              ) : (
                <>
                  There are no assigned user to this role. To assign users go to{" "}
                  <Link to="/admin-management" className="text-base font-bold">
                    User Management.
                  </Link>
                </>
              )}
            </div>
          </OverviewItem>
        </div>
        <div className="space-y-2">
          <div className="flex justify-end">
            <Pencil
              onClick={disabledEditPermissions ? undefined : onEditPermissions}
              className={twMerge(disabledEditPermissions && "cursor-default text-primary/20")}
            />
          </div>
          <OverviewItem item="Assigned Permissions">
            <div>
              {permissions &&
                permissions.length > 0 &&
                permissions.map(({ name, permissions }, index) => (
                  <OverviewListItem name={name} data={permissions} key={index} />
                ))}
            </div>
          </OverviewItem>
        </div>
      </div>
      <WithActions closeButton="Close" setOpen={onClose} />
    </div>
  );
};
