import { useRef, useState } from "react";
import { InputStyledDiv, Input, InputProps, CheckLarge, Pencil } from "components";
import { UpdateUserArgs } from "services";
import { useClickOutside } from "hooks";

interface EditInfoInputProps extends InputProps {
  label: string;
  name: keyof UpdateUserArgs;
  onEdit: ({ name, value }: { name: string; value: string }) => void;
  onCancel: () => void;
}

export const EditInfoInput = ({ label, name, value, placeholder, onEdit, onCancel, ...rest }: EditInfoInputProps) => {
  const [editMode, setEditMode] = useState(false);
  const editElementRef = useRef(null);

  useClickOutside(editElementRef, () => {
    setEditMode(false);
    onCancel();
  });

  return (
    <div>
      <h2 className="mb-3">{label}</h2>
      <div className="flex justify-between" ref={editMode ? editElementRef : null}>
        <div className="w-3/4">
          {editMode ? (
            <Input name={name} value={value} placeholder={placeholder} autoComplete="off" {...rest} />
          ) : (
            <InputStyledDiv variant="modal">{value || placeholder}</InputStyledDiv>
          )}
        </div>
        <div
          className="flex w-1/5 cursor-pointer items-center justify-center gap-2"
          onClick={() => {
            if (editMode) {
              onEdit({ name, value: value || "" });
            }
            setEditMode((prev) => !prev);
          }}
        >
          {editMode ? (
            <>
              <CheckLarge />
              <span>Save</span>
            </>
          ) : (
            <>
              <Pencil />
              <span>Edit</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
