import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";
import Puff from "react-loading-icons/dist/esm/components/puff";

interface LoadingProps extends IconsProps {
  absolute?: boolean;
}

export const Loading = ({ className = "", height, width, absolute = false }: LoadingProps) => (
  <div className={twMerge("flex items-center justify-center", className, absolute && "absolute h-full w-full")}>
    <Puff stroke="#1C67AD" height={height} width={width} />
  </div>
);
