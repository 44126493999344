import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const PlusSmall = ({
  className = "text-white",
  viewBox = "0 0 10 10",
  width = 10,
  height = 10,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>PlusSmall</title>
    <path
      d="M9.66634 5.66634H5.66634V9.66634H4.33301V5.66634H0.333008V4.33301H4.33301V0.333008H5.66634V4.33301H9.66634V5.66634Z"
      fill="currentColor"
    />
  </svg>
);
