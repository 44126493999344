import { ChangeEvent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Loading } from "components";
import {
  transformError,
  useDeleteUserMutation,
  useLogApiPrefetch,
  useNoteApiPrefetch,
  useUpdateUserMutation,
  useUserApiPrefetch,
} from "services";
import { CompanyId, UUID, User, convertDateFormat } from "helpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setSelectedUser as setStoreSelectedUser } from "services/userSlice";
import { useClickOutside } from "hooks";
import { useAdminManagementContext } from "pages/AdminManagement/utils/AdminManagementContext";
import { AdminTableDataUserType } from "./components/AdminTableDataUserType/AdminTableDataUserType";
import { AdminTableDataUserInfo } from "./components/AdminTableDataUserInfo";
import { AdminTableDataOptions } from "./components/AdminTableDataOptions/AdminTableDataOptions";
import { AdminTableDataDeletingUser } from "./components/AdminTableDataDeletingUser/AdminTableDataDeletingUser";

export type DeleteStep = "initial" | "confirmation" | "deleting";
export type StatusState = "initial" | "updating" | "updated";
export type Deleting = { id: UUID; company_id: CompanyId };

interface AdminTableDataProps {
  onFlag: (user: User) => void;
  onResendTempPass: (user: User) => void;
  onEdit: (user: User) => void;
}
export const AdminTableData = ({ onFlag, onResendTempPass, onEdit }: AdminTableDataProps) => {
  const dispatch = useAppDispatch();
  const { data, isLoading: isLoadingUsers, isFetching, onFetch, setPage } = useAdminManagementContext();
  const { selectedUser: storeSelectedUser } = useAppSelector((state) => state.userSlice);

  const prefetchUser = useUserApiPrefetch("getUser");
  const prefetchNotes = useNoteApiPrefetch("getNotes");
  const prefetchLogs = useLogApiPrefetch("getLogs");

  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [quickEditing, setQuickEditing] = useState<UUID>("");
  const [deleting, setDeleting] = useState<Deleting>({ id: "", company_id: "" });
  const [deleteStep, setDeleteStep] = useState<DeleteStep>("initial");
  const [statusState, setStatusState] = useState<StatusState>("initial");
  const [deletingCounter, setDeletingCounter] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingUsers || isFetching);
  const editElementRef = useRef(null);

  useClickOutside(editElementRef, () => setQuickEditing(""));

  useEffect(() => {
    setIsLoading(isLoadingUsers || isFetching);
  }, [isLoadingUsers, isFetching]);

  useEffect(() => {
    if (deleteStep === "deleting" && deletingCounter > 0) {
      const counter = setInterval(() => {
        setDeletingCounter((sec) => sec - 1);
      }, 1000);

      const deleteUser = setTimeout(() => {
        handleDeleteUser();
        clearTimeout(deleteUser);
        clearInterval(counter);
      }, 5000);

      return () => {
        clearTimeout(deleteUser);
        clearInterval(counter);
        setDeletingCounter(5);
      };
    }
  }, [deleteStep]);

  const handleDeleteUser = async () => {
    setIsLoading(() => true);
    try {
      await deleteUser({
        company_id: deleting.company_id,
        uuid: deleting.id,
      }).unwrap();

      if (!!data && data.data.length > 1) {
      } else {
        setPage((page) => page - 1);
      }

      await onFetch();
    } catch (error) {
      console.error(transformError(error).message);
    } finally {
      setIsLoading(() => false);
      setDeleting({ id: "", company_id: "" });
      setDeletingCounter(5);
      setDeleteStep("initial");
    }
  };

  const onQuickEdit = (user: User) => {
    setQuickEditing(user.uuid);
    setStatusState("initial");
    setDeleting({ id: "", company_id: "" });

    dispatch(setStoreSelectedUser(user));
  };

  const onSaveQuickEdit = async (user: User) => {
    const { uuid, company_id } = user;
    setIsLoading(() => true);
    try {
      await updateUser({
        company_id,
        uuid,
        first_name: storeSelectedUser.first_name,
        last_name: storeSelectedUser.last_name,
        email: storeSelectedUser.email,
        phone_number: storeSelectedUser.phone_number,
      }).unwrap();

      await onFetch();
    } catch (error) {
      console.error(transformError(error).message);
    } finally {
      setQuickEditing("");
      setDeleting({ id: "", company_id: "" });
      dispatch(setStoreSelectedUser({}));
      setIsLoading(() => false);
    }
  };

  const onDelete = (user: User) => {
    const { uuid: id, company_id } = user;

    setDeleting({ id, company_id });
    setDeleteStep("initial");
    setQuickEditing("");
  };

  const onCancelDeleting = () => {
    setDeleting({ id: "", company_id: "" });
    setDeleteStep("initial");
    setQuickEditing("");
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;

    dispatch(
      setStoreSelectedUser({
        ...(storeSelectedUser as User),
        [name]: value,
      })
    );
  };

  const prefetch = (user: User) => {
    const { uuid, company_id } = user;
    prefetchUser({ company_id, uuid }, { ifOlderThan: 5 });
    prefetchNotes(uuid, { ifOlderThan: 5 });
    prefetchLogs(uuid, { ifOlderThan: 5 });
  };

  const cellClasses = "text-center text-sm w-full";
  const inputClasses = "py-0 text-center text-sm";
  const innerCellClasses = "space-y-1 w-[200px]";

  return (
    <>
      {!!data &&
        data.data.length > 0 &&
        data.data.map((user, index) => {
          const { uuid, first_name, last_name, company_id, phone_number, email, last_login, type } = user;
          return (
            <tr
              ref={quickEditing === uuid ? editElementRef : null}
              key={index}
              className="h-[100px] max-h-[100px] break-words border-y text-center"
              // onMouseEnter={() => prefetch(user)} //Raequires additional questions to be 100% sure how it should be done
            >
              {deleting.id === uuid && deleteStep === "deleting" ? (
                <td className={twMerge(cellClasses, "bg-error/20")} colSpan={7}>
                  <AdminTableDataDeletingUser
                    user={user}
                    counter={deletingCounter}
                    onCancelDeleting={onCancelDeleting}
                  />
                </td>
              ) : (
                <>
                  <td className={twMerge(cellClasses)}>
                    <div className={twMerge(innerCellClasses)}>
                      <AdminTableDataUserInfo
                        quickEditing={quickEditing}
                        user={user}
                        first={{
                          value: first_name,
                          input: {
                            className: inputClasses,
                            name: "first_name",
                            value: storeSelectedUser.first_name,
                            onChange: onChangeHandler,
                          },
                        }}
                        second={{
                          value: last_name,
                          input: {
                            className: inputClasses,
                            name: "last_name",
                            value: storeSelectedUser.last_name,
                            onChange: onChangeHandler,
                          },
                        }}
                      />
                    </div>
                  </td>
                  <td className={twMerge(cellClasses)}>
                    <div className={twMerge(innerCellClasses)}>{company_id}</div>
                  </td>
                  <td className={twMerge(cellClasses)}>
                    <div className={twMerge(innerCellClasses)}>
                      <AdminTableDataUserInfo
                        quickEditing={quickEditing}
                        user={user}
                        first={{
                          value: phone_number,
                          input: {
                            className: inputClasses,
                            name: "phone_number",
                            value: storeSelectedUser.phone_number,
                            onChange: onChangeHandler,
                          },
                        }}
                        second={{
                          value: email,
                          input: {
                            className: inputClasses,
                            name: "email",
                            value: storeSelectedUser.email,
                            onChange: onChangeHandler,
                          },
                        }}
                      />
                    </div>
                  </td>
                  <td className={twMerge(cellClasses)}>
                    <div className={twMerge(innerCellClasses)}>{convertDateFormat(last_login)}</div>
                  </td>
                  <td className={twMerge(cellClasses)}>
                    <AdminTableDataUserType
                      deleting={deleting}
                      quickEditing={quickEditing}
                      editProps={{
                        setIsLoading,
                        setStatusState,
                        status: statusState,
                        user,
                      }}
                      deleteProps={{
                        deleteStep,
                        setDeleteStep,
                        setDeleting,
                      }}
                    />
                  </td>
                  <td className={twMerge(cellClasses)}>{type.name}</td>
                  <td className={twMerge(cellClasses)}>
                    <AdminTableDataOptions
                      user={user}
                      quickEditing={quickEditing}
                      deleting={deleting}
                      setDeleting={setDeleting}
                      onResendTempPass={onResendTempPass}
                      onEdit={onEdit}
                      onQuickEdit={onQuickEdit}
                      onSaveQuickEdit={onSaveQuickEdit}
                      onDelete={onDelete}
                      onFlag={onFlag}
                    />
                  </td>
                </>
              )}
            </tr>
          );
        })}
      {isLoading && (
        <tr>
          <td>
            <Loading absolute className="inset-0" />
          </td>
        </tr>
      )}
    </>
  );
};
