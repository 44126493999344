import { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { TablePagination } from ".";

interface TableProps extends HTMLAttributes<HTMLElement> {
  headings: JSX.Element;
  data: JSX.Element;
  page?: number;
  pageCount?: number;
  onPageChange?: (selectedItem: { selected: number }) => void;
  scrollClassName?: string;
}

export const Table = ({
  headings,
  data,
  page,
  pageCount,
  onPageChange,
  className,
  scrollClassName,
  onMouseEnter,
}: TableProps) => (
  <div className="flex flex-col justify-between">
    <div className={twMerge("relative mt-8 overflow-x-auto lg:mx-8", scrollClassName)} id="scroll">
      <div className={twMerge("mb-6 flow-root h-[600px] w-0", className)}>
        <table className="table w-full">
          <thead>
            <tr className="border-t text-center">{headings}</tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
      </div>
    </div>
    {page !== undefined && pageCount !== undefined && onPageChange !== undefined && (
      <div onMouseEnter={onMouseEnter} className="mt-3 flex items-center justify-center">
        <TablePagination page={page} pageCount={pageCount} onPageChange={onPageChange} />
      </div>
    )}
  </div>
);
