import { twMerge } from "tailwind-merge";
import { IconsProps } from "./IconProps";

export const Minus = ({
  className = "text-white",
  viewBox = "0 0 10 2",
  width = 10,
  height = 2,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>Minus</title>
    <path d="M9.66683 1.66659H0.333496V0.333252H9.66683V1.66659Z" fill="currentColor" />
  </svg>
);
