import { Button, InputWithButton, Sort, Search } from "components";
import { useRoleManagementContext } from "context";
import { RoleSortItem, ROLE_SORTS, isMasterAdmin } from "helpers";
import { useUser } from "hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { usePermissionApiPrefetch } from "services";
import { AddNewRoleModal } from "./components/AddNewRoleModal";
import { CompanyModal } from "./components/CompanyModal";
import { RoleHeaderSelectCompany } from "./components/RoleHeaderSelectCompany";

export const RoleHeader = () => {
  const { onSearch, onSort, params, companyId, onChangeCompany } = useRoleManagementContext();
  const { user } = useUser();
  const prefetchPermissionsByGroup = usePermissionApiPrefetch("getPermissionsByGroup");
  const [openAddNewRoleModal, setOpenAddNewRoleModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [inputValue, setInputValue] = useState(params.search || "");
  const [tempCompanyId, setTempCompanyId] = useState(companyId);

  useEffect(() => {
    setTempCompanyId(companyId);
  }, [companyId]);

  useEffect(() => {
    setOpenCompanyModal(user ? isMasterAdmin(user.type.id) : false);
  }, [user]);

  return (
    <>
      <div className="flex flex-col gap-x-10 gap-y-3 xl:flex-row xl:justify-center">
        <div>
          <InputWithButton
            inputProps={{
              placeholder: "Search",
              variant: "modal",
              value: inputValue,
              onChange: (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
              className: "border-primary focus:border-primary",
            }}
            buttonProps={{
              children: <Search className="w-10" />,
              className: "px-6",
              onClick: () => onSearch(inputValue),
            }}
          />
        </div>
        {user && isMasterAdmin(user?.type.id) && (
          <RoleHeaderSelectCompany companyId={companyId} onOpenModal={() => setOpenCompanyModal(true)} />
        )}
        <Sort
          label="Sort by:"
          values={ROLE_SORTS}
          onSort={(sort) => onSort(sort as RoleSortItem)}
          selectedSort={params.sort === "role-name" ? (params.order === "ASC" ? "name-a-z" : "name-z-a") : params.sort}
        />
        <div>
          <Button
            onMouseEnter={() => prefetchPermissionsByGroup({})}
            onClick={() => setOpenAddNewRoleModal(true)}
            className="w-full uppercase"
          >
            Add New Role
          </Button>
        </div>
      </div>

      {openAddNewRoleModal && <AddNewRoleModal open={openAddNewRoleModal} setOpen={setOpenAddNewRoleModal} />}

      {openCompanyModal && (
        <CompanyModal
          open={openCompanyModal}
          setOpen={setOpenCompanyModal}
          onChange={({ target }) => setTempCompanyId(target.value || "0")}
          companyId={tempCompanyId}
          onCancel={() => setTempCompanyId(companyId)}
          onNext={() => onChangeCompany(tempCompanyId)}
        />
      )}
    </>
  );
};
