import { CardHeader } from "components";
import { Step } from "helpers";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthPasswordResetForm } from "./components/AuthPasswordResetForm";

interface AuthPasswordResetProps {
  setEmail: Dispatch<SetStateAction<string>>;
}

export const AuthPasswordReset = ({ setEmail }: AuthPasswordResetProps) => {
  const [step, setStep] = useState<Step>(0);
  const [title, setTitle] = useState<string>("");
  const [paragraph, setParagraph] = useState<string>("");

  useEffect(() => {
    if (step === 0) {
      setTitle("Reset password");
      setParagraph("Please enter your email to receive a password recovery link.");
    } else {
      setTitle("Link sent successfully");
      setParagraph("Please check your email.");
    }
  }, [step]);

  return (
    <>
      <CardHeader title={title} paragraph={paragraph} withLanguage={step === 0} />
      {step === 0 ? (
        <AuthPasswordResetForm setStep={setStep} onEmailSent={setEmail} />
      ) : (
        <Link to="/" className="mt-7">
          Go back to the sign in page.
        </Link>
      )}
    </>
  );
};
