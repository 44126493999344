import { ValidationMessage, Input, InputProps } from "components";
import { UpdateUserArgs } from "services";

interface UserFormInputProps extends Omit<InputProps, "error"> {
  label: string;
  name: keyof UpdateUserArgs;
  error?: string;
}

export const UserFormInput = ({ label, error, ...rest }: UserFormInputProps) => (
  <div>
    <h2 className="mb-3">{label}</h2>
    <Input variant="modal" error={!!error} autoComplete="off" {...rest} />
    {error && <ValidationMessage>{error}</ValidationMessage>}
  </div>
);
