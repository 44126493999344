import { CardHeader } from "components";
import { Step } from "helpers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthPasswordRecoverForm } from "./components/AuthPasswordRecoverForm";

interface AuthPasswordRecoverProps {
  email: string;
}

export const AuthPasswordRecover = ({ email }: AuthPasswordRecoverProps) => {
  const [step, setStep] = useState<Step>(0);
  const [title, setTitle] = useState<string>("");
  const [paragraph, setParagraph] = useState<string>("");

  useEffect(() => {
    if (step === 0) {
      setTitle("Recover your password");
      setParagraph(
        "Please choose a new strong password with a minimum of 8 characters, including at least one small and one big letter, number, and a symbol (ex. . , $ % # @ !)"
      );
    } else {
      setTitle("Password was changed");
      setParagraph("");
    }
  }, [step]);

  return (
    <>
      <CardHeader title={title} paragraph={paragraph} withLanguage={step === 0} />
      {step === 0 ? (
        <AuthPasswordRecoverForm email={email} setStep={setStep} />
      ) : (
        <Link to="/" className="mt-7">
          Sign in to PARKBER
        </Link>
      )}
    </>
  );
};
