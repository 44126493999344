import { ChangeEvent, ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ClosedEye, OpenEye } from "components";
import { getInputClasses } from "helpers";

// Input component should be used only for these input types
export type InputType = "text" | "email" | "password" | "tel" | "number" | "file";

export type InputVariant = "default" | "modal";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  type?: InputType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  icon?: ReactNode;
  value?: string;
  variant?: InputVariant;
}

export const Input = forwardRef(
  (
    {
      className,
      value = "",
      onChange = () => null,
      error = false,
      type = "text",
      variant = "default",
      ...rest
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [inputValue, setInputValue] = useState<string | number | readonly string[]>(value);
    const [hidden, setHidden] = useState<boolean>(true);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      onChange(event);
    };

    return (
      <div className={twMerge("w-full", type === "password" && "relative")}>
        <input
          className={twMerge(getInputClasses({ error, type, variant }), className)}
          type={type === "password" ? (hidden ? "password" : "text") : type}
          value={inputValue}
          onChange={handleOnChange}
          ref={ref}
          {...rest}
        />
        {type === "password" && (
          <span
            onClick={() => setHidden((toggle) => !toggle)}
            className="absolute inset-y-0 right-0 mr-4 flex items-center"
          >
            {hidden ? <OpenEye /> : <ClosedEye />}
          </span>
        )}
      </div>
    );
  }
);
