import { useState } from "react";
import { Modal, ModalProps } from "features";
import { twMerge } from "tailwind-merge";
import { Button, Loading, Checkbox, WithActions } from "components";

type RolePermissionsConfirmModalProps = {
  title: string;
  label?: string;
  onSave: () => void;
  loading: boolean;
} & ModalProps;

export const RolePermissionsConfirmModal = ({
  title,
  label,
  onSave,
  loading,
  open,
  setOpen,
}: RolePermissionsConfirmModalProps) => {
  const [checked, setChecked] = useState(false);
  return (
    <Modal
      withActions={false}
      open={open}
      setOpen={setOpen}
      className={twMerge("flex min-h-[370px] max-w-[600px] p-0 text-center")}
    >
      <div className="relative flex w-full flex-col items-center justify-center gap-6 px-16">
        {loading && <Loading absolute />}
        <h1 className="uppercase">{title}</h1>
        {label && (
          <div className="w-full text-left">
            <Checkbox label={label} onChange={({ target }) => setChecked(target.checked)} checked={checked} />
          </div>
        )}
        {label && (
          <div className="w-full">
            <WithActions closeButton="Back" setOpen={setOpen}>
              <Button onClick={() => onSave()} disabled={!checked}>
                Save
              </Button>
            </WithActions>
          </div>
        )}
      </div>
    </Modal>
  );
};
