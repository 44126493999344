import { ArrowDown, Dropdown } from "components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { setCurrentLanguage } from "services/profileSlice";
import { LANGUAGES } from "helpers";

interface LanguageDropdownProps {
  active?: string;
  className?: string;
}

/**
 * Fake data and state until we get real
 * languages and translations from client
 */

export const LanguageDropdown = ({ className }: LanguageDropdownProps) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useAppSelector((state) => state.profile);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(currentLanguage);

  useEffect(() => {
    /**
     * Change language here using
     * react-i18next lib
     */
    onChangeLang(language);
    dispatch(setCurrentLanguage(language));
  }, [language]);

  const onChangeLang = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className={className}>
      <Dropdown>
        <Dropdown.Trigger>
          <div className="flex cursor-pointer items-center space-x-1">
            <span>{language}</span>
            <ArrowDown className="text-primary" />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Items>
          <div className="grid grid-cols-3 gap-5 p-4 text-center">
            {LANGUAGES.map((lang) => (
              <span
                key={lang}
                className={`${lang === language ? "font-bold" : ""} cursor-pointer hover:underline`}
                onClick={() => setLanguage(lang)}
              >
                {lang}
              </span>
            ))}
          </div>
        </Dropdown.Items>
      </Dropdown>
    </div>
  );
};
