import { User, ListResponse, Role } from "helpers";

export interface UsersResponse extends ListResponse {
  data: User[];
}

export interface RolesWithDetailsResponse extends ListResponse {
  data: Role[];
}

export interface ErrorType {
  exceptionType: string;
  httpCode: string;
  message: string;
  timestamp: string;
}

export const transformError = (error: any): ErrorType => ({
  exceptionType: error.exceptionType,
  httpCode: error.httpCode,
  message: error.message,
  timestamp: error.timestamp,
});
