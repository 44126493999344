import { Input, InputProps } from "./Input";
import { PlusSmall } from "components";
import { useRef } from "react";

interface UploadProps extends InputProps {}

export const Upload = ({ onChange, placeholder }: UploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Input
        ref={inputRef}
        type="file"
        onChange={onChange}
        accept="image/jpeg, image/jpg, image/png"
        className="hidden"
      />
      <div className="w-full border border-primary" onClick={() => inputRef.current?.click()}>
        <div className="relative flex w-full cursor-pointer flex-row justify-center p-1">
          {placeholder}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex w-8 items-center justify-center bg-primary">
            <PlusSmall className="text-white" />
          </span>
        </div>
      </div>
    </>
  );
};
