import { ReactNode } from "react";
import { TopNavigation } from "./TopNavigation";
import { Sidebar } from "./Sidebar";

type LayoutProps = {
  sidebar: ReactNode;
  children: ReactNode;
};

export const Layout = ({ sidebar, children }: LayoutProps) => (
  <div>
    <TopNavigation />
    <div className="mt-16 px-4 pb-16 lg:flex lg:space-x-8 lg:px-16">
      <Sidebar>{sidebar}</Sidebar>
      <div className="flex-1">{children}</div>
    </div>
  </div>
);
