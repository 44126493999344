import { FilterItem } from "components/Table";
import { CompanyId, Order, User, UserSortItem, UserStatusId, UserTypeId } from "helpers";
import { useUser } from "hooks";
import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { UsersResponse, useLazyGetUsersQuery, useUserApiPrefetch } from "services";

interface Params {
  search?: string;
  status?: UserStatusId;
  type?: UserTypeId;
  sort?: UserSortItem;
  order?: Order;
}

const initialParams: Params = {
  search: undefined,
  status: undefined,
  type: undefined,
  sort: undefined,
  order: undefined,
};
export interface AdminManagementContextValues {
  companyId: CompanyId;
  user: User | undefined;
  data: UsersResponse | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  params: Params;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onSort: (sort: UserSortItem, order?: Order) => void;
  onFilterByStatus: (status?: FilterItem) => void;
  onFilterByUserType: (type?: FilterItem) => void;
  onChangeCompany: (event: ChangeEvent<HTMLSelectElement>) => void;
  onSearch: (search: string) => void;
  onResetFilters: () => void;
  onPrefetchNext: (page: number) => void;
  onFetch: (preferCacheValue?: boolean) => Promise<void>;
}

export const AdminManagementContext = createContext<AdminManagementContextValues | undefined>(undefined);

export const AdminManagementProvider = ({ children }: { children: ReactNode }) => {
  const { companyId, user, setCompanyId, resetCompany } = useUser();
  const [trigger, result] = useLazyGetUsersQuery();
  const [params, setParams] = useState<Params>(initialParams);
  const [page, setPage] = useState<number>(0);
  const prefetchUsers = useUserApiPrefetch("getUsers");
  const pageSize = 5;

  useEffect(() => {
    !!companyId && onFetchHandler(true);
  }, [companyId, params, page]);

  const onSortHandler = (sort: UserSortItem, order?: Order) => {
    setPage(0);
    setParams((oldParams) => ({ ...oldParams, sort, order }));
  };

  const onFilterByStatusHandler = (status?: FilterItem) => {
    setPage(0);
    setParams((oldParams) => ({
      ...oldParams,
      type: oldParams.type,
      status: status as UserStatusId,
    }));
  };

  const onFilterByUserTypeHandler = (type?: FilterItem) => {
    setPage(0);
    setParams((oldParams) => ({
      ...oldParams,
      status: oldParams.status,
      type: type as UserTypeId,
    }));
  };

  const onChangeCompanyHandler = ({ target: { value: company_id } }: ChangeEvent<HTMLSelectElement>) => {
    setPage(0);
    setCompanyId(!!company_id ? company_id : "0");
    setParams(initialParams);
  };

  const onSearchHandler = (search: string) => {
    setPage(0);
    setParams(() => ({ ...initialParams, search }));
  };

  const onResetFiltersHandler = () => {
    setPage(0);
    resetCompany();
    setParams(initialParams);
  };

  const onPrefetchNextHandler = (page: number) => {
    if (!!result.data && page < result.data.totalPages)
      prefetchUsers({ company_id: companyId, page, pageSize, ...params }, { ifOlderThan: 5 });
  };

  const onFetchHandler = async (preferCacheValue: boolean = false) => {
    try {
      await trigger({ company_id: companyId, page, pageSize, ...params }, preferCacheValue);
    } catch (error) {
      console.log(error);
    }
  };

  const values: AdminManagementContextValues = {
    companyId,
    user,
    data: result?.data,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    isSuccess: result.isSuccess,
    params,
    page,
    setPage,
    onSort: onSortHandler,
    onFilterByStatus: onFilterByStatusHandler,
    onFilterByUserType: onFilterByUserTypeHandler,
    onChangeCompany: onChangeCompanyHandler,
    onSearch: onSearchHandler,
    onResetFilters: onResetFiltersHandler,
    onPrefetchNext: onPrefetchNextHandler,
    onFetch: onFetchHandler,
  };

  return <AdminManagementContext.Provider value={values}>{children}</AdminManagementContext.Provider>;
};

export const useAdminManagementContext = () => {
  const context = useContext(AdminManagementContext);

  if (!context) {
    throw new Error("useAdminManagementContext must be used within a AdminManagementProvider!");
  }
  return context;
};
