import { Bin, Info, Pencil } from "components";
import { Role } from "helpers";

type RoleTableDataOptionsProps = {
  role: Role;
  onEdit: (role: Role) => void;
  onDelete: (role: Role) => void;
};
export const RoleTableDataOptions = ({ role, onEdit, onDelete }: RoleTableDataOptionsProps) => (
  <div className="flex items-center justify-center space-x-3 bg-primary/5 py-9">
    <Pencil onClick={() => onEdit(role)} />
    {role.is_predefined ? (
      <Info height={18} width={18} className="cursor-default" />
    ) : (
      <Bin onClick={() => onDelete(role)} disabled={role.status} />
    )}
  </div>
);
