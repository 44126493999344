import { UserStatus, UserStatusId, UserType, UserTypeId } from "helpers";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export type FilterType = UserType | UserStatus;
export type FilterItem = UserTypeId | UserStatusId;

interface TableFilterProps {
  label: string;
  values: FilterType[];
  onFilter: (filter?: FilterItem) => void;
  selectedFilter?: FilterItem;
}

export const TableFilter = ({ label, values, onFilter, selectedFilter }: TableFilterProps) => {
  const [activeFilter, setActiveFilter] = useState<FilterItem | undefined>(selectedFilter);

  const onClickHandler = (filter?: FilterItem) => {
    if (activeFilter !== filter) {
      onFilter(filter);
      setActiveFilter(filter);
    }
  };

  useEffect(() => {
    setActiveFilter(selectedFilter);
  }, [selectedFilter]);

  return (
    <div>
      <span>{label}</span>
      <div className="flex justify-center gap-1">
        {values.map(({ id, name }, index) => (
          <div key={index} className="flex gap-1">
            <span
              className={twMerge(
                "cursor-pointer font-normal hover:underline",
                activeFilter === id && "cursor-auto font-bold hover:no-underline"
              )}
              onClick={() => onClickHandler(id)}
            >
              {name}
            </span>
            {index !== values.length - 1 && <>/</>}
          </div>
        ))}
      </div>
    </div>
  );
};
