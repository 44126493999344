import { Logo } from "components";
import { LanguageDropdown, NotificationsDropdown, ProfileDropdown } from "features";

export const TopNavigation = () => (
  <div className="bg-primary/10 px-4 py-6 sm:px-16">
    <div className="flex items-center justify-between">
      <div>
        <Logo height={32} width={114} />
      </div>
      <div className="flex items-center space-x-4 lg:space-x-8">
        <NotificationsDropdown />
        <LanguageDropdown />
        <ProfileDropdown />
      </div>
    </div>
  </div>
);
