import { Role } from "helpers";
import { twMerge } from "tailwind-merge";
import { Loading } from "components";
import { useRoleManagementContext } from "context";
import { RoleTableDataOptions } from "./components/RoleTableDataOptions";
import { RoleTableDataPermissions } from "./components/RoleTableDataPermissions";
import { RoleTableDataUsers } from "./components/RoleTableDataUsers";
import { RoleTableDataStatus } from "./components/RoleTableDataStatus";

interface RoleDataProps {
  onEdit: (role: Role) => void;
  onDelete: (role: Role) => void;
}
export const RoleTableData = ({ onEdit, onDelete }: RoleDataProps) => {
  const { data, isLoading, isFetching } = useRoleManagementContext();

  const cellClasses = "py-5 px-4 text-center text-sm w-full";

  return (
    <>
      {data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((role, index) => (
          <tr key={index} className="h-[137.5px] max-h-[137.5px] min-h-[137.5px] break-words border-y text-center">
            <>
              <td className={twMerge(cellClasses)}>{role.name}</td>
              {role.permissions && role.permissions.length > 0 && (
                <td className={twMerge(cellClasses)}>
                  <RoleTableDataPermissions group_permissions={role.permissions} />
                </td>
              )}
              <td className={twMerge(cellClasses)}>
                <RoleTableDataUsers role={role} />
              </td>
              <td className={twMerge(cellClasses)}>
                <RoleTableDataStatus status={role.status} />
              </td>
              <td className={twMerge(cellClasses)}>
                <RoleTableDataOptions role={role} onEdit={onEdit} onDelete={onDelete} />
              </td>
            </>
          </tr>
        ))}
      {(isLoading || isFetching) && (
        <tr>
          <td>
            <Loading absolute className="inset-0" />
          </td>
        </tr>
      )}
    </>
  );
};
