import { useProtectedRoute } from "hooks";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated } = useProtectedRoute();

  return <>{!isAuthenticated ? <Navigate to="/" replace /> : <>{children}</>}</>;
};
