import { User } from "helpers";

interface AdminTableDataDeletingUserProps {
  user: User;
  counter: number;
  onCancelDeleting: () => void;
}

export const AdminTableDataDeletingUser = ({ user, counter, onCancelDeleting }: AdminTableDataDeletingUserProps) => (
  <div className="flex flex-row justify-evenly">
    <div>
      User {user.first_name} {user.last_name} will be deleted in {counter} seconds
    </div>
    <div className="uppercase hover:cursor-pointer hover:underline" onClick={onCancelDeleting}>
      Cancel immidiately
    </div>
  </div>
);
