import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const CheckSmall = ({
  className = "text-white",
  viewBox = "0 0 10 10",
  width = 10,
  height = 10,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>CheckSmall</title>
    <path
      d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
      fill="currentColor"
    />
  </svg>
);
