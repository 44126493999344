import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Search = ({
  className = "text-white",
  viewBox = "0 0 13 12",
  width = 13,
  height = 12,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>Search</title>
    <path
      d="M8.83333 7.33333H8.30667L8.12 7.15333C8.77333 6.39333 9.16667 5.40667 9.16667 4.33333C9.16667 1.94 7.22667 0 4.83333 0C2.44 0 0.5 1.94 0.5 4.33333C0.5 6.72667 2.44 8.66667 4.83333 8.66667C5.90667 8.66667 6.89333 8.27333 7.65333 7.62L7.83333 7.80667V8.33333L11.1667 11.66L12.16 10.6667L8.83333 7.33333ZM4.83333 7.33333C3.17333 7.33333 1.83333 5.99333 1.83333 4.33333C1.83333 2.67333 3.17333 1.33333 4.83333 1.33333C6.49333 1.33333 7.83333 2.67333 7.83333 4.33333C7.83333 5.99333 6.49333 7.33333 4.83333 7.33333Z"
      fill="currentColor"
    />
  </svg>
);
