import { AWSAuth } from "authentication";
import { Button, CardHeader, InputStatus, Info } from "components";
import { VerifyPhoneNumberForm } from "features";
import { getPhoneNumber, storePreferredMFA } from "helpers";
import { transformPhoneNumber } from "helpers";
import { useUser2FA } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useProfilePrefetch } from "services";

export type ProfileStep = "info" | "action" | "success";

export const Profile2FA = () => {
  const prefetchProfile = useProfilePrefetch("getProfile");

  const [step, setStep] = useState<ProfileStep>("info");
  const [phoneNumberSent, setPhoneNumberSent] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [paragraph, setParagraph] = useState<string>("");
  const { user, has2FA } = useUser2FA();

  const onStatusChange = ({ phoneNumber, code }: { phoneNumber: InputStatus; code: InputStatus }) => {
    setPhoneNumberSent(phoneNumber === "success");
    setCodeSent(code === "success");
  };

  // effect to change info message
  useEffect(() => {
    let message = "";
    if (has2FA) {
      if (step === "info")
        message = `
          Your current mobile phone number <b>${transformPhoneNumber(getPhoneNumber())}</b> is connected with 2FA.
        `;
      else if (step === "action")
        message = `
          <div>Your current mobile phone number is connected with 2FA.</div>
          <div>If you change the phone number, the new number will become connected with your 2FA.</div>
        `;
      else message = "You have successfully set a new mobile phone number to your account.";
    } else {
      if (step === "info") message = "Your account is not connected with 2FA.";
      else if (step === "action")
        message = `
          <div>Your account is currently not secured with 2FA.</div>
          <div>Connect your mobile phone number now and make your account more secure.</div>
        `;
      else message = "You have successfully set a 2FA to your account.";
    }
    setInfoMsg(message);
  }, [has2FA, step]);

  // effect to change title & paragraph
  useEffect(() => {
    let title = "";
    let paragraph = "";
    title = !phoneNumberSent ? "set new 2fa mobile number" : "change your mobile phone number with 2fa";
    if (!codeSent) {
      paragraph = `Please use your ${has2FA ? "new" : ""} mobile phone number to connect it to your 2FA.`;
      if (phoneNumberSent)
        paragraph = `Please enter the SMS code you received to connect your ${
          has2FA ? "new" : ""
        } mobile phone number to your 2FA.`;
    } else {
      paragraph = "Select continue to save the changes to your account.";
    }
    setTitle(title);
    setParagraph(paragraph);
  }, [phoneNumberSent, codeSent]);

  const onSet2FA = async () => {
    try {
      setStep("success");
      // await AWSAuth.rememberDevice();
      await AWSAuth.setPreferredMFA(user, "SMS_MFA");
      storePreferredMFA("SMS_MFA");
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-10 text-center">
        <div className="flex flex-col items-center justify-center gap-4">
          <Info className={step === "success" ? "text-success" : "text-primary"} />
          <h2 className="font-normal" dangerouslySetInnerHTML={{ __html: infoMsg }} />
        </div>
        {step === "info" && (
          <div className="flex flex-col gap-2">
            <Button onClick={() => setStep("action")}>{has2FA ? "Change 2FA phone number" : "Set 2FA now"}</Button>
            {!has2FA && <Link to="">What is 2FA?</Link>}
          </div>
        )}
        {step === "action" && (
          <div className="flex w-[600px] flex-col gap-2 px-4 xs:px-12 sm:px-[70px]">
            <div className="self-center px-20">
              <CardHeader withLanguage={false} title={title} paragraph={paragraph} />
            </div>
            <VerifyPhoneNumberForm
              onContinue={onSet2FA}
              user={user!}
              variant="profile"
              onStatusChange={onStatusChange}
              cancel={
                <span onClick={() => setStep("info")} className="cursor-pointer text-xs leading-6 hover:underline">
                  Cancel
                </span>
              }
            />
          </div>
        )}
        {step === "success" && (
          <Link onMouseEnter={() => prefetchProfile()} to="/profile">
            Go back to General information
          </Link>
        )}
      </div>
    </div>
  );
};
