import { GroupPermission, Permission } from "helpers";
import { useState } from "react";

export const usePermissions = () => {
  const [selectedPermissions, setSelectedPermissions] = useState<GroupPermission[]>([]);

  const selectedSelectionsGroupLength = ({
    selectedPermissions,
    group,
  }: {
    selectedPermissions: GroupPermission[];
    group: GroupPermission;
  }) => selectedPermissions.find(({ id }) => id === group.id)?.permissions.length;

  const handleOnPermissionChange = ({
    checked,
    permission,
    group,
  }: {
    checked: boolean;
    permission: Permission;
    group: GroupPermission;
  }) => {
    const groupIndex = selectedPermissions.findIndex(({ id }) => id === group.id);

    if (checked) {
      if (groupIndex === -1) {
        const updatedPermissions = [...selectedPermissions];
        updatedPermissions.push({ ...group, permissions: [permission] });

        setSelectedPermissions(updatedPermissions);
      } else {
        const updatedPermissions = [...selectedPermissions];
        updatedPermissions[groupIndex] = {
          ...updatedPermissions[groupIndex],
          permissions: [...updatedPermissions[groupIndex].permissions, permission],
        };

        setSelectedPermissions(updatedPermissions);
      }
    } else {
      const updatedPermissions = [...selectedPermissions];
      const filteredGroups = selectedPermissions.filter(({ id }) => id !== group.id);
      const filteredPermissions = updatedPermissions[groupIndex].permissions.filter(({ id }) => id !== permission.id);

      if (filteredPermissions.length > 0) {
        updatedPermissions[groupIndex] = {
          ...updatedPermissions[groupIndex],
          permissions: filteredPermissions,
        };

        setSelectedPermissions(updatedPermissions);
      } else {
        setSelectedPermissions(filteredGroups);
      }
    }
  };

  const handleAllSelected = (group: GroupPermission) => {
    return group.is_default
      ? true
      : selectedSelectionsGroupLength({ selectedPermissions, group }) === group.permissions.length;
  };

  const handleNoneSelected = (group: GroupPermission) => {
    return (
      selectedSelectionsGroupLength({ selectedPermissions, group }) === 0 ||
      selectedSelectionsGroupLength({ selectedPermissions, group }) === undefined
    );
  };

  const handleAnySelected = (group: GroupPermission) => {
    return group.is_default ? true : selectedSelectionsGroupLength({ selectedPermissions, group }) !== undefined;
  };

  const handleSelectAll = (group: GroupPermission) => {
    const groupIndex = selectedPermissions.findIndex(({ id }) => id === group.id);
    if (groupIndex === -1) {
      const updatedPermissions = [...selectedPermissions];
      updatedPermissions.push({ ...group });

      setSelectedPermissions(updatedPermissions);
    } else {
      const updatedPermissions = [...selectedPermissions];
      updatedPermissions[groupIndex] = {
        ...updatedPermissions[groupIndex],
        permissions: group.permissions,
      };

      setSelectedPermissions(updatedPermissions);
    }
  };

  const handleDeselectAll = (group: GroupPermission) => {
    setSelectedPermissions((prev) => prev.filter(({ id }) => id !== group.id));
  };

  const handleChecked = ({ group, permission }: { group: GroupPermission; permission: Permission }) => {
    const selectedGroup = selectedPermissions.find(({ id }) => id === group.id);
    return !!selectedGroup?.permissions.find(({ id }) => id === permission.id) || false;
  };

  const accordionTitle = (group: GroupPermission, permissions: Permission[]) =>
    `${group.name} (${
      group.is_default ? permissions.length : selectedSelectionsGroupLength({ selectedPermissions, group }) || 0
    }/${permissions.length})`;

  return {
    selectedPermissions,
    setSelectedPermissions,
    selectedSelectionsGroupLength,
    handleOnPermissionChange,
    handleAllSelected,
    handleNoneSelected,
    handleAnySelected,
    handleSelectAll,
    handleDeselectAll,
    handleChecked,
    accordionTitle,
  };
};
