import { ReactNode } from "react";
import { Logo } from "components";
import { twMerge } from "tailwind-merge";

export type CardVariant = "success" | "error";
export const Card = ({ children, variant = "success" }: { children: ReactNode; variant?: CardVariant }) => (
  <div className="flex w-full items-center sm:w-auto">
    <div
      className={twMerge(
        "hidden min-h-[370px] place-items-center sm:grid sm:px-2 md:px-6 lg:px-10",
        variant === "success" ? "bg-primary" : "bg-error"
      )}
    >
      <Logo />
    </div>
    <div className="w-[600px] flex-1 px-4 xs:px-12 sm:px-[70px]">{children}</div>
  </div>
);
