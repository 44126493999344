import { useEffect } from "react";
import { Modal, ModalProps } from "../Modal";
import { twMerge } from "tailwind-merge";
import { CardVariant } from "components";
import { Message } from ".";

type MessageModalProps = { message: string; onClose: () => void; variant?: CardVariant } & ModalProps;

export const MessageModal = ({ message, onClose, open, setOpen: setOpen, variant }: MessageModalProps) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
        onClose();
      }, 2000);
    }
  }, [open]);

  return (
    <Modal
      withActions={false}
      open={open}
      setOpen={setOpen}
      className={twMerge("flex min-h-[370px] max-w-[900px] p-0")}
    >
      <Message message={message} variant={variant} />
    </Modal>
  );
};
