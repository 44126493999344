import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Flag = ({
  className = "text-primary",
  viewBox = "0 0 15 18",
  width = 15,
  height = 18,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>Flag</title>

    <path
      d="M7.36 2.5L7.76 4.5H13V10.5H9.64L9.24 8.5H2V2.5H7.36ZM9 0.5H0V17.5H2V10.5H7.6L8 12.5H15V2.5H9.4L9 0.5Z"
      fill="currentColor"
    />
  </svg>
);
