import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Close = ({
  className = "text-primary",
  viewBox = "0 0 15 15",
  width = 15,
  height = 15,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>Close</title>
    <path
      d="M14.5 1.91L13.09 0.5L7.5 6.09L1.91 0.5L0.5 1.91L6.09 7.5L0.5 13.09L1.91 14.5L7.5 8.91L13.09 14.5L14.5 13.09L8.91 7.5L14.5 1.91Z"
      fill="currentColor"
    />
  </svg>
);
