import { Fragment, HTMLAttributes, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button, Close } from "components";
import { twMerge } from "tailwind-merge";

export interface ModalProps extends HTMLAttributes<HTMLElement> {
  open: boolean;
  setOpen: (state: boolean) => void;
  onSave?: () => void;
  closeButton?: string;
  saveButton?: string;
  withActions?: boolean;
  withClose?: boolean;
  onlyCloseButton?: boolean;
}

export const Modal = ({
  open,
  setOpen,
  onSave = () => {},
  closeButton = "Close",
  saveButton = "Save",
  withActions = true,
  withClose = false,
  onlyCloseButton = false,
  children,
  className = "",
}: ModalProps & { children: ReactNode }) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" static onClose={() => {}}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-primary bg-opacity-20 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 my-[5vh] overflow-y-auto">
        <div className="flex min-h-full items-center justify-center px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-4 translate-y-0 scale-95"
          >
            <Dialog.Panel
              className={twMerge(
                "relative w-full max-w-[750px] transform overflow-hidden bg-white px-20 py-5 text-left shadow-xl transition-all",
                className
              )}
            >
              {withClose && (
                <div className="-mr-14 flex justify-end">
                  <Close onClick={() => setOpen(false)} />
                </div>
              )}

              {children}

              {onlyCloseButton && !withActions && (
                <div className="mt-4 flex justify-center">
                  <Button onClick={() => setOpen(false)}>{closeButton}</Button>
                </div>
              )}

              {!onlyCloseButton && withActions && (
                <div className="mt-5 flex justify-end">
                  <Button onClick={() => setOpen(false)}>{closeButton}</Button>
                  <Button onClick={onSave}>{saveButton}</Button>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
