import { CardVariant } from "components/Card";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useAdminManagementContext } from "pages/AdminManagement/utils/AdminManagementContext";
import { NonMasterUserTypeId, Parking, Role, User, UserTypeId, getUserTypeName, isSuperAdmin } from "helpers";
import { useUser } from "hooks";
import { useState } from "react";
import { transformError, useAttachRoleMutation, useCreateUserMutation, useUpdateUserMutation } from "services";
import { setUserData, setUserDataInfo } from "services/userSlice";

type ModalStep =
  | "choose-company"
  | "user-form"
  | "user-type"
  | "select-parking"
  | "assign-permissions"
  | "overview"
  | "success";

export const useUserModal = (editMode: boolean) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.userSlice);
  const { user, assignedParkings, role } = userData;
  const { uuid, company_id, first_name, last_name, address, email, phone_number, type, language } = user;

  const { user: currentUser } = useUser();
  const { onFetch } = useAdminManagementContext();

  const [updateUser] = useUpdateUserMutation();
  const [createUser, createUserResult] = useCreateUserMutation();
  const [attachRole, attachRoleResult] = useAttachRoleMutation();

  const [step, setStep] = useState<ModalStep>(editMode ? "user-form" : "choose-company");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [userTypeId, setUserTypeId] = useState(type?.id || undefined);
  const [responseMessage, setResponseMessage] = useState<{ type: CardVariant; message: string } | undefined>();

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    if (name === "type") {
      dispatch(
        setUserData({
          ...userData,
          user: {
            ...user,
            type: {
              id: Number(value) as NonMasterUserTypeId,
              name: getUserTypeName(Number(value) as UserTypeId) || "",
            },
          },
          assignedParkings: [],
          role: {} as Role,
          companyBasedRole: false,
        })
      );
    } else {
      dispatch(
        setUserDataInfo({
          ...user,
          [name]: value,
        })
      );
    }
  };

  const handleEditInfo = ({ name, value }: { name: string; value: string }) => {
    onChangeHandler({ name, value });
    handleUpdateUser({ name, value });
  };

  // TODO: check type on update user
  const handleUpdateUser = async ({ name, value }: { name: string; value: string | number }) => {
    try {
      await updateUser({
        company_id,
        uuid,
        [name]: value,
      }).unwrap();
      onFetch();
    } catch (error) {
      setResponseMessage({
        type: "error",
        message: transformError(error).message,
      });
      console.error(transformError(error).message);
    }
  };

  const handleCreateUser = async () => {
    try {
      const user = await createUser({
        company_id: currentUser?.company_id || "0",
        address,
        email,
        first_name,
        last_name,
        language,
        phone_number,
        parking: "test-parking",
        targetGroup: company_id,
        type: type.id as NonMasterUserTypeId,
        notification: true,
      }).unwrap();

      handleAttachRoles({ user, role, parkings: assignedParkings });
    } catch (error) {
      console.error(transformError(error).message);
      setResponseMessage({
        type: "error",
        message: transformError(error).message,
      });
    }
  };

  const handleAttachRoles = async ({
    user: { uuid: username, type },
    role,
    parkings,
  }: {
    user: User;
    role: Role;
    parkings: Parking[];
  }) => {
    const companyRole = {
      role_id: role.id,
    };

    const parkingRoles = parkings.map((parking) => ({ role_id: parking.parkingRole.id, parking_id: parking.id || 0 }));

    const roles = isSuperAdmin(type.id) ? [companyRole] : parkingRoles;

    // const roles = isSuperAdmin(type.id) ? [companyRole] : [...parkingRoles, companyRole];
    try {
      await attachRole({
        username,
        roles,
      }).unwrap();
      setResponseMessage({
        type: "success",
        message: "User has been saved",
      });
      onFetch();
    } catch (error) {
      console.error(transformError(error).message);
      setResponseMessage({
        type: "error",
        message: transformError(error).message,
      });
    } finally {
      setOpenSuccessModal(true);
      setStep("success");
    }
  };

  const handleSave = () => {
    if (editMode) {
      if (type.id !== userTypeId) {
        handleUpdateUser({ name: "type", value: type.id });
      }
      handleAttachRoles({ user, role, parkings: assignedParkings });
    } else {
      handleCreateUser();
    }
  };

  return {
    editMode,
    step,
    setStep,
    openSuccessModal,
    setOpenSuccessModal,
    responseMessage,
    setResponseMessage,
    onChangeHandler,
    handleEditInfo,
    handleSave,
    isLoading: createUserResult.isLoading || attachRoleResult.isLoading,
  };
};
