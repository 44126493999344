import { ArrowDown } from "components";
import { GroupPermission } from "helpers";
import { useShowAll } from "hooks";
import { twMerge } from "tailwind-merge";

export const RoleTableDataPermissions = ({ group_permissions }: { group_permissions: GroupPermission[] }) => {
  const { displayedData, setShowAll, showAll } = useShowAll(group_permissions);

  return (
    <div>
      {displayedData.map(({ name, permissions }, index) => (
        <p key={index}>
          {name} | {permissions.length}
        </p>
      ))}

      {group_permissions.length > 3 && (
        <span
          className="flex cursor-pointer items-center justify-center font-bold hover:underline"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "VIEW LESS" : "VIEW ALL"}
          <ArrowDown className={twMerge("ml-2 text-primary", showAll && "rotate-180")} />
        </span>
      )}
    </div>
  );
};
