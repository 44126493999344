import { ChangeEvent, FormEvent, useState } from "react";
import { Button, Loading, Select, WithActions } from "components";
import { LANGUAGES, Language, transofrmLanguagesToOptions } from "helpers";
import { useAppSelector } from "app/hooks";
import { useLazyCheckEmailQuery, useUserApiPrefetch } from "services";
import { UserFormInput } from "./components/UserFormInput";

interface UserFormProps {
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBack: () => void;
  onSubmit: () => void;
  back: string;
}

export const UserForm = ({ onChange, onBack, onSubmit, back }: UserFormProps) => {
  const { company_id, first_name, last_name, email, phone_number, address, language } = useAppSelector(
    (state) => state.userSlice.userData.user
  );

  const [checkEmail, emailExists] = useLazyCheckEmailQuery();
  const [error, setError] = useState<{ [key: string]: string }>({});

  const prefetchCheckEmail = useUserApiPrefetch("checkEmail");

  const handleBlur = (field: string, value: string | undefined) => {
    const safeValue = value || ""; // Ensure value is always a string
    setError((prev: { [key: string]: string }) => {
      const updatedError = { ...prev }; // Ensure it's an object

      if (safeValue.length < 2) {
        updatedError[field] = "Must be at least 2 characters!";
      } else if (field === "email") {
        const emailRegex = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/i;
        if (!emailRegex.test(safeValue)) {
          updatedError.email = "Invalid email format!";
        } else {
          delete updatedError.email; // Remove error if validation passes
        }
      } else {
        delete updatedError[field]; // Remove error for valid input
      }
      return updatedError; // Return the updated object
    });
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (Object.values(error).some((errMsg) => errMsg)) return;

    checkEmail({ company_id, email }, true).then((res) => {
      if (res.isError) {
        onSubmit();
        setError({});
      } else {
        setError((prev) => ({ ...prev, email: "Email exists already!" }));
      }
    });
  };

  return (
    <form onSubmit={onSubmitHandler} className="relative flex flex-col gap-5">
      {(emailExists.isLoading || emailExists.isFetching) && <Loading className="z-10" absolute />}

      <div className="flex flex-col gap-y-6">
        <span>
          Fill all the fields to <span className="font-bold">ADD A NEW USER</span>
        </span>
        <UserFormInput
          label="Name*"
          name="first_name"
          id="first_name"
          value={first_name}
          onChange={onChange}
          onBlur={() => handleBlur("first_name", first_name)}
          placeholder="Name"
          error={error.first_name}
        />
        <UserFormInput
          label="Surname*"
          name="last_name"
          id="last_name"
          value={last_name}
          onChange={onChange}
          onBlur={() => handleBlur("last_name", last_name)}
          placeholder="Surname"
          error={error.last_name}
        />
        <UserFormInput
          label="Email*"
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={onChange}
          onBlur={() => handleBlur("email", email)}
          placeholder="example@example.com"
          error={error.email}
        />
        <UserFormInput
          label="Phone*"
          type="tel"
          name="phone_number"
          id="phone_number"
          value={phone_number}
          onChange={onChange}
          placeholder="+3897XXXXXXX"
          title="Input does not match the required format: +3897XXXXXXX"
          pattern="\+3897\d{7}"
          error={error.phone_number}
        />
        <UserFormInput
          label="Address*"
          name="address"
          id="address"
          value={address}
          onChange={onChange}
          onBlur={() => handleBlur("address", address)}
          placeholder="Location"
          error={error.address}
        />
        <div>
          <h2 className="mb-3">Primary Language*</h2>
          <Select
            options={transofrmLanguagesToOptions(LANGUAGES)}
            name="language"
            onChange={onChange}
            value={language as Language}
          />
        </div>
        <WithActions closeButton={back} setOpen={onBack}>
          <Button
            type="submit"
            disabled={
              !!!first_name ||
              !!!last_name ||
              !!!email ||
              !!!phone_number ||
              !!!address ||
              !!!language ||
              !!emailExists.isLoading ||
              !!emailExists.isFetching ||
              Object.values(error).some((errMsg) => errMsg)
            }
          >
            Next
          </Button>
        </WithActions>
      </div>
    </form>
  );
};
