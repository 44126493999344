import { CompanyId } from "helpers";

interface RoleHeaderSelectCompanyProps {
  companyId: CompanyId;
  onOpenModal: () => void;
}
export const RoleHeaderSelectCompany = ({ companyId, onOpenModal }: RoleHeaderSelectCompanyProps) => (
  <div className="flex items-center justify-center gap-2">
    <div className="flex items-center gap-1">
      <span className="flex items-center whitespace-nowrap font-bold">Roles for company:</span>
      <span className="flex items-center whitespace-nowrap font-normal">
        {companyId === "0" ? "All Companies" : companyId}
      </span>
    </div>
    <div className="flex items-center gap-1">
      <div className="flex gap-1">
        <span
          className="flex cursor-pointer items-center whitespace-nowrap text-sm font-normal hover:underline"
          onClick={() => onOpenModal()}
        >
          Change
        </span>
      </div>
    </div>
  </div>
);
