import { store } from "app/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { router } from "router";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "i18n.config";
import { Suspense } from "react";
import { Loading } from "components";

let persistor = persistStore(store);

export const App = () => (
  <Suspense
    fallback={
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    }
  >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </Suspense>
);
