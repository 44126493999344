import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Settings = ({
  className = "text-primary",
  viewBox = "0 0 24 19",
  width = 24,
  height = 18,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    fill="currentColor"
  >
    <title>Settings</title>
    <path d="M22 0.5H7C6.31 0.5 5.77 0.85 5.41 1.38L0 9.5L5.41 17.61C5.77 18.14 6.38 18.5 7.07 18.5H22C23.1 18.5 24 17.6 24 16.5V2.5C24 1.4 23.1 0.5 22 0.5ZM22 16.5H7.07L2.4 9.5L7.06 2.5H22V16.5Z" />
    <path d="M9 11C9.82843 11 10.5 10.3284 10.5 9.5C10.5 8.67157 9.82843 8 9 8C8.17157 8 7.5 8.67157 7.5 9.5C7.5 10.3284 8.17157 11 9 11Z" />
    <path d="M14 11C14.8284 11 15.5 10.3284 15.5 9.5C15.5 8.67157 14.8284 8 14 8C13.1716 8 12.5 8.67157 12.5 9.5C12.5 10.3284 13.1716 11 14 11Z" />
    <path d="M19 11C19.8284 11 20.5 10.3284 20.5 9.5C20.5 8.67157 19.8284 8 19 8C18.1716 8 17.5 8.67157 17.5 9.5C17.5 10.3284 18.1716 11 19 11Z" />
  </svg>
);
