import { ArrowDown } from "components";
import { Role, getUserType, isMasterAdmin } from "helpers";
import { useShowAll } from "hooks";
import { twMerge } from "tailwind-merge";

export const RoleTableDataUsers = ({ role }: { role: Role }) => {
  const { assigned_users: users } = role;
  const { displayedData, setShowAll, showAll } = useShowAll(users);
  const userType = getUserType();

  return (
    <>
      {role.status ? (
        <>
          {displayedData.map(({ users, company: { name, id } }, index) => (
            <p key={index} className="whitespace-nowrap">
              {userType && isMasterAdmin(userType?.id) ? `${users.length} | ${name || id}` : users.length}
            </p>
          ))}

          {users.length > 3 && (
            <span
              className="flex cursor-pointer items-center justify-center font-bold hover:underline"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "VIEW LESS" : "VIEW ALL"}
              <ArrowDown className={twMerge("ml-2 text-primary", showAll && "rotate-180")} />
            </span>
          )}
        </>
      ) : (
        "/"
      )}
    </>
  );
};
