import { CompanyId } from "helpers";
import { useState, useEffect } from "react";
import { useGetProfileQuery } from "services";

export const useUser = () => {
  const { data: user } = useGetProfileQuery();
  const [companyId, setCompanyId] = useState<CompanyId>("");

  useEffect(() => {
    resetCompany();
  }, [user]);

  const resetCompany = () => {
    if (!!user) setCompanyId(user.company_id);
  };

  return {
    user,
    companyId,
    setCompanyId,
    resetCompany,
  };
};
