import { SetStateAction, useState } from "react";
import { PasswordFlow } from "helpers";
import { AuthPasswordReset } from "./components/AuthPasswordReset";
import { AuthPasswordRecover } from "./components/AuthPasswordRecover";

export const AuthPassword = () => {
  const [flow, setFlow] = useState<PasswordFlow>("reset");
  const [email, setEmail] = useState<string>("");

  const onEmailSent = (value: SetStateAction<string>) => {
    setEmail(value);
    setTimeout(() => setFlow("recover"), 3000);
  };

  return (
    <div className="text-center">
      {flow === "reset" && <AuthPasswordReset setEmail={onEmailSent} />}
      {flow === "recover" && <AuthPasswordRecover email={email} />}
    </div>
  );
};
