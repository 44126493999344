import { ButtonHTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type ButtonVariant = "bg-primary" | "bg-success" | "bg-error" | "bg-primary/50";
export type ButtonType = "button" | "submit" | "reset";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: ButtonVariant;
  type?: ButtonType;
  disableHover?: boolean;
}
// # 
export const Button = ({
  children,
  className,
  variant = "bg-primary",
  type = "button",
  disableHover = false,
  ...nativeButtonProps
}: ButtonProps) => (
  <button
    type={type}
    className={twMerge(
      "line-clamp-1 flex items-center justify-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap px-12 py-2 font-bold text-white disabled:bg-opacity-20",
      variant,
      !disableHover && "hover:bg-opacity-80",
      className
    )}
    {...nativeButtonProps}
  >
    {children}
  </button>
);
