import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const ClosedEye = ({
  className = "text-primary",
  viewBox = "0 0 15 3",
  width = 22,
  height = 3,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>ClosedEye</title>
    <path d="M14.5 2.5H0.5V0.5H14.5V2.5Z" fill="currentColor" />
  </svg>
);
