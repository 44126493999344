import { RoleUser } from "helpers";
import { HTMLAttributes } from "react";
import { Accordion, AccordionProps, Checkbox } from ".";
import { twMerge } from "tailwind-merge";

type UserAccordionProps = {
  users: RoleUser[];
  onUserChange: ({ checked, user }: { checked: boolean; user: RoleUser }) => void;
  isUserChecked: (user: RoleUser) => boolean;
} & Omit<AccordionProps, "children"> &
  Pick<HTMLAttributes<HTMLElement>, "className">;

export const UserAccordion = ({
  title,
  allSelected,
  anySelected,
  onSelectAllClick,
  disabled,
  users,
  onUserChange,
  isUserChecked,
  className,
}: UserAccordionProps) => (
  <div className={twMerge(className)}>
    <Accordion
      title={title}
      allSelected={allSelected}
      anySelected={anySelected}
      onSelectAllClick={onSelectAllClick}
      disabled={disabled}
    >
      <div className="flex flex-wrap gap-x-4">
        {users &&
          users.length > 0 &&
          users.map((user, index) => {
            const { uuid, first_name, last_name } = user;
            const fullName = `${first_name} ${last_name}`;
            return (
              <Checkbox
                key={index}
                label={fullName}
                id={uuid}
                name={uuid}
                value={uuid}
                onChange={({ target: { checked } }) => onUserChange({ checked, user })}
                checked={isUserChecked(user)}
              />
            );
          })}
      </div>
    </Accordion>
  </div>
);
