import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language, User } from "helpers";

export interface ProfileState {
  user: User | null;
  currentLanguage: Language;
}

const initialState: ProfileState = {
  user: null,
  currentLanguage: "EN",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setCurrentLanguage: (state, action: PayloadAction<Language>) => {
      state.currentLanguage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setCurrentLanguage } = profileSlice.actions;

export default profileSlice.reducer;
