import { LanguageDropdown } from "features";
import { twMerge } from "tailwind-merge";
export interface CardHeaderProps {
  title: string;
  paragraph?: string;
  withLanguage?: boolean;
  paragraphClassName?: string;
}

export const CardHeader = ({ title, paragraph, withLanguage = true, paragraphClassName }: CardHeaderProps) => (
  <div className="mb-3 flex flex-col gap-1 text-center">
    <div className="flex justify-center gap-20">
      {withLanguage ? (
        <>
          <>
            <div className="flex-1"></div>
            <h1>{title}</h1>
            <LanguageDropdown className="flex flex-1 items-center justify-end" />
          </>
        </>
      ) : (
        <h1>{title}</h1>
      )}
    </div>
    {paragraph && <p className={twMerge(paragraphClassName)}>{paragraph}</p>}
  </div>
);
