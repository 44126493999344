import { User } from "helpers";
import { StatusState } from "pages/AdminManagement/components/AdminTable/components";
import { useAdminManagementContext } from "pages/AdminManagement";
import { Dispatch, SetStateAction } from "react";
import { transformError, useChangeStatusMutation } from "services";
import { InputStyledDiv, Status } from "components";

export interface AdminTableDataUserTypeEditProps {
  user: User;
  status: StatusState;
  setStatusState: Dispatch<SetStateAction<StatusState>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
export const AdminTableDataUserTypeEdit = ({
  user,
  status,
  setStatusState,
  setIsLoading,
}: AdminTableDataUserTypeEditProps) => {
  const { enabled, company_id, uuid } = user;
  const { onFetch } = useAdminManagementContext();

  const [changeStatus] = useChangeStatusMutation();

  const onStatusChange = async (company_id: string, id: string, status: boolean) => {
    setIsLoading(true);
    try {
      await changeStatus({
        company_id,
        uuid: id,
        userStatus: status ? "enable" : "disable",
      }).unwrap();

      await onFetch();
    } catch (error) {
      console.error(transformError(error).message);
    } finally {
      setIsLoading(false);
      setStatusState("updated");
    }
  };

  return (
    <InputStyledDiv className="py-[7px]">
      {status === "initial" && (
        <>
          <span className={enabled ? "text-success" : "text-error"}>{enabled ? "Active" : "Inactive"}</span>
          <div className="flex items-center justify-between">
            <Status />
            <span className="cursor-pointer hover:underline" onClick={() => setStatusState("updating")}>
              <>{enabled ? "Deactivate" : "Activate"}</>
            </span>
          </div>
        </>
      )}
      {status === "updating" && (
        <>
          <span>{enabled ? "Deactivate user ?" : "Activate user ?"}</span>
          <div className="flex items-center justify-between">
            <span
              className="cursor-pointer hover:underline"
              onClick={() => {
                setStatusState("initial");
              }}
            >
              No
            </span>
            <span className="cursor-pointer hover:underline" onClick={() => onStatusChange(company_id, uuid, !enabled)}>
              Yes
            </span>
          </div>
        </>
      )}
      {status === "updated" && (
        <>
          <span className={!enabled ? "text-error" : "text-success"}>
            {!enabled ? "User Deactivated" : "User Activated"}
          </span>
          <div className="flex items-center justify-between">
            <Status />
            <span className="cursor-pointer hover:underline" onClick={() => setStatusState("updating")}>
              <>{enabled ? "Deactivate" : "Activate"}</>
            </span>
          </div>
        </>
      )}
    </InputStyledDiv>
  );
};
