import { ChevronLeft, ChevronRight } from "components";
import { HTMLAttributes } from "react";
import ReactPaginate from "react-paginate";

export interface TablePaginationProps extends HTMLAttributes<HTMLElement> {
  page: number;
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
}
export const TablePagination = ({
  page,
  pageCount,
  onPageChange,
  marginPagesDisplayed = 2,
  pageRangeDisplayed = 5,
}: TablePaginationProps) => (
  <ReactPaginate
    pageCount={pageCount}
    previousLabel={<ChevronLeft className="text-white" />}
    nextLabel={<ChevronRight className="text-white" />}
    forcePage={page}
    marginPagesDisplayed={marginPagesDisplayed}
    pageRangeDisplayed={pageRangeDisplayed}
    onPageChange={onPageChange}
    breakClassName={"BREAK flex items-center"}
    containerClassName="CONTAINER flex gap-2"
    previousClassName={"PREVIOUS flex items-center justify-center bg-primary"}
    previousLinkClassName={"PREVIOUS-LINK px-4 py-2"}
    nextClassName="NEXT flex items-center bg-primary"
    nextLinkClassName="NEXT-LINK px-4 py-2"
    activeClassName="ACTIVE bg-primary/20"
    pageClassName="bg-primary flex items-center justify-center"
    pageLinkClassName="px-4 py-2 text-white font-bold hover:no-underline"
    disabledClassName="DISABLED bg-primary/20"
  />
);
