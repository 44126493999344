import { ReactNode, useState } from "react";
import { ArrowDown } from "components";
import { twMerge } from "tailwind-merge";
import { Order } from "helpers";

interface TableHeadingProps {
  onClick: (order: Order) => void;
  children: ReactNode;
  order?: Order;
}

export const TableHeading = ({ onClick, children, order: defaultOrder = "DESC" }: TableHeadingProps) => {
  const [order, setOrder] = useState<Order>(defaultOrder);

  const onClickHandler = () => {
    if (order === "ASC") {
      onClick("DESC");
      setOrder("DESC");
    } else {
      onClick("ASC");
      setOrder("ASC");
    }
  };

  return (
    <span className="flex cursor-pointer items-center justify-center" onClick={() => onClickHandler()}>
      {children} <ArrowDown className={twMerge("ml-2 text-primary", order === "ASC" && "rotate-180")} />
    </span>
  );
};
