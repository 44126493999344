import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface ValidationMessageProps {
  className?: string;
  children?: ReactNode;
}

export const ValidationMessage = ({ className, children }: ValidationMessageProps) => (
  <p className={`${twMerge("flex self-end text-xs leading-6 text-error-text", className)}`}>
    {children && `*${children}`}
  </p>
);
