import { useAppDispatch, useAppSelector } from "app/hooks";
import { Button, OverviewListItem, Bin, UserAccordion, WithActions } from "components";
import { useUsers } from "hooks/useUsers";
import { useState } from "react";
import { RoleTableDetachUsersModal } from "../RoleTableDetachUsersModal";
import { setSelectedUsers } from "services/roleSlice";

type RoleTableEditRoleUsersProps = {
  onBack: () => void;
  onSuccess: () => void;
};

export const RoleTableEditRoleUsers = ({ onBack, onSuccess }: RoleTableEditRoleUsersProps) => {
  const dispatch = useAppDispatch();
  const { assigned_users } = useAppSelector((state) => state.roleSlice.selectedRole);
  const [openDetachUsersModal, setOpenDetachUsersModal] = useState(false);

  const {
    selectedUsers,
    handleOnUserChange,
    handleAllSelected,
    handleAnySelected,
    handleSelectAll,
    handleDeselectAll,
    handleChecked,
    accordionTitle,
    numberOfSelectedUsers,
  } = useUsers();

  return (
    <div className="flex flex-col gap-6">
      <h2>Assigned Users</h2>
      <div className="flex max-h-96 flex-col gap-2 overflow-y-auto" id="scroll">
        {assigned_users &&
          assigned_users.length > 0 &&
          assigned_users.map((group, groupIndex) => (
            <UserAccordion
              title={accordionTitle(group, group.users)}
              allSelected={handleAllSelected(group)}
              anySelected={handleAnySelected(group)}
              onSelectAllClick={() => {
                if (handleAllSelected(group)) {
                  handleDeselectAll(group);
                } else {
                  handleSelectAll(group);
                }
              }}
              disabled={false}
              users={group.users}
              onUserChange={({ checked, user }) => handleOnUserChange({ checked, user, group })}
              isUserChecked={(user) => handleChecked({ group, user })}
              key={groupIndex}
              className="mr-6"
            />
          ))}
      </div>

      {selectedUsers.length > 0 && (
        <div className="flex flex-col items-center">
          <span className="font-bold">
            {numberOfSelectedUsers} user{numberOfSelectedUsers > 1 ? "s" : ""} selected from {selectedUsers.length}{" "}
            {selectedUsers.length === 1 ? "company" : "companies"}
          </span>
          <Button
            onClick={() => {
              dispatch(setSelectedUsers(selectedUsers.flatMap(({ users }) => users)));
              setOpenDetachUsersModal(true);
            }}
            variant="bg-error"
            className="w-fit px-4"
          >
            <Bin className="text-white" />
          </Button>
        </div>
      )}

      <div className="flex flex-col gap-2">
        <div className="max-h-[75px] min-h-[75px] overflow-y-auto border border-primary p-1" id="scroll">
          {selectedUsers.map(({ company, users }, index) => (
            <OverviewListItem
              name={company.name || company.id}
              data={users.map((user) => ({ ...user, name: `${user.first_name} ${user.last_name}` }))}
              key={index}
            />
          ))}
        </div>
      </div>
      <WithActions closeButton="Back" setOpen={onBack}>
        <Button
          onClick={() => {
            // if (isEqual(role.permissions, selectedUsers)) {
            //   onBack();
            // } else {
            //   setConfirmModal(true);
            // }
          }}
          disabled={selectedUsers.length === 0}
        >
          Save
        </Button>
      </WithActions>

      {openDetachUsersModal && (
        <RoleTableDetachUsersModal
          open={openDetachUsersModal}
          setOpen={setOpenDetachUsersModal}
          onSave={() => onSuccess()}
          isLoading={false}
          selectedUsers={selectedUsers}
          title={`You are about to delete ${numberOfSelectedUsers} user${numberOfSelectedUsers > 1 ? "s" : ""} from ${
            selectedUsers.length
          } ${selectedUsers.length === 1 ? "company" : "companies"}`}
        />
      )}
    </div>
  );
};
