import { Button, InputStyledDiv, Bin, Loading } from "components";
import { CompanyId, User, UserRole, isAdmin } from "helpers";

interface EditInfoCompanyRoleProps {
  role: UserRole;
  user: User;
  onAction: ({ id, company_id }: { id: number; company_id: CompanyId }) => void;
  onPrefetchRole: () => void;
  disabledButton?: boolean;
  disabledDelete?: boolean;
  onDetachRole: () => void;
  isLoading: boolean;
}

export const EditInfoCompanyRole = ({
  role,
  user,
  onAction,
  onPrefetchRole,
  disabledButton,
  disabledDelete,
  onDetachRole,
  isLoading,
}: EditInfoCompanyRoleProps) => {
  const {
    id,
    name,
    company: { id: company_id },
  } = role;

  return (
    <div className="relative mb-6 flex flex-col gap-3 sm:flex-row sm:gap-7" onMouseEnter={() => onPrefetchRole()}>
      {isLoading && <Loading absolute />}
      <div className="flex w-full">
        <InputStyledDiv>{name}</InputStyledDiv>
        <Button
          className="min-w-[150px] max-w-[150px] px-2"
          onClick={() => onAction({ id, company_id })}
          disabled={disabledButton}
        >
          Edit
        </Button>
      </div>
      {isAdmin(user.type.id) && (
        <div className="flex items-center">
          <Bin disabled={disabledDelete} onClick={() => onDetachRole()} />
        </div>
      )}
    </div>
  );
};
