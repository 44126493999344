import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const ChevronLeft = ({
  className = "text-primary",
  viewBox = "0 0 9 13",
  width = 9,
  height = 13,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>ChevronLeft</title>
    <path
      d="M8.16016 1.91L3.58016 6.5L8.16016 11.09L6.75016 12.5L0.750156 6.5L6.75016 0.5L8.16016 1.91Z"
      fill="currentColor"
    />
  </svg>
);
