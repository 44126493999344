import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const PlusLarge = ({
  className = "text-primary",
  viewBox = "0 0 15 15",
  width = 15,
  height = 15,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>PlusLarge</title>
    <path d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z" fill="currentColor" />
  </svg>
);
