import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Bin = ({
  className = "text-primary",
  viewBox = "0 0 15 18",
  width = 15,
  height = 18,
  disabled = false,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(disabled ? "opacity-20" : "cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={disabled ? undefined : onClick}
    fill="currentColor"
  >
    <title>Bin</title>
    <path d="M9.62 7.47L7.5 9.59L5.37 7.47L3.96 8.88L6.09 11L3.97 13.12L5.38 14.53L7.5 12.41L9.62 14.53L11.03 13.12L8.91 11L11.03 8.88L9.62 7.47ZM11 1L10 0H5L4 1H0.5V3H14.5V1H11ZM1.5 16C1.5 17.1 2.4 18 3.5 18H11.5C12.6 18 13.5 17.1 13.5 16V4H1.5V16ZM3.5 6H11.5V16H3.5V6Z" />
  </svg>
);
