import { useClickOutside } from "hooks";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useRef, useState } from "react";

export interface DropdownContextValues {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const DropdownContext = createContext<DropdownContextValues | undefined>(undefined);

export const DropdownProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setOpen(false));

  const values: DropdownContextValues = {
    open,
    setOpen,
  };

  return (
    <DropdownContext.Provider value={values}>
      <div ref={dropdownRef} className="relative inline-block text-left">
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (!context) {
    throw new Error("useDropdownContext must be used within a DropdownProvider!");
  }
  return context;
};
