import { CompanyId, GroupPermission, signOut } from "helpers";
import { useCreateRoleMutation } from "services";
import { Modal, ModalProps } from "features";
import { useAppDispatch } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setSelectedRole } from "services/roleSlice";
import { twMerge } from "tailwind-merge";
import { Button, ValidationMessage, Loading, Input, WithActions } from "components";

type NewRoleSetupModalProps = {
  title: string;
  description: string;
  selectedPermissions: GroupPermission[];
  companyId: CompanyId;
  onSuccess: () => void;
} & ModalProps;

export const NewRoleSetupModal = ({
  title,
  description,
  selectedPermissions,
  onSuccess,
  companyId,
  open,
  setOpen,
}: NewRoleSetupModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createRole, createRoleResult] = useCreateRoleMutation();

  const [role, setRole] = useState("");
  const [error, setError] = useState<string>("");

  const onSaveHandler = async () => {
    await createRole({
      company_id: companyId,
      permissions: selectedPermissions,
      role,
    });
  };

  useEffect(() => {
    if (createRoleResult.isSuccess) {
      dispatch(setSelectedRole(createRoleResult.data));
      setError("");
      onSuccess();
    }
    if (createRoleResult.isError) {
      if (createRoleResult.error && "status" in createRoleResult.error && createRoleResult.error.status === 401) {
        const logout = async () => {
          try {
            await signOut();
            navigate("/");
          } catch (error) {}
        };
        setError("User is not authenitcated");
        logout();
      }
      setError(`A role with this name (PR_${role}) has already been created, please choose another name.`);
    }
  }, [createRoleResult]);

  return (
    <Modal
      withActions={false}
      open={open}
      setOpen={setOpen}
      className={twMerge("flex min-h-[370px] max-w-[600px] p-0 text-center")}
    >
      <div className="relative flex w-full flex-col items-center justify-center px-16">
        {createRoleResult.isLoading && <Loading absolute />}
        <h1 className="uppercase">{title}</h1>
        <span className="mb-4 font-light">{description}</span>
        <div className="w-full text-left">
          <h2 className="mb-3">Role Name*</h2>
          <Input
            variant="modal"
            name="role"
            id="role"
            value={role}
            onChange={({ target: { value } }) => setRole(value)}
            placeholder="Role name"
            autoComplete="off"
          />
          <ValidationMessage>{error}</ValidationMessage>
        </div>
        <div className="w-full">
          <WithActions closeButton="Cancel" setOpen={setOpen}>
            <Button onClick={() => onSaveHandler()} disabled={!!!role}>
              Save
            </Button>
          </WithActions>
        </div>
      </div>
    </Modal>
  );
};
