import { Button, OverviewListItem, PermissionAccordion, WithActions } from "components";
import { usePermissions } from "hooks";
import { useState } from "react";
import { useGetPermissionsByGroupQuery } from "services";
import { CompanyId } from "helpers";
import { NewRoleSetupModal } from "../NewRoleSetupModal";

type NewRoleAssignPermissionsProps = {
  back: string;
  companyId: CompanyId;
  onBack: () => void;
  onSuccess: () => void;
};
export const NewRoleAssignPermissions = ({ onBack, back, companyId, onSuccess }: NewRoleAssignPermissionsProps) => {
  const { data: groupedPermissions, isSuccess } = useGetPermissionsByGroupQuery({});
  const [openNewRoleNameModal, setOpenNewRoleNameModal] = useState<boolean>(false);

  const {
    selectedPermissions,
    handleOnPermissionChange,
    handleAllSelected,
    handleAnySelected,
    handleSelectAll,
    handleDeselectAll,
    handleChecked,
    accordionTitle,
  } = usePermissions();

  return (
    <div className="flex flex-col gap-6">
      <h2>Assign permissions to the new role</h2>
      <div className="flex max-h-96 flex-col gap-2 overflow-y-auto" id="scroll">
        {isSuccess &&
          groupedPermissions &&
          groupedPermissions.length > 0 &&
          groupedPermissions.map((group, groupIndex) => (
            <PermissionAccordion
              title={accordionTitle(group, group.permissions)}
              allSelected={handleAllSelected(group)}
              anySelected={handleAnySelected(group)}
              onSelectAllClick={() => {
                if (handleAllSelected(group)) {
                  handleDeselectAll(group);
                } else {
                  handleSelectAll(group);
                }
              }}
              disabled={group.is_default}
              permissions={group.permissions}
              onPermissionChange={({ checked, permission }) => handleOnPermissionChange({ checked, permission, group })}
              isPermissionChecked={(permission) => handleChecked({ group, permission })}
              key={groupIndex}
              className="mr-6"
            />
          ))}
      </div>
      <div className="flex flex-col gap-2">
        <div className="max-h-[75px] min-h-[75px] overflow-y-auto border border-primary p-1" id="scroll">
          {selectedPermissions.map(({ name, permissions }, index) => (
            <OverviewListItem key={index} name={name} data={permissions} />
          ))}
        </div>
      </div>
      <WithActions closeButton={back} setOpen={onBack}>
        <Button onClick={() => setOpenNewRoleNameModal(true)}>Save</Button>
      </WithActions>
      {openNewRoleNameModal && (
        <NewRoleSetupModal
          open={openNewRoleNameModal}
          setOpen={setOpenNewRoleNameModal}
          title="Name the new role"
          description="Choose a name to save the new role"
          companyId={companyId}
          selectedPermissions={selectedPermissions}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};
