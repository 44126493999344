import { TableHeading, TableOptions } from "components/Table";
import { useRoleManagementContext } from "context";
import { getUserType, isMasterAdmin } from "helpers";

export const RoleTableHeadings = () => {
  const { onSort } = useRoleManagementContext();
  const userType = getUserType();

  const headings: Array<JSX.Element | string> = [
    <TableHeading onClick={(order) => onSort("role-name", order)}>Role name</TableHeading>,
    "Assigned permissions | Number of permissions",
    userType && isMasterAdmin(userType?.id) ? "Number of user assigned | Company" : "Number of user assigned",
    "Status",
    <TableOptions>Options</TableOptions>,
  ];
  return (
    <>
      {headings.map((heading, index) => (
        <th
          key={index}
          scope="col"
          className="mx-8 h-[100px] max-h-[100px] w-full min-w-[200px] whitespace-nowrap p-4 text-sm font-semibold text-primary"
        >
          {heading}
        </th>
      ))}
    </>
  );
};
