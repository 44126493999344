import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const ChevronRight = ({
  className = "text-primary",
  viewBox = "0 0 9 13",
  width = 9,
  height = 13,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>ChevronRight</title>
    <path
      d="M0.839844 1.91L5.41984 6.5L0.839844 11.09L2.24984 12.5L8.24984 6.5L2.24984 0.5L0.839844 1.91Z"
      fill="currentColor"
      // stroke="currentColor"
    />
  </svg>
);
