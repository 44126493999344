import { AWSAuth } from "authentication";
import { removeUser } from "./localStorage";

export const signOut = async () => {
  try {
    await AWSAuth.signOut();
    removeUser();
  } catch (error) {
    console.log("ERROR", error);
  }
};
