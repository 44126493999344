import { Radio } from "components";
import { Author, GroupPermission, ParkingId, PermissionLevel, Role } from "helpers";

interface AssignPermissionsRolesContentProps {
  roles: Role[];
  selectedRole: Role;
  disabledRole?: number;
  parking_id?: ParkingId;
  onChange: ({ role }: { role: Role }) => void;
  groupedPermissions?: GroupPermission[];
}

export const AssignPermissionsRolesContent = ({
  roles,
  selectedRole,
  onChange,
  groupedPermissions,
  disabledRole,
}: AssignPermissionsRolesContentProps) => {
  const default_permissions =
    (groupedPermissions &&
      groupedPermissions.length > 0 &&
      groupedPermissions.filter((groupedPermission) => groupedPermission.is_default)) ||
    [];

  return (
    <div className="vertical relative h-[164px] overflow-y-auto" id="scroll">
      <div className="mr-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 md:grid-cols-3">
        {roles.map((role, index) => {
          const { id, name } = role;
          return (
            <Radio
              label={name}
              id={String(id)}
              name="companyRole"
              value={id}
              key={index}
              checked={selectedRole?.id === id}
              onChange={() => onChange({ role })}
              disabled={disabledRole === id}
            />
          );
        })}
        <Radio
          label="Create a role"
          id="create-role"
          name="create-role"
          value="create-role"
          key="create-role"
          checked={selectedRole?.id === 0 && selectedRole?.name === "create-role"}
          onChange={() => {
            onChange({
              role: {
                id: 0,
                name: "create-role",
                company: {
                  id: "",
                  name: "",
                },
                permission_level: {} as PermissionLevel,
                permissions: default_permissions,
                assigned_users: [],
                author: {} as Author,
                status: false,
                is_predefined: false,
                created_at: "",
                updated_at: "",
              },
            });
          }}
        />
      </div>
    </div>
  );
};
