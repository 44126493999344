export const ProfileTableHeaders = () => {
  const headings: Array<string> = ["Data type", "Your data", "Additional info", "Options"];
  return (
    <>
      {headings.map((heading, index) => (
        <th
          key={index}
          scope="col"
          className="h-[80px] max-h-[80px] w-[320px] min-w-[320px] break-words py-4 text-sm font-semibold text-primary"
        >
          {heading}
        </th>
      ))}
    </>
  );
};
