import { Permission } from "helpers";
import { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { Checkbox, Accordion, AccordionProps } from ".";

type PermissionAccordionProps = {
  permissions: Permission[];
  onPermissionChange: ({ checked, permission }: { checked: boolean; permission: Permission }) => void;
  isPermissionChecked: (permission: Permission) => boolean;
} & Omit<AccordionProps, "children"> &
  Pick<HTMLAttributes<HTMLElement>, "className">;

export const PermissionAccordion = ({
  title,
  allSelected,
  anySelected,
  onSelectAllClick,
  disabled,
  permissions,
  onPermissionChange,
  isPermissionChecked,
  className,
}: PermissionAccordionProps) => (
  <div className={twMerge(className)}>
    <Accordion
      title={title}
      allSelected={allSelected}
      anySelected={anySelected}
      onSelectAllClick={onSelectAllClick}
      disabled={disabled}
    >
      <div className="flex flex-wrap gap-x-4">
        {permissions &&
          permissions.length > 0 &&
          permissions.map((permission, index) => (
            <Checkbox
              key={index}
              label={permission.name}
              id={String(permission.id)}
              name={permission.name}
              value={permission.name}
              onChange={({ target: { checked } }) => onPermissionChange({ checked, permission })}
              checked={isPermissionChecked(permission)}
            />
          ))}
      </div>
    </Accordion>
  </div>
);
