import { Card, ProtectedRoute } from "components";
import { AuthProvider, RoleManagementProvider } from "context";
import { AuthLayout, MainLayout, ProfileLayout } from "layouts";
import {
  AdminManagement,
  UserManagement,
  ComppanyManagement,
  ParkingManagement,
  RoleManagement,
  ProfileTable,
  ProfileChangePassword,
  Profile2FA,
  Authentication,
  AuthPassword,
  AdminManagementProvider,
} from "pages";
import { createBrowserRouter, Navigate } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <ProtectedRoute>
        <Navigate to="/" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    element: (
      <AuthLayout>
        <Card>
          <AuthProvider>
            <Authentication />
          </AuthProvider>
        </Card>
      </AuthLayout>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <AuthLayout>
        <Card>
          <AuthPassword />
        </Card>
      </AuthLayout>
    ),
  },
  {
    path: "/admin-management",
    element: (
      <ProtectedRoute>
        <MainLayout>
          <AdminManagementProvider>
            <AdminManagement />
          </AdminManagementProvider>
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/user-management",
    element: (
      <ProtectedRoute>
        <MainLayout>
          <UserManagement />
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/company-management",
    element: (
      <ProtectedRoute>
        <MainLayout>
          <ComppanyManagement />
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/parking-management",
    element: (
      <ProtectedRoute>
        <MainLayout>
          <ParkingManagement />
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/role-management",
    element: (
      <ProtectedRoute>
        <MainLayout>
          <RoleManagementProvider>
            <RoleManagement />
          </RoleManagementProvider>
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <ProfileLayout>
          <ProfileTable />
        </ProfileLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/change-password",
    element: (
      <ProtectedRoute>
        <ProfileLayout>
          <ProfileChangePassword />
        </ProfileLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/2fa",
    element: (
      <ProtectedRoute>
        <ProfileLayout>
          <Profile2FA />
        </ProfileLayout>
      </ProtectedRoute>
    ),
  },
]);
