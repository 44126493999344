import { ReactNode, useState } from "react";
import { Button } from "./Button";
import { twMerge } from "tailwind-merge";
import { InputStyledDiv } from "./InputStyledDiv";
import { Info, PlusSmall, Triangle } from ".";

export interface AccordionProps {
  title: string;
  children: ReactNode;
  allSelected?: boolean;
  anySelected?: boolean;
  disabled?: boolean;
  onSelectAllClick: () => void;
}

export const Accordion = ({
  title,
  children,
  allSelected = false,
  anySelected = false,
  disabled = false,
  onSelectAllClick,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  const disabledClasses = "opacity-50 pointer-events-none";

  const accordion = (
    <div className="flex flex-1">
      <div className={twMerge("w-full border border-primary")}>
        <div className="relative flex items-center justify-center">
          <span className={twMerge("p-1", anySelected ? "font-bold" : "font-light")}>{title}</span>
          {(anySelected || isOpen) && (
            <Button
              className="absolute right-0 h-full w-[100px]"
              variant={allSelected ? "bg-error" : "bg-primary/50"}
              onClick={onSelectAllClick}
            >
              {allSelected ? "Deselect All" : "Select All"}
            </Button>
          )}
        </div>
        {isOpen && <div className="border-t border-primary p-4">{children}</div>}
      </div>
    </div>
  );

  const additionalButtons = (
    <div className="flex h-[34px]">
      <span
        className={twMerge(
          "flex cursor-pointer items-center justify-center border-y border-primary px-4",
          isOpen ? "bg-primary" : "bg-primary/10"
        )}
        onClick={toggleAccordion}
      >
        <Triangle className={twMerge("transform transition-transform", isOpen && "rotate-180 text-white")} />
      </span>
      <span
        className={twMerge(
          "flex cursor-pointer items-center justify-center border border-l-0 border-primary px-4",
          anySelected ? "bg-success" : "bg-primary"
        )}
        onClick={toggleAccordion}
      >
        <PlusSmall />
      </span>
    </div>
  );

  const info = (
    <div className="absolute right-44 flex flex-col">
      <div onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} className="relative">
        <Info width={12} height={12} className="ml-1" />
      </div>
      {showInfo && (
        <InputStyledDiv className="absolute z-[99999] -mt-2 ml-4 w-max bg-error/20 bg-opacity-100 p-0 text-xs font-thin">
          Lorem ipsum
        </InputStyledDiv>
      )}
    </div>
  );

  return (
    <div className={twMerge("relative flex", disabled && disabledClasses)} aria-disabled={disabled}>
      {accordion}
      {additionalButtons}
      {/* {info} */}
    </div>
  );
};
