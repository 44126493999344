import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const ResendTemporaryPassword = ({
  className = "text-error",
  viewBox = "0 0 25 24",
  width = 25,
  height = 18,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>ResendTemporaryPassword</title>
    <g clipPath="url(#clip0_2653_43147)">
      <path
        d="M12.5 1L3.5 5V11C3.5 16.55 7.34 21.74 12.5 23C17.66 21.74 21.5 16.55 21.5 11V5L12.5 1ZM12.5 11.99H19.5C18.97 16.11 16.22 19.78 12.5 20.93V12H5.5V6.3L12.5 3.19V11.99Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2653_43147">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
