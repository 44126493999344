import { useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { Modal, ModalProps } from "features";
import { RolePermissions } from "pages/RoleManagement/components/RolePermissions";
import { RoleOverview } from "pages/RoleManagement/components/RoleOverview";
import { RoleTableEditRoleUsers } from "./components/RoleTableEditRoleUsers";
import { useRoleManagementContext } from "context";
import { usePermissionApiPrefetch } from "services";

type ModalStep = "initial" | "users" | "permissions" | "success";

interface RoleTableEditRoleModalProps extends ModalProps {}

export const RoleTableEditRoleModal = ({ open, setOpen }: RoleTableEditRoleModalProps) => {
  const { onFetch } = useRoleManagementContext();
  const {
    selectedRole: {
      permission_level: { id: permission_level_id },
    },
  } = useAppSelector((state) => state.roleSlice);

  const isParkingBased = permission_level_id === 2;
  const isAccountBased = permission_level_id === 3;

  const [step, setStep] = useState<ModalStep>("initial");

  const prefetchPermissions = usePermissionApiPrefetch("getPermissionsByGroup");

  useEffect(() => {
    prefetchPermissions({ permission_level_id: isParkingBased ? 2 : undefined }, { ifOlderThan: 5 });
  }, []);

  return (
    <Modal withActions={false} open={open} setOpen={setOpen}>
      {step === "initial" && (
        <RoleOverview
          onClose={() => setOpen(false)}
          onEditUsers={() => setStep("users")}
          onEditPermissions={() => setStep("permissions")}
          disabledEditPermissions={isAccountBased}
        />
      )}
      {step === "users" && (
        <RoleTableEditRoleUsers onBack={() => setStep("initial")} onSuccess={() => setStep("initial")} />
      )}
      {step === "permissions" && (
        <RolePermissions
          onBack={() => setStep("initial")}
          onSuccess={() => {
            onFetch();
            setStep("initial");
          }}
        />
      )}
    </Modal>
  );
};
