import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLazyGetPermissionsByGroupQuery, useUpdateRoleMutation } from "services";
import { useEffect, useState } from "react";
import { usePermissions } from "hooks";
import { Button, PermissionAccordion, OverviewListItem, WithActions } from "components";
import { setSelectedRole } from "services/roleSlice";
import { RolePermissionsConfirmModal } from "./components/RolePermissionsConfirmModal";
import { isEqual } from "lodash";

type RolePermissionsProps = {
  onBack: () => void;
  onSuccess: () => void;
};

export const RolePermissions = ({ onBack, onSuccess }: RolePermissionsProps) => {
  const dispatch = useAppDispatch();
  const { selectedRole: role } = useAppSelector((state) => state.roleSlice);
  const isCompanyBased = role.permission_level.id === 1;
  const isParkingBased = role.permission_level.id === 2;
  const isAccountBased = role.permission_level.id === 3;

  const [getPermissionsByGroup, { data: groupedPermissions, isSuccess }] = useLazyGetPermissionsByGroupQuery();
  const [updateRole, { data: updatedRole, isSuccess: isSuccessRole, isError, isLoading }] = useUpdateRoleMutation();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [title, setTitle] = useState(`You are about to make changes to the permissions of role ${role.name}`);
  const [label, setLabel] = useState("I understand that this action can affect multiple users.");

  const {
    selectedPermissions,
    setSelectedPermissions,
    handleOnPermissionChange,
    handleAllSelected,
    handleAnySelected,
    handleSelectAll,
    handleDeselectAll,
    handleChecked,
    accordionTitle,
  } = usePermissions();

  useEffect(() => {
    getPermissionsByGroup({ permission_level_id: isParkingBased ? 2 : isAccountBased ? 3 : undefined }, true);
  }, []);

  useEffect(() => {
    if (role.id && role.permissions) {
      setSelectedPermissions(role.permissions);
    }
  }, [role]);

  useEffect(() => {
    if (isSuccessRole && updatedRole) {
      dispatch(setSelectedRole(updatedRole));
      setTitle(`Permissions of the role ${role.name} has been changed`);
      setLabel("");
      setTimeout(() => {
        setConfirmModal(false);
        onSuccess();
      }, 2000);
    }
    if (isError) {
    }
  }, [isSuccessRole, isError]);

  const non_defaults = selectedPermissions.filter(({ is_default }) => !is_default);

  const ignore_parking_based = selectedPermissions.filter(
    ({ is_default, permissions }) => !is_default && permissions[0].permission_level.id !== 2
  );

  const disabledButton = isParkingBased ? non_defaults.length === 0 : ignore_parking_based.length === 0;

  return (
    <div className="flex flex-col gap-6">
      <h2>Permissions assigned to role {role.name}</h2>
      <div className="flex max-h-96 flex-col gap-2 overflow-y-auto" id="scroll">
        {isSuccess &&
          groupedPermissions &&
          groupedPermissions.length > 0 &&
          groupedPermissions.map((group, groupIndex) => (
            <PermissionAccordion
              title={accordionTitle(group, group.permissions)}
              allSelected={handleAllSelected(group)}
              anySelected={handleAnySelected(group)}
              onSelectAllClick={() => {
                if (handleAllSelected(group)) {
                  handleDeselectAll(group);
                } else {
                  handleSelectAll(group);
                }
              }}
              disabled={group.is_default}
              permissions={group.permissions}
              onPermissionChange={({ checked, permission }) => handleOnPermissionChange({ checked, permission, group })}
              isPermissionChecked={(permission) => handleChecked({ group, permission })}
              key={groupIndex}
              className="mr-6"
            />
          ))}
      </div>
      <div className="flex flex-col gap-2">
        <div className="max-h-[75px] min-h-[75px] overflow-y-auto border border-primary p-1" id="scroll">
          {selectedPermissions.map(({ name, permissions }, index) => (
            <OverviewListItem name={name} data={permissions} key={index} />
          ))}
        </div>
      </div>
      <WithActions closeButton="Back" setOpen={onBack}>
        <Button
          onClick={() => {
            if (isEqual(role.permissions, selectedPermissions)) {
              onBack();
            } else {
              setConfirmModal(true);
            }
          }}
          disabled={disabledButton}
        >
          Save
        </Button>
      </WithActions>
      {confirmModal && (
        <RolePermissionsConfirmModal
          open={confirmModal}
          setOpen={setConfirmModal}
          title={title}
          label={label}
          loading={isLoading}
          onSave={() =>
            updateRole({ role_id: role.id, permissions: selectedPermissions.filter(({ is_default }) => !is_default) })
          }
        />
      )}
    </div>
  );
};
