import { Button, InputStyledDiv, Bin, Loading } from "components";
import { CompanyId, UserRole } from "helpers";

interface EditInfoParkingRoleProps {
  role: UserRole;
  onAction: ({ id, company_id }: { id: number; company_id: CompanyId }) => void;
  onPrefetchRole: () => void;
  disabledButton?: boolean;
  disabledDelete?: boolean;
  onDetachRole: () => void;
  isLoading: boolean;
}
export const EditInfoParkingRole = ({
  role,
  onAction,
  onPrefetchRole,
  disabledButton,
  disabledDelete,
  onDetachRole,
  isLoading,
}: EditInfoParkingRoleProps) => {
  const {
    id,
    name,
    company: { id: company_id },
    parking,
  } = role;

  return (
    <div className="relative mb-6 flex flex-col gap-3 sm:flex-row sm:gap-7" onMouseEnter={() => onPrefetchRole()}>
      {isLoading && <Loading absolute />}
      <div className="flex w-full flex-col gap-3 sm:flex-row sm:gap-7">
        <InputStyledDiv className="text-center">{parking?.name || parking?.id}</InputStyledDiv>
        <InputStyledDiv className="text-center">{name}</InputStyledDiv>
      </div>
      <div className="flex items-center gap-8">
        <Button
          disabled={disabledButton}
          onClick={() => onAction({ id, company_id })}
          className="min-w-[150px] max-w-[150px] px-2"
        >
          Edit
        </Button>
        <Bin disabled={disabledDelete} onClick={() => onDetachRole()} />
      </div>
    </div>
  );
};
