import { useState } from "react";
import { Select, InputStyledDiv, Close, Info } from "components";
import { TableFilter, TableHeading, TableOptions } from "components/Table";
import { useAdminManagementContext } from "pages/AdminManagement/utils/AdminManagementContext";
import { COMPANIES, FILTER_USER_TYPE, STATUSES, isMasterAdmin, isSuperAdmin, transformCompanyToOptions } from "helpers";

export const AdminTableHeadings = () => {
  const {
    user,
    companyId: selectedCompany,
    params,
    onResetFilters,
    onSort,
    onFilterByStatus,
    onFilterByUserType,
    onChangeCompany,
  } = useAdminManagementContext();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const resetFilters = (
    <div className="absolute -mt-1.5 flex flex-col">
      {showInfo && (
        <InputStyledDiv className="absolute -mt-4 ml-6 w-max bg-primary/5 p-0 text-xs font-thin">
          Clear All Filters
        </InputStyledDiv>
      )}
      <div onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} className="relative">
        <Info width={7} height={7} className="ml-4" />
      </div>
      <Close className="text-error-text" onClick={onResetFilters} />
    </div>
  );

  const headings: Array<JSX.Element | string> = [
    <>
      {resetFilters}
      <TableHeading onClick={(order) => onSort("full-name", order)} order={params.order || "DESC"}>
        Full Name
      </TableHeading>
    </>,
    <Select
      options={
        user && isMasterAdmin(user.type.id) ? transformCompanyToOptions(COMPANIES) : [{ value: user?.company_id! }]
      }
      placeholder="Companies"
      onChange={onChangeCompany}
      value={selectedCompany === "0" ? "" : selectedCompany}
      disabled={user && !isMasterAdmin(user.type.id)}
    />,
    "Phone number & Email",
    <TableHeading onClick={(order) => onSort("last-login", order)}>Last Sign In</TableHeading>,
    <TableFilter onFilter={onFilterByStatus} values={STATUSES} label="Status" selectedFilter={params.status} />,
    <TableFilter
      onFilter={onFilterByUserType}
      values={FILTER_USER_TYPE}
      label="User Type"
      selectedFilter={params.type}
    />,
    <TableOptions>Options</TableOptions>,
  ];

  return (
    <>
      {headings.map((heading, index) => (
        <th
          key={index}
          scope="col"
          className="h-[100px] max-h-[100px] w-[200px] min-w-[200px] break-words py-4 text-sm font-semibold text-primary"
        >
          {heading}
        </th>
      ))}
    </>
  );
};
