import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Button, ValidationMessage, Input } from "components";
import { Link } from "react-router-dom";
import { AWSAuth } from "authentication";
import { StepState } from "helpers";

interface AuthPasswordResetFormProps extends Pick<StepState, "setStep"> {
  onEmailSent: (email: string) => void;
}

export const AuthPasswordResetForm = ({ setStep, onEmailSent }: AuthPasswordResetFormProps) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    setDisabled(!email);
    !!error && setError("");
  }, [email]);

  const onChangeHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => setEmail(value);

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setDisabled(() => true);

    try {
      await AWSAuth.forgotPassword(email);
      onEmailSent(email);
      setStep(() => 1);
    } catch (err: any) {
      console.error(err);
      setError(err.message ? err.message : err);
    } finally {
      setDisabled(() => false);
    }
  };

  return (
    <form onSubmit={onSubmitHandler} autoComplete="off">
      <div className="space-y-8">
        <div className="flex flex-col">
          <Input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={onChangeHandler}
            error={!!error}
            placeholder="example@example.com"
            autoComplete="off"
          />
          <ValidationMessage>{error}</ValidationMessage>
        </div>
        <div className="flex">
          <div className="flex flex-1 items-center justify-center">
            <Link to="/">Go Back</Link>
          </div>
          <div className="flex-1">
            <Button type="submit" disabled={disabled}>
              Request a link
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
