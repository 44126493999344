import { ChangeEvent, useState } from "react";
import { Radio, RadioProps } from "./Radio";
import { twMerge } from "tailwind-merge";

type DefaultValue = "off" | "on";
type RequiredRadioProps = Required<Pick<RadioProps, "label" | "id" | "value">>;
type ExtendedRequiredRadioProps = Required<Pick<RadioProps, "name" | "onChange">>;

interface ToggleProps extends ExtendedRequiredRadioProps {
  off: RequiredRadioProps;
  on: RequiredRadioProps;
  defaultValue: DefaultValue;
}

export const Toggle = ({ off, on, name, defaultValue, onChange }: ToggleProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue === "off" ? off.value : on.value);

  const checkedClasses = twMerge(
    "text-white",
    selectedValue === off.value ? "bg-error border-error" : "bg-success border-success"
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <div className="flex">
      <Radio
        {...off}
        name={name}
        checked={selectedValue === off.value}
        onChange={handleOnChange}
        variant="custom"
        checkedClasses={checkedClasses}
      />
      <Radio
        {...on}
        name={name}
        checked={selectedValue === on.value}
        onChange={handleOnChange}
        variant="custom"
        checkedClasses={checkedClasses}
      />
    </div>
  );
};
