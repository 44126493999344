import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Triangle = ({
  className = "text-primary",
  viewBox = "0 0 8 4",
  width = 8,
  height = 4,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>Triangle</title>
    <path d="M0.666504 0.666016L3.99984 3.99935L7.33317 0.666016H0.666504Z" fill="currentColor" />
  </svg>
);
