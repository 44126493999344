import { signOut } from "helpers";
import { Link, useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal } from "features";
import AvatarEditor from "react-avatar-editor";
import { useAttachPictureMutation, useGetProfileQuery, useProfilePrefetch } from "services";
import { Button, ValidationMessage, Loading, Upload, WithActions, Dropdown } from "components";

export const ProfileDropdown = () => {
  const navigate = useNavigate();
  const prefetch = useProfilePrefetch("getProfile");
  const { data, isLoading, isError, refetch } = useGetProfileQuery();
  const [openModal, setOpenModal] = useState(false);
  const [displayImage, setDisplayImage] = useState<File | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [
    trigger,
    { isLoading: isLoadingAttach, isError: isErrorAttach, isSuccess: isSuccessAttach, error: errorAttach },
  ] = useAttachPictureMutation();
  const imageRef = useRef<AvatarEditor | null>(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fallbackImage =
    data && `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${data.first_name}+${data.last_name}`;

  const onLogoutHandler = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {}
  };

  const initialState = () => {
    setDisplayImage(null);
    setImage(null);
  };

  const errorState = () => {
    initialState();

    setHasError(true);
    setDisabledButton(true);
  };

  const handleLoadSuccess = () => {
    setHasError(false);
    setDisabledButton(false);
  };

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0 && event.target.files[0].size <= 3145728) {
      setDisplayImage(event.target.files[0]);
    } else {
      errorState();
    }
  };

  const handleSetImage = async () => {
    if (displayImage && imageRef.current && imageRef.current instanceof AvatarEditor) {
      const canvas = imageRef.current.getImage();
      canvas.toBlob(
        (blob: Blob | null) => {
          if (blob) {
            const file = new File([blob], displayImage?.name || "avatar.png", {
              type: displayImage?.type || "image/png",
            });
            setImage(file);
          }
        },
        displayImage?.type || "image/png"
      );
    }
  };

  const handleUploadImage = () => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = (reader.result as string).split(",")[1];
        trigger({ fileName: image.name, base64Data });
      };
      reader.readAsDataURL(image);
    }
  };

  useEffect(() => {
    if (isError /* && "status" in error && error.status === 401 */) {
      onLogoutHandler();
    }
  }, [isError]);

  useEffect(() => {
    if (!isLoadingAttach && isSuccessAttach) {
      refetch();
      setOpenModal(false);
    }
    if (isErrorAttach) {
      errorState();
    }
  }, [isErrorAttach, isLoadingAttach, isSuccessAttach, errorAttach]);

  useEffect(() => {
    if (!openModal) {
      initialState();
    }
  }, [openModal]);

  return (
    <>
      {isLoading || !data ? (
        <Loading height={32} width={32} />
      ) : (
        <div className="flex items-center" onMouseEnter={() => prefetch()}>
          <Dropdown>
            <>
              <Dropdown.Trigger>
                <div className="cursor-pointer">
                  <img
                    src={data.picture?.url}
                    alt="User avatar"
                    className="h-8 w-8 rounded-full object-cover"
                    loading="lazy"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = fallbackImage || "";
                    }}
                  />
                </div>
              </Dropdown.Trigger>
              <Dropdown.Items customOpen={openModal}>
                <div className="p-4">
                  <ul className="space-y-1">
                    <li className="font-bold">
                      {data.first_name} {data.last_name}
                    </li>
                    <li className="font-light">{data.company_id}</li>
                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Change profile image
                    </li>
                    <li>
                      <Link className="text-base" to="/profile">
                        My profile
                      </Link>
                    </li>
                    <li className="cursor-pointer" onClick={onLogoutHandler}>
                      Log out
                    </li>
                  </ul>
                </div>
              </Dropdown.Items>
            </>
            {openModal && (
              <Modal withActions={false} open={openModal} setOpen={setOpenModal} className="w-[390px] p-5">
                <div className="relative">
                  {isLoadingAttach && <Loading absolute />}
                  <span>
                    Change your <b>PROFILE IMAGE</b>
                  </span>
                  <div className="flex flex-col items-center justify-center gap-6">
                    <Upload onChange={handleChangeImage} placeholder="Upload new profile image" />
                    <AvatarEditor
                      width={350}
                      height={350}
                      ref={imageRef}
                      image={displayImage || ""}
                      border={0}
                      borderRadius={300}
                      color={[0, 0, 0, 0.5]}
                      onLoadFailure={errorState}
                      onLoadSuccess={handleLoadSuccess}
                      onImageReady={handleSetImage}
                      onPositionChange={handleSetImage}
                    />
                    {hasError && <ValidationMessage>File must be smaller than 3mb</ValidationMessage>}
                    <WithActions closeButton="Cancel" setOpen={setOpenModal}>
                      <Button onClick={() => handleUploadImage()} disabled={disabledButton}>
                        Confirm
                      </Button>
                    </WithActions>
                  </div>
                </div>
              </Modal>
            )}
          </Dropdown>
        </div>
      )}
    </>
  );
};
