import { getToken, refreshUser } from "helpers";
import { useEffect } from "react";
import { useState } from "react";
export const useProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const refresh = async () => {
      await refreshUser();
      setIsAuthenticated(!!getToken());
    };
    refresh();
  }, []);

  return { isAuthenticated };
};
