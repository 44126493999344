import { OverviewListItem } from "components";
import { GroupPermission } from "helpers";

interface AssignPermissionsOverviewProps {
  groupedPermissions?: GroupPermission[];
  rolePermissions: GroupPermission[];
  selectedPermissions: GroupPermission[];
}

export const AssignPermissionsOverview = ({
  groupedPermissions,
  rolePermissions,
  selectedPermissions,
}: AssignPermissionsOverviewProps) => (
  <>
    {((groupedPermissions && groupedPermissions.length > 0) || rolePermissions.length > 0) && (
      <div className="flex flex-col gap-2">
        <div className="max-h-[75px] overflow-y-auto border border-primary p-1" id="scroll">
          {selectedPermissions.map(({ name, permissions }, index) => (
            <OverviewListItem name={name} data={permissions} key={index} />
          ))}
        </div>
      </div>
    )}
  </>
);
