import { twMerge } from "tailwind-merge";
import { InputType, InputVariant } from "components";

interface InputClassesProps {
  error?: boolean;
  type?: InputType;
  variant?: InputVariant;
}

export const getInputClasses = ({ error = false, type = "text", variant = "default" }: InputClassesProps) =>
  twMerge(
    "w-full border border-primary border-opacity-10 bg-primary bg-opacity-10 px-4 py-2 text-primary placeholder:text-primary placeholder:text-opacity-50 focus:ring-0",
    type === "password" && "pr-11",
    variant === "modal" && "bg-white border-grey border-opacity-100 focus:border-grey",
    error && "border-r-8 !border-r-error"
  );
