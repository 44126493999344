import { RoleSort, SortItem, UserSort } from "helpers";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

type SortType = UserSort | RoleSort;

interface SortProps {
  label: string;
  values: SortType[];
  onSort: (sort?: SortItem) => void;
  selectedSort?: SortItem;
}

export const Sort = ({ label, values, onSort, selectedSort }: SortProps) => {
  const [activeSort, setActiveSort] = useState<SortItem | undefined>(selectedSort);

  const onClickHandler = (sort?: SortItem) => {
    if (activeSort !== sort) {
      onSort(sort);
      setActiveSort(sort);
    }
  };

  useEffect(() => {
    setActiveSort(selectedSort);
  }, [selectedSort]);

  return (
    <div className="flex items-center justify-center gap-1">
      <span className="flex items-center whitespace-nowrap font-bold">{label}</span>
      <div className="flex items-center gap-1">
        {values.map(({ id, name }, index) => (
          <div key={index} className="flex gap-1">
            <span
              className={twMerge(
                "flex cursor-pointer items-center whitespace-nowrap text-sm font-normal hover:underline",
                activeSort === id && "cursor-auto font-bold hover:no-underline"
              )}
              onClick={() => onClickHandler(id)}
            >
              {name}
            </span>
            {index !== values.length - 1 && <>|</>}
          </div>
        ))}
      </div>
    </div>
  );
};
