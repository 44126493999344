import { Button, CardHeader, ValidationMessage, Input, Info, Loading } from "components";
import { useChangePasswordMutation } from "services";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ChangePasswordFormI {
  old: string;
  password: string;
  repeatPassword: string;
}

const initialData: ChangePasswordFormI = {
  old: "",
  password: "",
  repeatPassword: "",
};

export const ProfileChangePassword = () => {
  const [data, setData] = useState<ChangePasswordFormI>(initialData);
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [changePassword, { isError, isLoading, isSuccess }] = useChangePasswordMutation();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    setData((oldObject: ChangePasswordFormI) => ({
      ...oldObject,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDisabled(() => true);

    if (data.password !== data.repeatPassword) {
      setError("Passwords are not matching");
      setDisabled(() => false);
      return;
    }

    await changePassword({ oldPassword: data.old, newPassword: data.password, confirmPassword: data.repeatPassword });
  };

  useEffect(() => {
    if (isError) {
      setError("ERROR"); // TODO: Do better error handling!
      setDisabled(() => false);
    }

    if (isSuccess && !isLoading) {
      setShowSuccess(true);
      setDisabled(() => false);
    }
  }, [isError, isLoading, isSuccess]);

  useEffect(() => {
    setDisabled(!data.password || !data.repeatPassword);
    !!error && setError("");
  }, [data]);

  return (
    <div className="flex h-full items-center justify-center">
      {showSuccess ? (
        <div className="flex flex-col items-center justify-center gap-4 text-center">
          <Info className="text-success" />
          <h2 className="font-normal">You have successfully changed the password for your account.</h2>
          <Link to="/profile">Go back to General Information</Link>
        </div>
      ) : (
        <div className="relative max-w-[460px]">
          {isLoading && <Loading absolute />}
          <CardHeader
            withLanguage={false}
            title="Change Password"
            paragraph="Please choose a new strong password with a minimum of 8 characters, including at least one small and one big
            letter, number, and a symbol (ex. . , $ % # @ !)"
          />
          <form onSubmit={onSubmitHandler}>
            <div className="space-y-4">
              {/* Input below is only for accessibility reasons */}
              <Input className="hidden" autoComplete="username" />
              <Input
                id="old"
                type="password"
                name="old"
                value={data.old}
                placeholder="Old password"
                onChange={onChangeHandler}
                error={!!error}
                autoComplete="off"
              />
              <Input
                id="password"
                type="password"
                name="password"
                value={data.password}
                placeholder="New password"
                onChange={onChangeHandler}
                error={!!error}
                autoComplete="new-password"
              />
              <Input
                type="password"
                name="repeatPassword"
                value={data.repeatPassword}
                placeholder="Repat password"
                onChange={onChangeHandler}
                error={!!error}
                autoComplete="new-password"
              />
            </div>
            <div className="mt-2 flex justify-end">
              <ValidationMessage>{error}</ValidationMessage>
            </div>
            <Button type="submit" className="mx-auto mt-5 px-16 sm:min-w-[200px]" disabled={disabled}>
              Change password
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};
