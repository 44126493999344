import { AssignedUser, RoleUser, getCompanyName } from "helpers";
import { useState } from "react";

export const useUsers = () => {
  const [selectedUsers, setSelectedUsers] = useState<AssignedUser[]>([]);

  const selectedSelectionsGroupLength = ({
    selectedUsers,
    group,
  }: {
    selectedUsers: AssignedUser[];
    group: AssignedUser;
  }) => selectedUsers.find(({ company }) => company.id === group.company.id)?.users.length;

  const handleOnUserChange = ({ checked, user, group }: { checked: boolean; user: RoleUser; group: AssignedUser }) => {
    const groupIndex = selectedUsers.findIndex(({ company }) => company.id === group.company.id);

    if (checked) {
      if (groupIndex === -1) {
        const updatedUsers = [...selectedUsers];
        updatedUsers.push({ ...group, users: [user] });

        setSelectedUsers(updatedUsers);
      } else {
        const updatedUsers = [...selectedUsers];
        updatedUsers[groupIndex] = {
          ...updatedUsers[groupIndex],
          users: [...updatedUsers[groupIndex].users, user],
        };

        setSelectedUsers(updatedUsers);
      }
    } else {
      const updatedUsers = [...selectedUsers];
      const filteredGroups = selectedUsers.filter(({ company }) => company.id !== group.company.id);
      const filteredUsers = updatedUsers[groupIndex].users.filter(({ uuid }) => uuid !== user.uuid);

      if (filteredUsers.length > 0) {
        updatedUsers[groupIndex] = {
          ...updatedUsers[groupIndex],
          users: filteredUsers,
        };

        setSelectedUsers(updatedUsers);
      } else {
        setSelectedUsers(filteredGroups);
      }
    }
  };

  const handleAllSelected = (group: AssignedUser) => {
    return selectedSelectionsGroupLength({ selectedUsers, group }) === group.users.length;
  };

  const handleNoneSelected = (group: AssignedUser) => {
    return (
      selectedSelectionsGroupLength({ selectedUsers, group }) === 0 ||
      selectedSelectionsGroupLength({ selectedUsers, group }) === undefined
    );
  };

  const handleAnySelected = (group: AssignedUser) => {
    return selectedSelectionsGroupLength({ selectedUsers, group }) !== undefined;
  };

  const handleSelectAll = (group: AssignedUser) => {
    const groupIndex = selectedUsers.findIndex(({ company }) => company.id === group.company.id);
    if (groupIndex === -1) {
      const updatedUsers = [...selectedUsers];
      updatedUsers.push({ ...group });

      setSelectedUsers(updatedUsers);
    } else {
      const updatedUsers = [...selectedUsers];
      updatedUsers[groupIndex] = {
        ...updatedUsers[groupIndex],
        users: group.users,
      };

      setSelectedUsers(updatedUsers);
    }
  };

  const handleDeselectAll = (group: AssignedUser) => {
    setSelectedUsers((prev) => prev.filter(({ company }) => company.id !== group.company.id));
  };

  const handleChecked = ({ group, user }: { group: AssignedUser; user: RoleUser }) => {
    const selectedGroup = selectedUsers.find(({ company }) => company.id === group.company.id);
    return !!selectedGroup?.users.find(({ uuid }) => uuid === user.uuid) || false;
  };

  const accordionTitle = (group: AssignedUser, users: RoleUser[]) =>
    `${getCompanyName(group.company)} (${selectedSelectionsGroupLength({ selectedUsers, group }) || 0}/${
      users.length
    })`;

  const numberOfSelectedUsers = selectedUsers.reduce((total, company) => total + company.users.length, 0);

  return {
    selectedUsers,
    setSelectedUsers,
    selectedSelectionsGroupLength,
    handleOnUserChange,
    handleAllSelected,
    handleNoneSelected,
    handleAnySelected,
    handleSelectAll,
    handleDeselectAll,
    handleChecked,
    accordionTitle,
    numberOfSelectedUsers,
  };
};
