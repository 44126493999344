import { useEffect, useState } from "react";
import { Button, PermissionAccordion, Loading, WithActions } from "components";
import { GroupPermission, Parking, Role, UUID } from "helpers";
import {
  setSelectedUsersRole,
  transformError,
  useAttachRoleMutation,
  useDetachRoleMutation,
  useLazyGetPermissionsByGroupQuery,
  useLazyGetRolesWithDetailsQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "services";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setAssignedParkings, setRole, setUserDataInfo } from "services/userSlice";
import { usePermissions } from "hooks";
import { isEqual } from "lodash";
import { UserAddRoleModal } from "pages/AdminManagement/components/UserModal/components";
import { AssignPermissionsOverview } from "./components/AssignPermissionsOverview";
import { AssignPermissionsRolesContent } from "./components/AssignPermissionsRolesContent";

type Variant = "parking" | "company";

export interface AssignPermissionsProps {
  parking?: Parking;
  role?: Role;
  disabledRole?: number;
  onBack: () => void;
  onGoToOverview: () => void;
  variant: Variant;
  editMode?: boolean;
}

export const AssignPermissions = ({
  role,
  parking,
  onBack,
  variant,
  disabledRole,
  onGoToOverview,
  editMode = false,
}: AssignPermissionsProps) => {
  const dispatch = useAppDispatch();

  const {
    selectedPermissions,
    setSelectedPermissions,
    handleAllSelected,
    handleAnySelected,
    handleChecked,
    handleDeselectAll,
    handleOnPermissionChange,
    handleSelectAll,
    accordionTitle,
  } = usePermissions();

  const isParkingBased = variant === "parking" && !!parking;

  const {
    user: { company_id, roles: userDataRoles, uuid, type },
    assignedParkings,
    role: userDataRole,
  } = useAppSelector((state) => state.userSlice.userData);

  const [initialParkingRole, setInitialParkingRole] = useState(parking?.parkingRole);
  const [initialUserType, setInitialUserType] = useState(type?.id);

  const [getRolesWithDetails, { data: rolesData, isSuccess: rolesIsSuccess, isLoading: rolesIsLoading }] =
    useLazyGetRolesWithDetailsQuery();
  const roles = rolesIsSuccess && rolesData ? rolesData.data : [];

  const [getUser] = useLazyGetUserQuery(); // prefetch ?

  const [attachRole] = useAttachRoleMutation();
  const [detachRole] = useDetachRoleMutation();
  const [updateUser] = useUpdateUserMutation();

  const [isLoading, setIsLoading] = useState(false);

  const companyBasedRole = userDataRoles?.find(({ permission_level }) => permission_level.id === 1);
  const parkingBasedRoles = userDataRoles?.filter(({ permission_level }) => permission_level.id === 2);

  const [getPermissionsByGroup, permissionsResult] = useLazyGetPermissionsByGroupQuery();
  const [groupData, setGroupData] = useState<GroupPermission[]>([]);
  const [groupedPermissions, setGroupedPermissions] = useState<GroupPermission[]>(groupData);

  const [initialValues, setInitialValues] = useState<Parking | Role | undefined>(isParkingBased ? parking : role);
  const [openRoleModal, setOpenRoleModal] = useState<boolean>(false);

  const getRolesData = (preferCacheValue = true) => {
    getRolesWithDetails(
      {
        company_id,
        permission_level_id: isParkingBased ? 2 : 1,
        include_default_permissions: false,
      },
      preferCacheValue
    );
  };

  useEffect(() => {
    if (permissionsResult.isSuccess && permissionsResult.data) {
      setGroupData(permissionsResult.data);
    }
  }, [permissionsResult]);

  useEffect(() => {
    setGroupedPermissions(() => [
      ...groupData,
      ...((role?.permissions &&
        role?.permissions.filter(({ id }) => !groupData.some((initial) => initial.id === id))) ||
        []),
    ]);
  }, [groupData]);

  useEffect(() => {
    getRolesData();
    getPermissionsByGroup({ permission_level_id: isParkingBased ? 2 : 1 }, true);

    setSelectedPermissions(() => (isParkingBased ? parking.parkingRole?.permissions || [] : role?.permissions || []));
  }, []);

  const non_defaults = selectedPermissions.filter(({ is_default }) => !is_default);

  const ignore_parking_based = selectedPermissions.filter(
    ({ is_default, permissions }) => !is_default && permissions[0].permission_level.id !== 2
  );

  const disabledButton = isParkingBased ? non_defaults.length === 0 : ignore_parking_based.length === 0;

  const handleDetachAttachRole = async ({
    detachRoleId,
    attachRoleId,
    uuid,
    parking_id,
  }: {
    detachRoleId: number | undefined;
    attachRoleId: number;
    uuid: UUID;
    parking_id?: number;
  }) => {
    try {
      initialUserType !== type.id &&
        (await updateUser({
          uuid,
          company_id,
          type: type.id,
        })); // only master ?
      !!detachRoleId && (await detachRole({ users: [{ role_id: detachRoleId, username: uuid, parking_id }] }));

      await attachRole({ roles: [{ role_id: attachRoleId, parking_id }], username: uuid });
      const userResult = await getUser({ company_id, uuid }, false).unwrap();
      dispatch(setUserDataInfo(userResult));
    } catch (error) {
      return console.error(transformError(error).message);
    }
  };

  const handleSave = async () => {
    const role = isParkingBased ? parking?.parkingRole : userDataRole;
    const createRoleRadio = role.id === 0 && role?.name === "create-role";
    const changedPermissions = !isEqual(role?.permissions, selectedPermissions);

    dispatch(setSelectedUsersRole({ uuid, role_id: role.id }));

    const detachRoleId = isParkingBased ? initialParkingRole?.id : companyBasedRole?.id; // TODO: handle this undefined better !
    const parking_id = isParkingBased ? parking.id : undefined;

    if (editMode && !createRoleRadio && !changedPermissions) {
      setIsLoading(() => true);
      try {
        await handleDetachAttachRole({
          detachRoleId,
          attachRoleId: role.id,
          uuid,
          parking_id,
        });
        setOpenRoleModal(true);
      } catch (error) {
        return console.error(transformError(error).message);
      } finally {
        setIsLoading(() => false);
      }
    } else {
      setOpenRoleModal(true);
    }
  };

  return (
    <>
      <form className="relative">
        {(rolesIsLoading || permissionsResult.isLoading || isLoading) && <Loading absolute />}
        <div className="flex flex-col gap-y-6">
          <div>
            <h2 className="mb-3">
              Assign user role for {isParkingBased ? "parking" : "company"}{" "}
              <span className="uppercase">{isParkingBased ? parking.parkingLot || parking.id : company_id}</span>
            </h2>
            <AssignPermissionsRolesContent
              roles={roles}
              selectedRole={isParkingBased ? parking.parkingRole : userDataRole}
              disabledRole={disabledRole}
              onChange={({ role }) => {
                dispatch(setRole({ parking_id: isParkingBased ? parking.id : undefined, role }));
                setSelectedPermissions(() => role?.permissions);
                setGroupedPermissions([
                  ...groupData,
                  ...role?.permissions.filter(({ id }) => !groupData.some((initial) => initial.id === id)),
                ]);
              }}
              groupedPermissions={groupedPermissions}
            />
          </div>
          <div className="flex flex-col gap-2">
            <h2>Permissions</h2>
            <div className="flex max-h-96 flex-col gap-2 overflow-y-auto" id="scroll">
              {groupedPermissions &&
                groupedPermissions.length > 0 &&
                groupedPermissions.map((group, groupIndex) => (
                  <PermissionAccordion
                    title={accordionTitle(group, group.permissions)}
                    allSelected={handleAllSelected(group)}
                    anySelected={handleAnySelected(group)}
                    onSelectAllClick={() => {
                      if (handleAllSelected(group)) {
                        handleDeselectAll(group);
                      } else {
                        handleSelectAll(group);
                      }
                    }}
                    disabled={
                      isParkingBased
                        ? group.is_default || !!!parking?.parkingRole?.name
                        : group.is_default || !!!userDataRole?.name
                    }
                    permissions={group.permissions}
                    onPermissionChange={({ checked, permission }) =>
                      handleOnPermissionChange({ checked, permission, group })
                    }
                    isPermissionChecked={(permission) => handleChecked({ group, permission })}
                    key={groupIndex}
                    className="mr-6"
                  />
                ))}
            </div>
          </div>
          {((isParkingBased && !!parking?.parkingRole?.name) || (!isParkingBased && !!role?.name)) && (
            <AssignPermissionsOverview
              groupedPermissions={groupedPermissions}
              rolePermissions={isParkingBased ? parking?.parkingRole?.permissions || [] : role?.permissions || []}
              selectedPermissions={selectedPermissions}
            />
          )}
        </div>
        <WithActions
          setOpen={() => {
            if (!isEqual(initialValues, isParkingBased ? parking : userDataRole)) {
              if (isParkingBased) {
                const selectedParkingIndex = assignedParkings.findIndex(({ id }) => id === parking.id);
                const resetParking = [...assignedParkings];
                resetParking[selectedParkingIndex] = initialValues as Parking;

                dispatch(setAssignedParkings(resetParking));
              } else {
                if (initialValues) dispatch(setRole({ role: initialValues as Role }));
              }
            }
            onBack();
          }}
          closeButton="Back"
        >
          <Button onClick={() => handleSave()} disabled={disabledButton}>
            Save
          </Button>
        </WithActions>
      </form>
      {openRoleModal && (
        <UserAddRoleModal
          open={openRoleModal}
          setOpen={setOpenRoleModal}
          onBack={onGoToOverview}
          editMode={editMode}
          onRefetch={() => getRolesData(false)}
          parking_id={isParkingBased ? parking.id : undefined}
          role={isParkingBased ? parking.parkingRole : userDataRole}
          selectedPermissions={selectedPermissions}
          successMessage={(role) =>
            `user has been assigned with a role ${role} for ${isParkingBased ? "parking" : "company"} ${
              isParkingBased ? parking.parkingLot : company_id
            }`
          }
          errorMessage={(role) =>
            `user has not been assigned with a role ${role} for ${isParkingBased ? "parking" : "company"} ${
              isParkingBased ? parking.parkingLot : company_id
            }`
          }
        />
      )}
    </>
  );
};
