import { PreferredMFA, UserCognito, UserType } from ".";
import { AWSAuth } from "authentication";

// interfaces
export interface LocalStorage {
  username: string | null;
  token: string | null;
  phone_number: string | null;
  preferredMFA: string | null;
}

// setters
export const storeUserName = (username: string): void => localStorage.setItem("username", username);

export const storeToken = (token: string): void => localStorage.setItem("token", token);

export const storePhoneNumber = (phone_number: string): void => localStorage.setItem("phone_number", phone_number);

export const storePreferredMFA = (preferredMFA: PreferredMFA): void =>
  localStorage.setItem("preferredMFA", preferredMFA);

export const storeUser = (user: UserCognito) => {
  storeUserName(user.username);
  storeToken(user.signInUserSession.accessToken.jwtToken);
  storePhoneNumber(user.attributes?.phone_number);
  storePreferredMFA(user.preferredMFA);
};

export const storeUserType = (userType: UserType): void => localStorage.setItem("userType", JSON.stringify(userType));

// getters
export const getUserName = (): string | null => (localStorage.getItem("username") as string) || null;

export const getToken = (): string | null => (localStorage.getItem("token") as string) || null;

export const getPhoneNumber = (): string | null => (localStorage.getItem("phone_number") as string) || null;

export const getPreferredMFA = (): string | null => (localStorage.getItem("preferredMFA") as string) || null;

export const getUser = (): LocalStorage => {
  return {
    username: getUserName(),
    token: getToken(),
    phone_number: getPhoneNumber(),
    preferredMFA: getPreferredMFA(),
  };
};

export const getUserType = (): UserType | null => JSON.parse(localStorage.getItem("userType") as string) || null;

// removers
export const removeUserName = () => localStorage.removeItem("username");

export const removeToken = () => localStorage.removeItem("token");

export const removePhoneNumber = () => localStorage.removeItem("phone_number");

export const removePreferredMFA = () => localStorage.removeItem("preferredMFA");

export const removeUser = () => {
  removeUserName();
  removeToken();
  removePhoneNumber();
  removePreferredMFA();
};

// functions
export const refreshUser = async (): Promise<void> => {
  try {
    const user = await AWSAuth.currentAuthenticatedUser();
    storeUser(user);
  } catch (error) {
    console.error(error);
    removeUser();
  }
};
