import { Input, InputProps } from "components";
import { UUID, User } from "helpers";

interface Data {
  value: string;
  input: InputProps;
}

interface AdminTableDataUserInfoProps {
  user: User;
  quickEditing: UUID;
  first: Data;
  second: Data;
}

export const AdminTableDataUserInfo = ({ user, quickEditing, first, second }: AdminTableDataUserInfoProps) => (
  <>
    {quickEditing === user.uuid ? (
      <>
        <Input {...first.input} />
        <Input {...second.input} />
      </>
    ) : (
      <>
        <p className="text-sm">{first.value}</p>
        <p className="text-sm">{second.value}</p>
      </>
    )}
  </>
);
