import { ChangeEvent, useState } from "react";
import { Button, Loading, Radio, WithActions } from "components";
import { CREATE_USER_TYPES, Role, UserType } from "helpers";
import { transformError, useUpdateUserMutation } from "services";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setUserData } from "services/userSlice";

export interface UserTypeFormProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBack: () => void;
  onNext: () => void;
  editMode?: boolean;
}

export const UserTypeForm = ({ onChange, onBack, onNext, editMode = false }: UserTypeFormProps) => {
  const dispatch = useAppDispatch();
  const [updateUser] = useUpdateUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    user: { company_id, uuid, type },
  } = useAppSelector((state) => state.userSlice.userData);
  const [initialType] = useState<UserType | undefined>(type);

  const handleNext = async () => {
    try {
      if (editMode && initialType?.id !== type.id) {
        setIsLoading(() => true);
        const userResult = await updateUser({ company_id, uuid, type: type.id }).unwrap();
        dispatch(
          setUserData({
            user: userResult,
            companyBasedRole: false,
            assignedParkings: [],
            role: {} as Role,
          })
        );
      }
      onNext();
    } catch (error) {
      return transformError(error).message;
    } finally {
      setIsLoading(() => false);
    }
  };
  return (
    <div className="relative flex flex-col gap-4">
      {isLoading && <Loading absolute />}
      <div>
        <h2 className="mb-3">User Type</h2>
        <div className="flex gap-20">
          {CREATE_USER_TYPES.map(({ id, name }, index) => (
            <Radio
              label={name}
              id={`${index}-${id}`}
              key={index}
              name="type"
              value={id}
              checked={type?.id === id}
              onChange={onChange}
              className="w-[155px] min-w-fit"
            />
          ))}
        </div>
      </div>
      <WithActions closeButton="Back" setOpen={onBack}>
        <Button onClick={() => handleNext()} disabled={!!!type}>
          Next
        </Button>
      </WithActions>
    </div>
  );
};
