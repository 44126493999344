import { Parking, ParkingId, PermissionLevelId, Role, getUserTypeName, isAdmin, isSuperAdmin } from "helpers";
import { Button, OverviewItem, OverviewListItem, Bin, Loading, Pencil, WithActions } from "components";
import { DeleteModal } from "features/Modal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useState } from "react";
import { setAssignedParkings, setCompanyBasedRole, setRole } from "services/userSlice";
import { usePermissionApiPrefetch, useRoleApiPrefetch } from "services";
import { useDeleteStep } from "hooks/useDeleteStep";

interface UserOverviewProps {
  onCancel: (state: boolean) => void;
  onAddNewParking: () => void;
  onEdit: (index?: number) => void;
  onSave: () => void;
  isLoading: boolean;
  editMode?: boolean;
}
export const UserOverview = ({
  onCancel,
  onAddNewParking,
  onSave,
  onEdit,
  isLoading,
  editMode = false,
}: UserOverviewProps) => {
  const dispatch = useAppDispatch();
  const {
    user: { company_id, type, uuid },
    companyBasedRole,
    assignedParkings,
    role,
  } = useAppSelector((state) => state.userSlice.userData);
  const prefetchRoles = useRoleApiPrefetch("getRolesWithDetails");
  const prefetchPermissions = usePermissionApiPrefetch("getPermissionsByGroup");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<ParkingId | undefined>(undefined);
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deleteSuccessTitle, setDeleteSuccessTitle] = useState("");

  const { step: deleteStep, setStep: setDeleteStep } = useDeleteStep();

  const onDelete = (id?: ParkingId) => {
    if (id) dispatch(setAssignedParkings(assignedParkings.filter((parking: Parking) => parking.id !== id)));
    else {
      dispatch(setCompanyBasedRole(false));
      dispatch(setRole({ role: {} as Role }));
    }
    setDeleteStep("success");
    setTimeout(() => {
      setOpenDeleteModal(false);
      setDeleteStep("initial");
    }, 2000);
  };

  const prefetchRolesAndPermissions = (permission_level_id: PermissionLevelId) => {
    prefetchRoles({ company_id, permission_level_id }, { ifOlderThan: 5 });
    prefetchPermissions({ permission_level_id }, { ifOlderThan: 5 });
  };

  const handleDisabled = () => {
    if (isSuperAdmin(type.id)) {
      return !!!role.name;
    } else {
      return assignedParkings.length === 0 || assignedParkings.some(({ parkingRole }) => parkingRole.id === undefined);
    }
  };

  const overviewContent = (
    <div className="mb-[20px]">
      <div className="mb-[20px] flex items-center justify-between">
        <h2>New user overview</h2>
        {isAdmin(type.id) && (
          <Button className="uppercase" onClick={onAddNewParking}>
            Add new parking
          </Button>
        )}
      </div>
      <OverviewItem item="User Type">{getUserTypeName(type.id)}</OverviewItem>
      {isSuperAdmin(type.id) ? (
        <OverviewItem item="Company">{company_id}</OverviewItem>
      ) : (
        <OverviewItem item="Assigned Parking(s)">{assignedParkings?.map((p) => p.parkingLot).join(", ")}</OverviewItem>
      )}
    </div>
  );

  const rolesAndPermissionsContent = (
    <div className="mb-[20px]" onMouseEnter={() => prefetchRolesAndPermissions(1)}>
      <div className="flex items-center justify-between">
        <h2>{isSuperAdmin(type.id) ? "Roles and Permissions" : "Company level role"}</h2>
        <div className="flex items-center justify-center space-x-3 py-5">
          <Pencil onClick={() => onEdit()} />
          {isAdmin(type.id) && (
            <Bin
              onClick={() => {
                setDeleteId(undefined);
                setDeleteName(role.name);
                setDeleteTitle(`You are about to delete role ${role.name} from the user profile`);
                setDeleteSuccessTitle(`Role ${role.name} has been deleted from this user profile`);
                setOpenDeleteModal(true);
              }}
            />
          )}
        </div>
      </div>
      <OverviewItem disabled={!!!role.name} item="Assigned Role">
        {role.name ? role.name : "Assign Role"}
      </OverviewItem>
      <OverviewItem disabled={!!!role.name} item="Assigned Permissions">
        <div className="flex flex-col">
          {!!role.name ? (
            <>
              {role.permissions.map(({ name, permissions }, index) => (
                <OverviewListItem name={name} data={permissions} key={index} />
              ))}
            </>
          ) : (
            "Assign Permission(s)"
          )}
        </div>
      </OverviewItem>
    </div>
  );

  const parkingsContent = (
    <>
      {companyBasedRole && rolesAndPermissionsContent}
      {assignedParkings?.map((parking: Parking, index) => (
        <div className="mb-[20px]" key={index} onMouseEnter={() => prefetchRolesAndPermissions(2)}>
          <div className="flex items-center justify-between">
            <h2>{parking.parkingLot}</h2>
            <div className="flex items-center justify-center space-x-3 py-5">
              <Pencil onClick={() => onEdit(index)} />
              <Bin
                onClick={() => {
                  setDeleteId(parking.id);
                  setDeleteName(parking.parkingLot);
                  setDeleteTitle(`You are about to delete parking ${parking.parkingLot} from the user profile`);
                  setDeleteSuccessTitle(`Parking ${parking.parkingLot} has been deleted from this user profile`);
                  setOpenDeleteModal(true);
                }}
              />
            </div>
          </div>
          <OverviewItem disabled={!!!parking.parkingRole.name} item="Assigned Role">
            {parking.parkingRole.name || "Assign Role"}
          </OverviewItem>
          <OverviewItem disabled={!!!parking.parkingRole.name} item="Assigned Permissions">
            <div className="flex flex-col">
              {!!parking.parkingRole.name ? (
                <>
                  {parking.parkingRole.permissions.map(({ name, permissions }, index) => (
                    <OverviewListItem name={name} data={permissions} key={index} />
                  ))}
                </>
              ) : (
                "Assign Permission(s)"
              )}
            </div>
          </OverviewItem>
        </div>
      ))}
    </>
  );

  return (
    <>
      <div className="relative">
        {isLoading && <Loading absolute />}
        {overviewContent}
        <div className="max-h-[634px] overflow-y-auto" id="scroll">
          {isSuperAdmin(type.id) && rolesAndPermissionsContent}
          {isAdmin(type.id) && parkingsContent}
        </div>
        <WithActions closeButton="Cancel" setOpen={onCancel}>
          <Button onClick={() => onSave()} disabled={handleDisabled() || editMode}>
            Save User
          </Button>
        </WithActions>
      </div>
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={deleteTitle}
          label="I understand that this will affect user permissions."
          name={deleteName}
          isLoading={false}
          successTitle={deleteSuccessTitle}
          onSave={() => onDelete(deleteId)}
          deleteStep={deleteStep}
        />
      )}
    </>
  );
};
