import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Button, InputStyledDiv, Loading, WithActions } from "components";
import { CompanyId, UserRole, getUserTypeName, isAdmin, isMasterAdmin } from "helpers";
import { transformError, useDetachRoleMutation, useLazyGetUserQuery } from "services";
import { setUserDataInfo } from "services/userSlice";
import { EditInfoParkingRole } from "./components/EditInfoParkingRole";
import { EditInfoInput } from "./components/EditInfoInput";
import { EditInfoCompanyRole } from "./components/EditInfoCompanyRole";

type EditInfoProps = {
  onClose: (state: boolean) => void;
  onEdit: ({ name, value }: { name: string; value: string }) => void;
  goToUserType: () => void;
  goToParking: () => void;
  goToPermissions: ({
    id,
    company_id,
    isParkingBased,
    index,
  }: {
    id: number;
    company_id: CompanyId;
    isParkingBased: boolean;
    index?: number;
  }) => void;
  onPrefetchRole: (role: UserRole) => void;
  isLoading: boolean;
};

export const EditInfo = ({
  onClose,
  onEdit,
  goToUserType,
  goToPermissions,
  onPrefetchRole,
  goToParking,
  isLoading,
}: EditInfoProps) => {
  const { user } = useAppSelector((state) => state.userSlice.userData);
  const companyBasedRole =
    user.roles && user.roles.length && user.roles?.find(({ permission_level }) => permission_level.id === 1);
  const parkingBasedRoles =
    (user.roles && user.roles.length && user.roles?.filter(({ permission_level }) => permission_level.id === 2)) || [];

  const [userData, setUserData] = useState(user);
  const dispatch = useAppDispatch();

  const [detachRole] = useDetachRoleMutation();
  const [getUser] = useLazyGetUserQuery();
  const [isLoadingDetach, setIsLoadingDetach] = useState(false);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onCancelHandler = () => setUserData(user);

  const onDetachRoleHandler = async ({ role_id, parking_id }: { role_id: number; parking_id?: number }) => {
    setIsLoadingDetach(() => true);
    try {
      await detachRole({ users: [{ role_id, username: user.uuid, parking_id }] });
      const userResult = await getUser({ company_id: user.company_id, uuid: user.uuid }).unwrap();
      dispatch(setUserDataInfo(userResult));
    } catch (error) {
      return console.error(transformError(error).message);
    } finally {
      setIsLoadingDetach(() => false);
    }
  };

  return (
    <div className="relative">
      {isLoading && <Loading absolute />}
      <div className="flex flex-col gap-y-6">
        <span className="uppercase">Edit user</span>
        <EditInfoInput
          label="Name*"
          id="first_name"
          name="first_name"
          value={userData.first_name}
          onChange={onChangeHandler}
          onEdit={onEdit}
          onCancel={onCancelHandler}
          placeholder="Name"
        />
        <EditInfoInput
          label="Surname*"
          id="last_name"
          name="last_name"
          value={userData.last_name}
          onChange={onChangeHandler}
          onEdit={onEdit}
          onCancel={onCancelHandler}
          placeholder="Surname"
        />
        <EditInfoInput
          label="Email*"
          type="email"
          id="email"
          name="email"
          value={userData.email}
          onChange={onChangeHandler}
          onEdit={onEdit}
          onCancel={onCancelHandler}
          placeholder="example@example.com"
        />
        <EditInfoInput
          label="Phone*"
          type="tel"
          id="phone_number"
          name="phone_number"
          value={userData.phone_number}
          onChange={onChangeHandler}
          onEdit={onEdit}
          onCancel={onCancelHandler}
          placeholder="XXXXXXXXX"
        />
        <div>
          <h2 className="mb-3">User Type</h2>
          <div className="flex w-full">
            <InputStyledDiv>{getUserTypeName(user.type.id)}</InputStyledDiv>
            <Button
              className="min-w-[150px] max-w-[150px] px-2"
              onClick={goToUserType}
              disabled={isMasterAdmin(user.type.id)}
            >
              Edit
            </Button>
          </div>
        </div>

        {!isMasterAdmin(user.type.id) && (
          <>
            <div>
              <h2 className="mb-3">Assigned roles</h2>
              {!!companyBasedRole && (
                <EditInfoCompanyRole
                  role={companyBasedRole}
                  user={user}
                  onAction={({ id, company_id }) => goToPermissions({ id, company_id, isParkingBased: false })}
                  onPrefetchRole={() => onPrefetchRole(companyBasedRole)}
                  disabledButton={isMasterAdmin(user.type.id)}
                  disabledDelete={isMasterAdmin(user.type.id)}
                  // not sure about dsabled
                  onDetachRole={() => onDetachRoleHandler({ role_id: companyBasedRole.id })}
                  isLoading={isLoadingDetach}
                />
              )}

              {parkingBasedRoles.length > 0 &&
                parkingBasedRoles.map((role, index) => {
                  if (role.permission_level.id === 2) {
                    return (
                      <EditInfoParkingRole
                        role={role}
                        onAction={({ id, company_id }) =>
                          goToPermissions({ id, company_id, isParkingBased: true, index })
                        }
                        onPrefetchRole={() => onPrefetchRole(role)}
                        disabledButton={isMasterAdmin(user.type.id)}
                        disabledDelete={isMasterAdmin(user.type.id)}
                        // not sure about dsabled
                        onDetachRole={() => onDetachRoleHandler({ role_id: role.id, parking_id: role.parking?.id })}
                        key={index}
                        isLoading={isLoadingDetach}
                      />
                    );
                  }
                })}
            </div>
            {isAdmin(user.type.id) && <Button onClick={goToParking}>Add new mapping</Button>}
          </>
        )}
      </div>
      <WithActions closeButton="Cancel" setOpen={onClose} />
    </div>
  );
};
