import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const CheckLarge = ({
  className = "text-success",
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge("cursor-pointer", className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <title>CheckLarge</title>
    <g filter="url(#filter0_d_811_19751)">
      <path
        d="M9.00039 15.6996L4.80039 11.4996L3.40039 12.8996L9.00039 18.4996L21.0004 6.49961L19.6004 5.09961L9.00039 15.6996Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_811_19751"
        x="-1.59961"
        y="0.0996094"
        width="27.5996"
        height="23.4004"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_811_19751" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_811_19751" result="shape" />
      </filter>
    </defs>
  </svg>
);
