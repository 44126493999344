import { Minus, PlusSmall } from "components";
import { InputHTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Variant = "default" | "custom";
export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  variant?: Variant;
}

export const Checkbox = ({
  variant = "default",
  label,
  id,
  checked = false,
  disabled = false,
  className,
  ...rest
}: CheckboxProps) => {
  const defaultLabelClasses = twMerge("flex items-center space-x-2");
  const defaultCheckboxClasses = twMerge("form-checkbox focus:ring-0 focus:ring-offset-0 text-primary border-primary");

  const customLabelClasses = twMerge("h-[32px] min-w-[125px]");
  const customCheckboxClasses = twMerge("sr-only");

  return (
    <>
      <label
        className={twMerge(
          variant === "default" && defaultLabelClasses,
          variant === "custom" && customLabelClasses,
          !disabled && "cursor-pointer",
          disabled && "pointer-events-none",
          className
        )}
        key={id}
      >
        <input
          className={variant === "default" ? defaultCheckboxClasses : customCheckboxClasses}
          checked={checked}
          id={id}
          type="checkbox"
          disabled={disabled}
          {...rest}
        />
        {variant === "default" && <span>{label}</span>}
        {variant === "custom" && (
          <div
            className={twMerge(
              "flex h-full items-center justify-between border border-primary bg-white focus:outline-none",
              disabled && "border-primary/20 text-primary/20"
            )}
          >
            <span className="flex-1 px-1 text-center text-sm font-light">{label}</span>
            <span
              className={twMerge(
                "flex h-full items-center p-2",
                checked ? (disabled ? "bg-success/20" : "bg-success") : disabled ? "bg-primary/20" : "bg-primary"
              )}
            >
              {checked ? <Minus /> : <PlusSmall />}
            </span>
          </div>
        )}
      </label>
    </>
  );
};
