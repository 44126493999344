import { Modal, ModalProps } from "features";
import { Checkbox, CheckboxProps, Loading, Button, WithActions } from "components";
import { ReactNode, useEffect, useState } from "react";
import { DeleteStep, useDeleteStep } from "hooks/useDeleteStep";

interface DeleteModalProps extends ModalProps, Required<Pick<CheckboxProps, "name" | "label">> {
  isLoading: boolean;
  deleteStep?: DeleteStep;
  successTitle?: string | ReactNode;
}

export const DeleteModal = ({
  open,
  setOpen,
  isLoading,
  title,
  name,
  label,
  onSave,
  deleteStep = "initial",
  successTitle = "",
}: DeleteModalProps) => {
  const [deleteRole, setDeleteRole] = useState(false);
  const { step, setStep } = useDeleteStep();

  useEffect(() => {
    setStep(deleteStep);
  }, [deleteStep]);

  return (
    <Modal withActions={false} open={open} setOpen={setOpen} className="max-w-[600px] py-20">
      <div className="relative">
        {isLoading && <Loading absolute className="p-0" />}
        <div className="space-y-6 text-center">
          {step === "initial" && (
            <>
              <h1 className="uppercase">{title}</h1>
              <Checkbox
                label={label}
                id="delete-role-checkbox"
                name={name}
                checked={deleteRole}
                onChange={({ target }) => setDeleteRole(target.checked)}
              />
              <WithActions closeButton="Back" setOpen={setOpen}>
                <Button variant="bg-error" disabled={!deleteRole} onClick={() => onSave && onSave()}>
                  Delete
                </Button>
              </WithActions>
            </>
          )}
        </div>
        {step === "success" && <h1 className="text-center uppercase">{successTitle}</h1>}
      </div>
    </Modal>
  );
};
