import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Button, ValidationMessage, Input } from "components";
import { StepState } from "helpers";
import { useForgotPasswordMutation } from "services";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

interface AuthPasswordRecoverFormI {
  code: string;
  password: string;
  repeatPassword: string;
}

const initialData: AuthPasswordRecoverFormI = {
  code: "",
  password: "",
  repeatPassword: "",
};

interface AuthPasswordRecoverFormProps extends Pick<StepState, "setStep"> {
  email?: string;
}

export const AuthPasswordRecoverForm = ({ setStep, email = "" }: AuthPasswordRecoverFormProps) => {
  const [data, setData] = useState<AuthPasswordRecoverFormI>(initialData);
  const [codeError, setCodeError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [generalError, setGeneralError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [forgotPasswordSubmit, { isError, isLoading, isSuccess, error }] = useForgotPasswordMutation();
  const customError: FetchBaseQueryError | SerializedError | undefined | any = error;

  useEffect(() => {
    setDisabled(!data.code || !data.password || !data.repeatPassword);
    (!!codeError || !!passwordError) && resetError();
  }, [data]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    setData((oldObject: AuthPasswordRecoverFormI) => ({
      ...oldObject,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setDisabled(() => true);

    if (data.password === data.repeatPassword) {
      try {
        const res = await forgotPasswordSubmit({
          username: email,
          confirmationCode: data.code,
          newPassword: data.password,
          confirmPassword: data.repeatPassword,
        });
        if (isError && error) {
          console.log("isError", error);
          // {error.status} {JSON.stringify(error.data)}
        }
        if (isLoading) {
          console.log("isLoading");
        }
        if (isSuccess && data) {
          console.log("isSuccess", data);
        }
        // setStep(() => 1);
      } catch (err: any) {
        console.log(err);
        if (err.code === "CodeMismatchException" || err.code === "ExpiredCodeException") {
          setCodeError(err.message ? err.message : err);
        } else if (err.code === "InvalidPasswordException") {
          setPasswordError(err.message ? err.message : err);
        } else {
          setGeneralError(err.message ? err.message : err);
        }
      } finally {
        setDisabled(() => false);
      }
    } else {
      setPasswordError("Passwords don't match!");
      setDisabled(() => false);
    }
  };

  const resetError = () => {
    setCodeError("");
    setPasswordError("");
    setGeneralError("");
  };

  useEffect(() => {
    if (error && "status" in error) {
      // Access error.status without TypeScript complaints
      console.log(error.status);
    }
  }, [error]);

  useEffect(() => {
    console.log(customError);
  }, [customError]);

  return (
    <form onSubmit={onSubmitHandler} autoComplete="off">
      <div className="space-y-8">
        <div className="flex flex-col">
          <div className="space-y-5">
            <Input
              id="code"
              name="code"
              value={data.code}
              onChange={onChangeHandler}
              error={!!codeError}
              maxLength={6}
              minLength={6}
              placeholder="CODE (you received the code via email)"
              autoComplete="one-time-code"
            />
            <Input
              id="password"
              name="password"
              type="password"
              value={data.password}
              onChange={onChangeHandler}
              error={!!passwordError}
              placeholder="New password"
              autoComplete="off"
            />
            <Input
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              value={data.repeatPassword}
              onChange={onChangeHandler}
              error={!!passwordError}
              placeholder="Repeat password"
              autoComplete="off"
            />
          </div>
          <ValidationMessage>{codeError}</ValidationMessage>
          <ValidationMessage>{passwordError}</ValidationMessage>
          <ValidationMessage>{generalError}</ValidationMessage>
        </div>
        <div className="flex justify-center">
          <Button type="submit" disabled={disabled}>
            Change password
          </Button>
        </div>
      </div>
    </form>
  );
};
