import { ChangeEvent, TextareaHTMLAttributes, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const Textarea = ({ value = "", onChange = () => null, className, ...rest }: TextareaProps) => {
  const [textareaValue, setTextareaValue] = useState<string | number | readonly string[]>(value);

  useEffect(() => {
    setTextareaValue(value);
  }, [value]);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value);
    onChange(event);
  };

  return (
    <textarea
      className={twMerge(
        "w-full resize-none border border-primary text-primary placeholder:text-primary placeholder:text-opacity-50 focus:border-primary focus:outline-none focus:ring-0",
        className
      )}
      onChange={handleOnChange}
      value={textareaValue}
      {...rest}
    />
  );
};
