import { twMerge } from "tailwind-merge";
import { IconsProps } from ".";

export const Info = ({
  className = "text-primary",
  viewBox = "0 0 42 42",
  width = 42,
  height = 42,
  onClick,
}: IconsProps) => (
  <svg
    className={twMerge(className)}
    viewBox={viewBox}
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    aria-hidden="true"
  >
    <title>Info</title>
    <path
      d="M18.9141 10.5837H23.0807V14.7503H18.9141V10.5837ZM18.9141 18.917H23.0807V31.417H18.9141V18.917ZM20.9974 0.166992C9.4974 0.166992 0.164062 9.50033 0.164062 21.0003C0.164062 32.5003 9.4974 41.8337 20.9974 41.8337C32.4974 41.8337 41.8307 32.5003 41.8307 21.0003C41.8307 9.50033 32.4974 0.166992 20.9974 0.166992ZM20.9974 37.667C11.8099 37.667 4.33073 30.1878 4.33073 21.0003C4.33073 11.8128 11.8099 4.33366 20.9974 4.33366C30.1849 4.33366 37.6641 11.8128 37.6641 21.0003C37.6641 30.1878 30.1849 37.667 20.9974 37.667Z"
      fill="currentColor"
    />
  </svg>
);
