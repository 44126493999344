import { Card, CardVariant } from "components";
import { ReactNode } from "react";

export interface MessageProps {
  message: string;
  children?: ReactNode;
  variant?: CardVariant;
}

export const Message = ({ message, children = <></>, variant }: MessageProps) => (
  <Card variant={variant}>
    <div className="flex justify-center">
      <h1>{message}</h1>
      {children}
    </div>
  </Card>
);
