import { Table, Loading } from "components";
import { useRoleManagementContext } from "context";
import { useState } from "react";
import { MessageModal, DeleteModal } from "features/Modal";
import { Role } from "helpers";
import { useAppDispatch } from "app/hooks";
import { setSelectedRole } from "services/roleSlice";
import { transformError, useDeleteRoleMutation } from "services";
import { RoleTableHeadings } from "./components/RoleTableHeadings";
import { RoleTableData } from "./components/RoleTableData";
import { RoleTableEditRoleModal } from "./components/RoleTableEditRoleModal";

export const RoleTable = () => {
  const dispatch = useAppDispatch();
  const { data, isSuccess, page, onFetch, setPage } = useRoleManagementContext();
  const [deleteRole, deleteRoleResult] = useDeleteRoleMutation();
  const [openEditRoleModal, setOpenEditRoleModal] = useState<boolean>(false);
  const [deletingRole, setDeletingRole] = useState<Role | undefined>(undefined);
  const [openDeleteRoleModal, setOpenDeleteRoleModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const openEditModal = (role: Role) => {
    dispatch(setSelectedRole(role));
    setOpenEditRoleModal(true);
  };

  const openDeleteModal = (role: Role) => {
    setDeletingRole(role);
    setOpenDeleteRoleModal(true);
  };

  const onDeleteRole = async () => {
    if (deletingRole) {
      try {
        await deleteRole({ id: deletingRole.id, company_id: deletingRole.company.id }).unwrap();
        setOpenDeleteRoleModal(false);
        if (data && data.data.length > 1) {
          onFetch();
        } else {
          setPage((prevPage) => prevPage - 1);
        }
        setOpenSuccessModal(true);
      } catch (error) {
        console.error(transformError(error).message);
      }
    }
  };

  return (
    <>
      {isSuccess && !!data ? (
        <>
          <Table
            headings={<RoleTableHeadings />}
            data={<RoleTableData onEdit={openEditModal} onDelete={openDeleteModal} />}
            // page={data.currentPage - 1} // TODO: check this !!
            page={page}
            pageCount={data.totalPages}
            onPageChange={({ selected }) => setPage(selected)}
            className="h-[650px]"
          />
          {openEditRoleModal && (
            <RoleTableEditRoleModal
              open={openEditRoleModal}
              setOpen={() => {
                onFetch();
                setOpenEditRoleModal(false);
                dispatch(setSelectedRole({}));
              }}
            />
          )}
          {openDeleteRoleModal && deletingRole && (
            <DeleteModal
              open={openDeleteRoleModal}
              setOpen={setOpenDeleteRoleModal}
              title={`You are about to delete role ${deletingRole.name}`}
              label={
                <>
                  I understand that this action can <span className="underline">NOT</span> be undone.
                </>
              }
              name={deletingRole.name}
              isLoading={deleteRoleResult.isLoading}
              onSave={onDeleteRole}
            />
          )}
          {openSuccessModal && (
            <MessageModal
              open={openSuccessModal}
              setOpen={setOpenSuccessModal}
              message={`The role ${deletingRole?.name} has been deleted`}
              onClose={() => setOpenSuccessModal(false)}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
