import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { UserState, storeUser } from "helpers";
import { Button, CardHeader, ValidationMessage, Input } from "components";
import { Link } from "react-router-dom";
import { AWSAuth } from "authentication";
import { useAuthContext } from "context";

interface AuthLoginFormI {
  email: string;
  password: string;
}

const initialData: AuthLoginFormI = {
  email: "",
  password: "",
};

interface AuthLoginProps extends Pick<UserState, "setUser"> {}

export const AuthLogin = ({ setUser }: AuthLoginProps) => {
  const { setAuth } = useAuthContext();
  const emailRegex = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{1,}$/i;
  const [data, setData] = useState<AuthLoginFormI>(initialData);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    setData((oldObject: AuthLoginFormI) => ({
      ...oldObject,
      [name]: value,
    }));
  };

  const storeAdditionalUserData = (username: string, deviceKey: string, deviceName: string) => {
    localStorage.setItem("username", username);
    localStorage.setItem("deviceKey", deviceKey);
    localStorage.setItem("deviceName", deviceName);
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDisabled(() => true);

    try {
      const user = await AWSAuth.signIn(data.email, data.password);

      console.log(user);
      // Go dobiva device_name direktno od navigator
      const device_name = navigator.userAgent;
      // Go dobiva deviceKey od storage na response po login
      const deviceKey = user?.storage[user?.keyPrefix + "." + user?.username + ".deviceKey"];

      if (user?.username) {
        if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
          setAuth("change-password");
        } else if (user?.challengeName === "SMS_MFA") {
          storeAdditionalUserData(user.username, deviceKey, device_name);
          setAuth("2fa");
        } else {
          storeUser(user);
          setAuth("success");
        }
        setUser(user);
      }
    } catch (err: any) {
      console.error(err);
      setError(err.message ? err.message : err);
    } finally {
      setDisabled(() => false);
    }
  };

  useEffect(() => {
    setDisabled(!emailRegex.test(data.email) || !data.password);
    !!error && setError("");
  }, [data]);

  useEffect(() => {
    setAuth("login");
  }, []);

  return (
    <>
      <CardHeader title="Sign In" />
      <form onSubmit={onSubmitHandler}>
        <div className="space-y-4">
          <Input
            type="email"
            id="email"
            name="email"
            value={data.email}
            placeholder="Email"
            onChange={onChangeHandler}
            error={!!error}
            autoComplete="email"
          />
          <Input
            type="password"
            id="password"
            name="password"
            value={data.password}
            placeholder="Password"
            onChange={onChangeHandler}
            error={!!error}
            autoComplete="current-password"
          />
        </div>
        <div className="mt-2 flex items-center justify-between">
          <Link to="/reset-password" className="text-primary underline">
            Forgot password?
          </Link>
          <ValidationMessage>{error}</ValidationMessage>
        </div>
        <Button type="submit" className="mx-auto mt-5 px-16 sm:min-w-[200px]" disabled={disabled}>
          Sign in
        </Button>
      </form>
    </>
  );
};
